import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../../Action/SnackbarAction';
import { dispatch } from '../../../../../App';
import ButtonComponent from '../../../../../Component/ButtonComponent';
import ApiHandler from '../../../../../Handler/ApiHandler';

type Props = {
  reload: () => void;
  onClose: () => void;
  uuid: string;
};

const CancelOrderModal: React.FC<Props> = ({ onClose, uuid, reload }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const cancelOrder = () => {
    setLoading(true);
    dispatch(LinearProgressMainLayoutActivate());
    ApiHandler.post(
      {
        route: 'api_sale_order_client_cancel',
        params: { uuid },
      },
      (response) => {
        if (response.status === 200) {
          dispatch(
            SnackbarOpen({
              text: 'Commande annulée avec succès.',
              variant: 'success',
            }),
          );

          onClose();
          reload();
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }

        dispatch(LinearProgressMainLayoutDeactivate());
        setLoading(false);
      },
    );
  };

  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <DialogTitle className={classes.title}>Annuler la commande</DialogTitle>
      <DialogContent className={classes.content}>
        <p>Êtes-vous sure de vouloir annuler la commande ?</p>
      </DialogContent>
      <DialogActions>
        <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loading} />
        <ButtonComponent label="Valider" onClick={cancelOrder} loading={loading} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: 15,
  },
  content: {
    minWidth: '30vw',
  },
});

export default CancelOrderModal;
