import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StateType } from '#app/Store';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler, Form } from '../../../../Handler/FormHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';

type Shop = {
  id: string;
};

type Props = {
  shop?: Shop;
};

const ShopManagementShopForm: React.FC<Props> = ({ shop }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [optionsShopRegion, setOptionsShopRegion] = useState<{ value: string; label: string }[]>([]);
  const [optionsUser, setOptionsUser] = useState<{ value: string; label: string }[]>([]);

  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);

  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') &&
    !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

  const [form, setForm] = useState<Form>({
    name: {
      name: 'name',
      label: 'Nom',
      textHelper: 'Saisissez le nom de la boutique.',
      type: 'text',
      options: { validation: ['required'] },
    },
    openingTime: {
      name: 'openingTime',
      label: "Horaire d'ouverture",
      textHelper: "Saisissez l'horaire d'ouverture de la boutique.",
      type: 'text',
    },
    closingTime: {
      name: 'closingTime',
      label: 'Horaire de fermeture',
      textHelper: "Saisissez l'horaire de fermeture de la boutique.",
      type: 'text',
    },
    shopRegion: {
      name: 'shopRegion',
      label: 'Région boutique',
      textHelper: 'Choisissez la région de la boutique.',
      type: 'integer',
    },
    userRegion: {
      name: 'userRegion',
      label: 'Responsable régional',
      textHelper: "Choisissez l'utilisateur région boutique.",
      type: 'integer',
    },
    userNational: {
      name: 'userNational',
      label: 'Responsable national',
      textHelper: "Choisissez l'utilisateur national boutique.",
      type: 'integer',
    },
    userLogistique: {
      name: 'userLogistique',
      label: 'Responsable logistique',
      textHelper: "Choisissez l'utilisateur logistique boutique.",
      type: 'integer',
    },
    userSellers: {
      name: 'userSellers',
      label: 'Vendeurs',
      textHelper: 'Choisissez les vendeurs boutique.',
      type: 'array',
    },
  });

  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const handlerForm = initFormHandler(form, setForm);

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      const apiRoute = shop ? 'api_shop_management_shop_edit' : 'api_shop_management_shop_add';
      const apiParams = shop ? { id: shop.id } : undefined;

      ApiHandler.post({ route: apiRoute, data: handlerForm.getData(), params: apiParams }, (response) => {
        if (response.status === 200) {
          const successMessage = shop ? 'Boutique modifiée avec succès.' : 'Boutique créée avec succès.';
          dispatch(SnackbarOpen({ text: successMessage, variant: 'success' }));

          if (!shop) {
            navigate(getRoutePathname('shop_management_shop', { id: response.data.id }));
          }
        } else if (response.status === 400) {
          handlerForm.setErrorApi(response.error);
        } else {
          dispatch(
            SnackbarOpen({
              text: response.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }

        dispatch(LinearProgressMainLayoutDeactivate());
        handlerForm.setFormLoading(false);
        setIsLoadingForm(false);
      });
    }
  };

  useEffect(() => {
    handlerForm.start();
    if (shop) {
      handlerForm.setDataApi(shop);
    }
    handlerForm.setFormLoading(justWatch);

    ApiHandler.get({ route: 'api_select_shop_region' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsShopRegion(data);
    });

    ApiHandler.get({ route: 'api_select_user' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsUser(data);
    });
  }, [shop, justWatch]);

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title="Information boutique" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="name" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="openingTime" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="closingTime" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MultipleSelectComponent id="userSellers" handler={handlerForm} options={optionsUser} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectComponent id="shopRegion" options={optionsShopRegion} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectComponent id="userRegion" options={optionsUser} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectComponent id="userNational" options={optionsUser} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectComponent id="userLogistique" options={optionsUser} handler={handlerForm} />
        </Grid>
      </Grid>
      {!justWatch && (
        <ButtonComponent label="Enregistrer" className={classes.button} onClick={save} loading={isLoadingForm} />
      )}
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default ShopManagementShopForm;
