import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';

export const listingStyles = makeStyles({
  shadowBox: {
    paddingBottom: 60,
    paddingTop: 60,
  },
  button: {
    margin: '15px 15px 15px 0 !important',
  },
  buttonTop: {
    margin: '15px !important',
    top: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
  buttonActionFirst: {
    margin: '0 -8px 5px 20px !important',
    fontSize: '10px !important',
  },
  buttonActionSecond: {
    margin: '0 -8px 5px 20px !important',
    fontSize: '10px !important',
  },
});
