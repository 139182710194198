import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Store from './Store';
import './index.css';

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={Store.store}>
    <PersistGate loading={null} persistor={Store.persistor}>
      <App />
    </PersistGate>
  </Provider>,
);
