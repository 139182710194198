import { Home, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs as BreadcrumbsMUI, Typography, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import UseAnimations from 'react-useanimations';
import loading2 from 'react-useanimations/lib/loading2';

import { StateType } from '#app/Store';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../Action/LinearProgressMainLayoutAction';
import ShadowBoxComponent from './ShadowBoxComponent';

type Breadcrumb = {
  context: string;
  links?: { path: string; label: string }[];
  title: string;
  description?: string;
};

type Props = {
  loading?: boolean;
  breadcrumbs: Breadcrumb;
};

const ContentViewComponent: React.FC<PropsWithChildren<Props>> = ({ loading = false, breadcrumbs, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenReducer = useSelector((state: StateType) => state.ScreenReducer);
  const [isSmallView, setSmallView] = useState(false);

  useEffect(() => {
    setSmallView(screenReducer.screen === 'XS' || screenReducer.screen === 'SM');
  }, [screenReducer.screen]);

  useEffect(() => {
    dispatch(loading ? LinearProgressMainLayoutActivate() : LinearProgressMainLayoutDeactivate());
  }, [loading, dispatch]);

  return (
    <div
      className={`${classes.contentView} ${isSmallView && classes.contentViewSmall}`}
      style={isSmallView ? { padding: '0 10px' } : {}}
    >
      {loading ? (
        <Fade in={true} timeout={1000}>
          <div className={classes.loader}>
            <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
              <ShadowBoxComponent>
                <img src={'/image/logo.webp'} alt="logo" className={classes.loaderLogo} />
                <div className={classes.loaderActivity}>
                  <UseAnimations animation={loading2} size={35} />
                </div>
              </ShadowBoxComponent>
            </div>
          </div>
        </Fade>
      ) : (
        <>
          <div className={`${classes.breadcrumbs} ${isSmallView && classes.breadcrumbsSmall}`}>
            <BreadcrumbsMUI
              separator={<NavigateNext className={'icon-bc'} style={{ margin: 0, cursor: 'default' }} />}
              aria-label="breadcrumb"
            >
              <Typography>
                <Home className={'icon-bc'} />
                {breadcrumbs.context}
              </Typography>
              {breadcrumbs.links?.map((link, key) => (
                <Link to={link.path} key={key} className={'link'}>
                  {link.label}
                </Link>
              ))}
            </BreadcrumbsMUI>
            <h2>{breadcrumbs.title}</h2>
            {breadcrumbs.description && <p className="description">{breadcrumbs.description}</p>}
          </div>
          {children}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  contentView: {
    padding: '0 25px',
    position: 'relative',
  },
  contentViewSmall: {
    padding: '0 5px',
  },
  loader: {
    textAlign: 'center',
  },
  loaderContent: {
    marginTop: 'calc(50vh - 60px)',
    transform: 'translateY(-50%)',
    width: 300,
    margin: 'auto',
  },
  loaderLogo: {
    width: '80%',
    marginLeft: '3%',
    marginBottom: 20,
  },
  loaderActivity: {
    '& > div': {
      margin: 'auto',
      width: '15% !important',
    },
  },
  breadcrumbs: {
    color: '#5E6E82',
    width: '100%',
    padding: '15px 40px',
    height: 82,
    boxSizing: 'border-box',
    '& h2': {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
    },
    '& p': {
      margin: '0 !important',
      marginTop: 3,
      fontSize: 13,
      fontWeight: 300,
      color: '#5E6E82',
    },
    '& a': {
      margin: 0,
      marginTop: 3,
      fontSize: 13,
      fontWeight: 300,
      color: '#5E6E82',
    },
    '& .icon-bc': {
      color: '#5E6E82',
      fontSize: 18,
      verticalAlign: 'sub',
      fontWeight: 400,
      margin: 0,
      marginRight: 10,
    },
    '& .MuiBreadcrumbs-root': {
      color: '#5E6E82',
      marginLeft: -30,
      marginBottom: 8,
    },
    '& svg': {
      color: '#5E6E82',
      fontWeight: 300,
      fontSize: 15,
    },
  },
  breadcrumbsSmall: {
    padding: '10px 45px',
    height: 82,
    '& h2': {
      margin: 0,
      fontSize: 14,
      fontWeight: 500,
    },
    '& p': {
      margin: '0 !important',
      marginTop: 3,
      fontSize: 10,
      fontWeight: 300,
      color: '#5E6E82',
    },
    '& a': {
      margin: 0,
      marginTop: 3,
      fontSize: 10,
      fontWeight: 300,
      color: '#5E6E82',
    },
    '& .icon-bc': {
      color: '#5E6E82',
      fontSize: 16,
      verticalAlign: 'sub',
      fontWeight: 400,
      margin: 0,
      marginRight: 10,
      cursor: 'pointer',
    },
    '& .MuiBreadcrumbs-root': {
      color: '#5E6E82',
      marginLeft: -30,
      marginBottom: 8,
    },
    '& svg': {
      color: '#5E6E82',
      fontWeight: 300,
      fontSize: 13,
    },
  },
});

export default ContentViewComponent;
