import { Face, FaceRetouchingOff } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useState } from 'react';

import { Order } from '#app/typings/order';

import ButtonComponent from '../../../../../Component/ButtonComponent';
import ShadowBoxComponent from '../../../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../../../Component/TitleComponent';
import { formatNumberToEurCurrency } from '../../../../../Util/NumberTool';
import CancelOrderModal from '../../../../../View/Sale/OrderClient/SaleOrderClient/components/CancelOrderModal';

type Props = {
  order: Order;
  reload: () => void;
};

const Information: React.FC<Props> = ({ order, reload }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <Grid item md={12} lg={8} xl={9}>
        <ShadowBoxComponent className={classes.box}>
          <span className={classes.state}>{order.state}</span>
          <TitleComponent
            title={
              <>
                {order.isGuest ? (
                  <Tooltip title="Guest" placement="left">
                    <FaceRetouchingOff className={classes.iconGuest} />
                  </Tooltip>
                ) : (
                  <Face className={classes.iconConnect} />
                )}{' '}
                Information Commande client
                {order?.orderClientLitiges?.some((dispute) => !dispute.dealAt) && (
                  <span className={classes.litige}>En litige</span>
                )}
              </>
            }
          />
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={4} xl={6}>
              <div>
                <b>Référence</b> : {order.number ?? '-'}
              </div>
              <div>
                <b>Nom du client</b> : {order.clientName}
              </div>
              <div>
                <b>Email du client</b> : {order.clientEmail ?? '-'}
              </div>
              <div>
                <b>Canal</b> : {order.channel ?? '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
              <b>Adresse de facturation</b>
              <div>
                {order.addressBilling.firstname} {order.addressBilling.lastname}
              </div>
              <div>{order.addressBilling.address1}</div>
              <div>{order.addressBilling.address2}</div>
              <div>{order.addressBilling.city}</div>
              <div>{order.addressBilling.zipCode}</div>
              <div>{order.addressBilling.phone}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
              <b>Adresse d'expédition</b>
              <div>
                {order.addressShipping.firstname} {order.addressShipping.lastname}
              </div>
              <div>{order.addressShipping.company}</div>
              <div>{order.addressShipping.address1}</div>
              <div>{order.addressShipping.address2}</div>
              <div>{order.addressShipping.city}</div>
              <div>{order.addressShipping.zipCode}</div>
              <div>{order.addressShipping.phone}</div>
            </Grid>
            <Grid item sm={12} lg={4} xl={3}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Prix sans remise</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceOriginalHT ? `${formatNumberToEurCurrency(order.priceOriginalHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceOriginalTTC ? `${formatNumberToEurCurrency(order.priceOriginalTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Prix payé</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.payment.priceHT ? `${formatNumberToEurCurrency(order.payment.priceHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.payment.priceTTC ? `${formatNumberToEurCurrency(order.payment.priceTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceHT ? `${formatNumberToEurCurrency(order.priceHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceTTC ? `${formatNumberToEurCurrency(order.priceTTC)} TTC` : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Remise</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceDiscountHT ? `${formatNumberToEurCurrency(order.priceDiscountHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceDiscountTTC ? `${formatNumberToEurCurrency(order.priceDiscountTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>CB</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }} colSpan={2}>
                      {formatNumberToEurCurrency(order.payment?.shopCard ?? 0)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Espèce</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }} colSpan={2}>
                      {formatNumberToEurCurrency(order.payment?.shopCash ?? 0)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Avoir</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }} colSpan={2}>
                      {formatNumberToEurCurrency(order.payment?.shopAvoir ?? 0)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Monnaie rendue</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }} colSpan={2}>
                      {formatNumberToEurCurrency(order.payment?.cashBack ?? 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <br />
          {order.stateId === 2 && (
            <ButtonComponent
              color="#982525"
              label="Annuler la commande"
              className={classes.button}
              onClick={() => setOpenModal(true)}
            />
          )}
        </ShadowBoxComponent>
      </Grid>
      {openModal && <CancelOrderModal uuid={order.uuid} onClose={() => setOpenModal(false)} reload={reload} />}
    </>
  );
};

const useStyles = makeStyles({
  box: {
    height: '100%',
    fontSize: 12,
  },
  iconGuest: {
    color: '#ffc107',
    fontSize: 22,
    verticalAlign: 'middle',
  },
  iconConnect: {
    color: '#28a745',
    fontSize: 22,
    verticalAlign: 'middle',
  },
  icon: {
    fontSize: '17px !important',
    position: 'absolute',
    right: -5,
    top: -2,
  },
  state: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
    position: 'absolute',
    top: 8,
    right: 13,
  },
  litige: {
    backgroundColor: '#F17105',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default Information;
