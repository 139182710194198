import { PlaylistAddCheck } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { isNumber } from '../../../Util/NumberTool';

function InventoryManagementReceiptOrderPurveyorList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [optionsPurveyor, setOptionsPurveyor] = React.useState([]);
  const [optionsDeposit, setOptionsDeposit] = React.useState([]);
  const [optionsOrderPurveyorState, setOptionsOrderPurveyorState] = React.useState([]);
  const [loadingContent, setLoadingContent] = React.useState(true);

  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'purveyor',
      label: 'Fournisseur',
      options: { filter: true, sort: true },
    },
    {
      name: 'deposit',
      label: 'Dépôt',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountTotal',
      label: 'Montant HT',
      options: { filter: true, sort: true },
    },
    {
      name: 'stateText',
      label: 'État',
      options: { filter: true, sort: true },
    },
    {
      name: 'deliveryAt',
      label: 'Date prévue de livraison',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const [formSearch, setFormSearch] = React.useState({
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseurs.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].purveyor
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].purveyor
          : 0,
    },
    deposit: {
      name: 'deposit',
      label: 'Dépot',
      textHelper: 'Rechercher par dépots.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].deposit
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].deposit
          : 0,
    },
    amountTotal: {
      name: 'amountTotal',
      label: 'Montant HT',
      textHelper: 'Rechercher par montant HT.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].amountTotal
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].amountTotal
          : '',
    },
    stateText: {
      name: 'stateText',
      label: 'État',
      textHelper: 'Rechercher par état.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].stateText
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].stateText
          : 0,
    },
    deliveryAt: {
      name: 'deliveryAt',
      label: 'Date prévu de livraison',
      textHelper: 'Rechercher par date prévu de livraison.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].deliveryAt
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].deliveryAt
          : '',
      options: { validation: ['date'] },
    },
    fromStartAt: {
      name: 'fromStartAt',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].fromStartAt
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].fromStartAt
          : '',
      options: { validation: ['date'] },
    },
    fromEndAt: {
      name: 'fromEndAt',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'] &&
        cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].fromEndAt
          ? cacheTableServer['api_inventory_management_receipt_order_purveyor_list'].fromEndAt
          : '',
      options: { validation: ['date'] },
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage.api_inventory_management_receipt_order_purveyor_list = 0;
    cacheTableServer.api_inventory_management_receipt_order_purveyor_list.page = 0;
    cacheTableServer.api_inventory_management_receipt_order_purveyor_list[index] = value;
    dispatch(TableReload('api_inventory_management_receipt_order_purveyor_list'));
  };
  const getAction = (row) => (
    <>
      {hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR') ||
      hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR_EDIT') ? (
        <Tooltip title={'Réception'} placement="left">
          <Link to={getRoutePathname('inventory_management_receipt_order_purveyor', { id: row.id })}>
            <IconButton>
              <PlaylistAddCheck style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      ) : (
        <IconButton disabled={true}>
          <PlaylistAddCheck style={{ color: '#17a2b8' }} />
        </IconButton>
      )}
    </>
  );

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_purveyor' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsPurveyor(data);
    });
    ApiHandler.get({ route: 'api_select_deposit' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsDeposit(data);
    });
    ApiHandler.get({ route: 'api_select_order_purveyor_state' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }];
      for (const index in response.data) {
        if (response.data[index].value === 1) continue;
        data.push(response.data[index]);
      }
      setOptionsOrderPurveyorState(data);
    });

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{ title: 'Liste des réceptions commande fournisseur', context: 'Gestion des stocks' }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'purveyor'}
                  options={optionsPurveyor}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('purveyor', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'deposit'}
                  options={optionsDeposit}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('deposit', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'stateText'}
                  options={optionsOrderPurveyorState}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('stateText', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'deliveryAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => {
                    inputSearchChange('deliveryAt', val);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'fromStartAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromStartAt', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'fromEndAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromEndAt', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_inventory_management_receipt_order_purveyor_list'}
        title={'Commandes Fournisseur'}
        columns={columns}
        search={false}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
            deposit: datas.deposit === 0 || !datas.deposit ? '' : datas.deposit,
            amountTotal: formSearch.amountTotal.value ? formSearch.amountTotal.value : '',
            stateText: datas.stateText === 0 || !datas.stateText ? '' : datas.stateText,
            deliveryAt: formSearch.deliveryAt.value ? formSearch.deliveryAt.value : '',
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
          };
          ApiHandler.get(
            {
              route: 'api_inventory_management_receipt_order_purveyor_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('ll');
                data[index].amountTotal = isNumber(data[index].amountTotal)
                  ? parseFloat(data[index].amountTotal).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].deliveryAt = (
                  <span
                    style={
                      !data[index].deliveryAt ||
                      (moment(data[index].deliveryAt).isBefore(moment()) && data[index].stateText <= 2)
                        ? { color: '#982525' }
                        : {}
                    }
                  >
                    {moment(data[index].deliveryAt).format('ll')}
                  </span>
                );
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default InventoryManagementReceiptOrderPurveyorList;
