import { CloudDownload } from '@mui/icons-material';
import { Article } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import ButtonComponent from '../../../Component/ButtonComponent';
import TableComponent from '../../../Component/TableComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { isNumber } from '../../../Util/NumberTool';

function ShopProductsList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [getStockDialog, setGetStockDialog] = React.useState(false);
  const [stocksByProductId, setStocksByProductId] = React.useState(false);
  // LIST
  const columns = [
    {
      name: 'sku',
      label: 'SKU',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'flavor',
      label: 'Parfum / Couleur',
      options: { filter: true, sort: true },
    },
    {
      name: 'mark',
      label: 'Marque',
      options: { filter: true, sort: true },
    },
    {
      name: 'packSize',
      label: 'Taille du conditionnement',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  const columnsDialog = [
    {
      name: 'deposit',
      label: 'Dépôt',
      options: { filter: false, sort: false },
    },
    {
      name: 'amountText',
      label: 'Quantité',
      options: { filter: false, sort: false },
    },
    {
      name: 'amountAvailableText',
      label: 'Quantité disponible',
      options: { filter: false, sort: false },
    },
    {
      name: 'amountCurrentSupplyText',
      label: 'Quantité en cours d’approvisionnement',
      options: { filter: false, sort: false },
    },
    {
      name: 'amountTermText',
      label: 'Quantité à terme',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    return (
      <div style={{ width: 100 }}>
        <Tooltip title={'Consulter le stock'} placement="left" onClick={() => loadStockByProduct(row.id)}>
          <IconButton>
            <Article style={{ color: '#982525' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Télécharger l'étiquette"} placement="left" onClick={() => loadStockByProduct(row.id)}>
          <IconButton
            onClick={() => {
              fetch(
                ApiHandler.route({ route: 'api_shop_seller_etiquette_download', params: { byProductId: row.id } }),
                {
                  method: 'GET',
                  mode: 'no-cors',
                  referrerPolicy: 'no-referrer',
                },
              )
                .then((res) => res.blob())
                .then((res) => {
                  const aElement = document.createElement('a');
                  aElement.setAttribute('download', row.id + '.pdf');
                  const href = URL.createObjectURL(res);
                  aElement.href = href;
                  aElement.setAttribute('target', '_blank');
                  aElement.click();
                  URL.revokeObjectURL(href);
                });
            }}
          >
            <CloudDownload style={{ color: '#28a745' }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const loadStockByProduct = (byProductId) => {
    setGetStockDialog(true);
    setStocksByProductId(byProductId);
  };

  return (
    <>
      <Grid container spacing={1} style={{ margin: 5 }}>
        <Grid item xs={12} sm={12} md={12} lg={1} xl={1}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <TableComponent
            id={'api_shop_management_shop_by_product_list'}
            title={'Articles disponibles'}
            columns={columns}
            promiseData={(resolve) => {
              ApiHandler.get(
                {
                  route: 'api_shop_management_shop_by_product_list',
                  params: { shopId: authenticationReducer.shopSelected },
                },
                (response) => {
                  const data = response.data;
                  for (const index in data) {
                    data[index].action = getAction(data[index]);
                  }
                  resolve(data);
                },
              );
            }}
          />

          <Dialog open={getStockDialog} maxWidth={'xl'} onClose={() => setGetStockDialog(false)}>
            <DialogTitle style={{ fontSize: 15 }}>Stock en boutique</DialogTitle>
            <DialogContent style={{ minWidth: '30vw' }}>
              <TableComponent
                id={'api_shop_seller_all_stocks'}
                columns={columnsDialog}
                promiseData={(resolve) => {
                  ApiHandler.get(
                    {
                      route: 'api_shop_seller_get_stock_by_product_by_shop',
                      params: { byProductId: stocksByProductId },
                    },
                    (response) => {
                      const data = response.data;

                      for (const index in data) {
                        data[index].amountText = isNumber(data[index].amount)
                          ? data[index].amount.toLocaleString()
                          : '-';
                        data[index].amountAvailableText = isNumber(data[index].amountAvailable)
                          ? data[index].amountAvailable.toLocaleString()
                          : '-';
                        data[index].amountCurrentSupplyText = isNumber(data[index].amountCurrentSupply)
                          ? data[index].amountCurrentSupply.toLocaleString()
                          : '-';
                        data[index].amountTermText = isNumber(data[index].amountTerm)
                          ? data[index].amountTerm.toLocaleString()
                          : '-';
                      }
                      resolve(data);
                    },
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => setGetStockDialog(false)} />
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}

export default ShopProductsList;
