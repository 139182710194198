import { Article, CloudDownload } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { isNumber } from '../../../Util/NumberTool';

function BillingInvoiceList() {
  const [optionsChannel, setOptionsChannel] = React.useState([]);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'État',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date de facturation',
      options: { filter: true, sort: true },
    },
    {
      name: 'priceHT',
      label: 'Prix HT',
      options: { filter: true, sort: true },
    },
    {
      name: 'priceTTC',
      label: 'Prix TTC',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    return (
      <>
        <Tooltip title={'Télécharger la facture'} placement="left">
          <IconButton
            onClick={() => {
              fetch(ApiHandler.route({ route: 'api_billing_invoice_download', params: { uuid: row.uuid } }), {
                method: 'GET',
                mode: 'no-cors',
                referrerPolicy: 'no-referrer',
              })
                .then((res) => res.blob())
                .then((res) => {
                  const aElement = document.createElement('a');
                  aElement.setAttribute('download', row.uuid + '.pdf');
                  const href = URL.createObjectURL(res);
                  aElement.href = href;
                  aElement.setAttribute('target', '_blank');
                  aElement.click();
                  URL.revokeObjectURL(href);
                });
            }}
          >
            <CloudDownload style={{ color: '#006500' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Visualiser'} placement="left">
          <Link to={getRoutePathname('sale_order_client', { uuid: row.uuid })}>
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  };

  // FormSearch
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par référence.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].number
          ? cacheTableServer['api_sale_order_client_list'].number
          : '',
    },
    clientEmail: {
      name: 'clientEmail',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].clientEmail
          ? cacheTableServer['api_sale_order_client_list'].clientEmail
          : '',
    },
    clientName: {
      name: 'clientName',
      label: 'Client',
      textHelper: 'Rechercher par client.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].clientName
          ? cacheTableServer['api_sale_order_client_list'].clientName
          : '',
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].channel
          ? cacheTableServer['api_sale_order_client_list'].channel
          : 0,
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_sale_order_client_list'] = 0;
    cacheTableServer['api_sale_order_client_list'].page = 0;
    cacheTableServer['api_sale_order_client_list'][index] = value;
    dispatch(TableReload('api_sale_order_client_list'));
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsChannel(data);
    });
  }, []);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des commandes client', context: 'Vente' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'number'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('number', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'clientEmail'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientEmail', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'clientName'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientName', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <SelectComponent
                  id={'channel'}
                  options={optionsChannel}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('channel', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_sale_order_client_list'}
        title={'Commandes client'}
        columns={columns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
            clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
            channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
          };

          ApiHandler.get(
            {
              route: 'api_billing_invoice_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].confirmedAt = moment(data[index].confirmedAt).format('lll');
                data[index].clientEmail = data[index].clientEmail ?? '-';
                data[index].clientName = data[index].clientName ?? '-';
                data[index].channel = data[index].channel ?? '-';
                data[index].orderClientState = data[index].orderClientState ?? '-';
                data[index].priceHT = isNumber(data[index].priceHT)
                  ? parseFloat(data[index].priceHT).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].priceTTC = isNumber(data[index].priceTTC)
                  ? parseFloat(data[index].priceTTC).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BillingInvoiceList;
