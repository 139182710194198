import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StateType } from '#app/Store';

import { LinearProgressMainLayoutActivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler, Form } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

const ShopCashRegister: React.FC = () => {
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);
  const [form, setForm] = useState<Form>({
    token: {
      name: 'token',
      label: 'Code signature',
      textHelper: 'Saisissez le code reçu par SMS',
      type: 'text',
      options: { validation: ['required'] },
    },
  });

  const handlerForm = initFormHandler(form, setForm);

  const saveForm = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_shop_sales_validate_sign',
          data: handlerForm.getData(),
        },
        (response) => {
          handlerForm.setFormLoading(false);

          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Ouverture / Fermeture de caisse validée et signée',
                variant: 'success',
              }),
            );

            navigate(
              getRoutePathname('shop_sales_list_today', { shopId: authenticationReducer.shopSelected as string }),
            );
          } else if (response.status === 400) {
            handlerForm.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  };

  useEffect(() => {
    if (authenticationReducer.shopSelected) {
      ApiHandler.get(
        {
          route: 'api_shop_sales_shop_info',
          params: { id: authenticationReducer.shopSelected },
        },
        (response) => {
          if (response.status === 200) {
            setSuccess(true);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  }, [authenticationReducer.shopSelected]);

  return (
    <ContentViewShopComponent>
      {success && (
        <>
          <p>Validation d'ouverture et fermeture de caisse</p>

          <Grid container spacing={1}>
            <Grid item xs={8} sm={2}>
              <TextFieldComponent id="token" handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ButtonComponent label="Valider" onClick={saveForm} />
            </Grid>
          </Grid>
        </>
      )}
    </ContentViewShopComponent>
  );
};

export default ShopCashRegister;
