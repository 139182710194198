type TableReloadAction = {
  type: 'TableReload';
  id: string;
  withCache: boolean;
};

export const TableReload = (id: string, withCache: boolean = false): TableReloadAction => ({
  type: 'TableReload',
  id,
  withCache,
});
