import React from 'react';

import ContentViewComponent from '../../../Component/ContentViewComponent';
import InventoryManagementPreparerManagerOrderList from './PreparerManagerComponents/InventoryManagementPreparerManagerOrderList';
import InventoryManagementPreparerManagerPreparerList from './PreparerManagerComponents/InventoryManagementPreparerManagerPreparerList';

const InventoryManagementPreparerManager: React.FC = () => (
  <ContentViewComponent
    breadcrumbs={{
      title: 'Manager préparateur de commande',
      context: 'Gestion des stocks',
    }}
  >
    <InventoryManagementPreparerManagerPreparerList />
    <br />
    <InventoryManagementPreparerManagerOrderList />
  </ContentViewComponent>
);

export default InventoryManagementPreparerManager;
