import { makeStyles } from '@mui/styles';
import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { StateType } from '#app/Store';

import ApiHandler from '../../Handler/ApiHandler';
import { getRoutePathname } from '../../Handler/RouteHandler';
import AppBar from './Component/AppBar';

type Props = {
  view: ComponentType;
};

const ShopLayout: React.FC<Props> = ({ view: View }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();

  const { shopSelected, shopNameSelected } = useSelector((state: StateType) => state.AuthenticationReducer);

  useEffect(() => {
    ApiHandler.post(
      {
        route: 'api_shop_sales_pending_sign',
        params: { shopId: shopSelected as string },
      },
      (response) => {
        if (response.status === 200 && response.data.blockToSign === 1 && location.pathname !== '/shop/sign') {
          navigate(getRoutePathname('shop_sales_sign_opening'));
        }
      },
    );
  }, [shopSelected, location.pathname, navigate]);

  return (
    <div className={classes.mainLayout}>
      <AppBar />

      <div className={classes.view}>
        <div style={{ padding: '2px 10px', background: '#1d90cc', color: '#fff' }}>
          <p>Vous êtes connecté à la boutique de : {shopNameSelected}</p>
        </div>

        <View />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  mainLayout: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
  },
  view: {
    flexGrow: 1,
    overflowX: 'scroll',
    paddingTop: 58,
    boxSizing: 'border-box',
  },
});

export default ShopLayout;
