import { Article, CloudDownload } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import { History } from '#app/typings/order';

import ShadowBoxComponent from '../../../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../../../Component/TitleComponent';
import ApiHandler from '../../../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../../../Handler/RouteHandler';

type Props = {
  history: History;
};

const OrderHistory: React.FC<Props> = ({ history }) => {
  const classes = useStyles();

  return (
    <Grid item md={12} lg={4} xl={3}>
      <ShadowBoxComponent className={classes.box}>
        <TitleComponent title="Historique" />
        <div className={classes.container}>
          <div>
            <b>Créée le : </b> {history.createdAt ?? '-'}
          </div>
          <div>
            <b>Confirmée le : </b> {history.confirmedAt ?? '-'}
          </div>
          <div>
            <div>
              <b>Préparée le : </b> {history.inPreparationAt ?? '-'}
            </div>
            <div>
              <b>Par : </b> {history.userPreparer ?? '-'}
            </div>
          </div>
          <div>
            <b>Colisage le : </b> {history.packagingAt ?? '-'}
          </div>
          <div>
            <div>
              <b>En transit le : </b> {history.inTransitAt ?? '-'}
            </div>
            <div>
              <b>Code : </b> {history.numberCarrier ?? '-'}
            </div>
          </div>
          <div>
            <b>Terminée le : </b> {history.completedAt ?? '-'}
          </div>
          <div>
            <div>
              <b>Annulé le : </b> {history.cancelAt ?? '-'}
            </div>
            <div>
              <b>Par : </b> {history.authorCancel ?? '-'}
            </div>
          </div>

          {history.forwardingUuid && (
            <div>
              <b>Réexpédié le : </b> {history.forwardingAt}
              <Tooltip title="Visualisation la commande réexpédiée" placement="right">
                <Link to={getRoutePathname('sale_order_client', { uuid: history.forwardingUuid })}>
                  <IconButton>
                    <Article className={classes.article} />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          )}
          {history &&
            history.orderClientCredits.map((orderClientCredit, key) => (
              <div key={key}>
                <b>Remboursé le : </b> {orderClientCredit.refundAt}
                <Tooltip title="Télécharger l'avoir de la commande" placement="right">
                  <IconButton
                    onClick={() => {
                      fetch(
                        ApiHandler.route({
                          route: 'api_sale_order_credit_download',
                          params: { uuid: orderClientCredit.uuid },
                        }) as string,
                        {
                          method: 'GET',
                          mode: 'no-cors',
                          referrerPolicy: 'no-referrer',
                        },
                      )
                        .then((res) => res.blob())
                        .then((res) => {
                          const aElement = document.createElement('a');
                          aElement.setAttribute('download', `${orderClientCredit.uuid}.pdf'`);
                          const href = URL.createObjectURL(res);
                          aElement.href = href;
                          aElement.setAttribute('target', '_blank');
                          aElement.click();
                          URL.revokeObjectURL(href);
                        });
                    }}
                  >
                    <CloudDownload className={classes.download} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
        </div>
      </ShadowBoxComponent>
    </Grid>
  );
};

const useStyles = makeStyles({
  box: {
    height: '100%',
  },
  container: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  article: {
    color: '#17a2b8',
    fontSize: 17,
    position: 'absolute',
    right: -5,
    top: -2,
  },
  download: {
    color: '#006500',
    fontSize: 17,
    position: 'absolute',
    right: -5,
    top: -2,
  },
});

export default OrderHistory;
