type DrawerMainLayoutAction = {
  type: 'DrawerMainLayoutOpen' | 'DrawerMainLayoutClose';
};

type DrawerMainLayoutState = {
  isOpen: boolean;
};

const DrawerMainLayoutReducer = (
  state: DrawerMainLayoutState = { isOpen: true },
  action: DrawerMainLayoutAction,
): DrawerMainLayoutState => {
  switch (action.type) {
    case 'DrawerMainLayoutOpen':
      return { ...state, isOpen: true };
    case 'DrawerMainLayoutClose':
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default DrawerMainLayoutReducer;
