import { CloudDownload, CloudUpload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useRef } from 'react';

import { SnackbarOpen } from '../Action/SnackbarAction';
import { dispatch } from '../App';

type Props = {
  label?: string;
  cantUpload?: boolean;
  csvUpload?: boolean;
  defaultPDF?: string;
  urlUpload: string;
  urlDownload: string;
  setIsLoadingButton?: (isLoading: boolean) => void;
  setShowModal?: (show: boolean) => void;
  namePDF?: string;
  onUpload?: (url: string) => void;
  id?: string;
};

const InputFileComponent: React.FC<Props> = ({
  label,
  cantUpload,
  csvUpload,
  defaultPDF,
  urlUpload,
  urlDownload,
  setIsLoadingButton,
  setShowModal,
  namePDF,
  onUpload,
  id,
}) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = React.useState<string | undefined>(defaultPDF);

  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>): void => {
    let val: File | null = null;

    if ('files' in e.target && e.target.files) {
      val = e.target.files[0];
    } else if ('dataTransfer' in e && e.dataTransfer) {
      const items = e.dataTransfer.items;
      if (items) {
        const fileItem = items[0]?.getAsFile();
        if (fileItem && (fileItem.type === 'application/pdf' || fileItem.type === 'text/csv')) {
          val = fileItem;
        }
      }
    }

    if (setIsLoadingButton) {
      setIsLoadingButton(true);
    }

    if (val) {
      setFile('');

      const data = new FormData();
      data.append('file', val);

      axios
        .post(urlUpload, data)
        .then(() => {
          if (setShowModal) {
            setShowModal(false);
          }
          if (setIsLoadingButton) {
            setIsLoadingButton(false);
          }
          dispatch(SnackbarOpen({ text: 'Fichier bien enregistré.', variant: 'success' }));
          setTimeout(() => setFile(urlDownload), 50);
          if (onUpload) {
            onUpload(urlDownload);
          }
        })
        .catch((err) => {
          if (setShowModal) {
            setShowModal(false);
          }
          if (setIsLoadingButton) {
            setIsLoadingButton(false);
          }
          dispatch(
            SnackbarOpen({
              text: err.response?.data?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        });
    }
  };

  const handleDownload = (): void => {
    fetch(urlDownload, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then((res) => res.blob())
      .then((blob) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', namePDF || 'file.pdf');
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  return (
    <div className={classes.contentInput}>
      <span className={classes.label}>{label}</span>

      {cantUpload && !file && <CloudUpload className={classes.uploadIcon} />}
      {cantUpload && (
        <input
          accept="application/pdf"
          className={classes.hiddenInput}
          type="file"
          ref={inputRef}
          onChange={onChange}
          onDrop={onChange}
        />
      )}

      {csvUpload && !file && <CloudUpload className={classes.uploadIcon} />}
      {csvUpload && (
        <input
          accept="text/csv"
          className={classes.hiddenInput}
          type="file"
          ref={inputRef}
          onChange={onChange}
          onDrop={onChange}
        />
      )}
      <embed src={file} className={classes.embed} />

      {id !== 'add' && file && <CloudDownload className={classes.downloadIcon} onClick={handleDownload} />}
    </div>
  );
};

const useStyles = makeStyles({
  contentInput: {
    border: '1px #585858 dashed',
    padding: 5,
    marginBottom: 15,
    height: 100,
    width: 177,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: -17,
    width: '100%',
    fontSize: 10,
  },
  uploadIcon: {
    color: '#35A2EB',
    position: 'absolute',
    bottom: 30,
    right: 72,
    fontSize: 40,
    cursor: 'pointer',
  },
  hiddenInput: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    cursor: 'pointer',
  },
  embed: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  downloadIcon: {
    color: '#28a745',
    position: 'absolute',
    top: 10,
    right: 15,
    cursor: 'pointer',
  },
});

export default InputFileComponent;
