import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AuthenticationReducer from './Reducer/AuthenticationReducer';
import DrawerMainLayoutReducer from './Reducer/DrawerMainLayoutReducer';
import LinearProgressMainLayoutReducer from './Reducer/LinearProgressMainLayoutReducer';
import ScreenReducer from './Reducer/ScreenReducer';
import SnackbarReducer from './Reducer/SnackbarReducer';
import TableReducer from './Reducer/TableReducer';

export type StateType = {
  AuthenticationReducer: ReturnType<typeof AuthenticationReducer>;
  ScreenReducer: ReturnType<typeof ScreenReducer>;
  SnackbarReducer: ReturnType<typeof SnackbarReducer>;
  DrawerMainLayoutReducer: ReturnType<typeof DrawerMainLayoutReducer>;
  LinearProgressMainLayoutReducer: ReturnType<typeof LinearProgressMainLayoutReducer>;
  TableReducer: ReturnType<typeof TableReducer>;
};

const rootReducer = combineReducers({
  AuthenticationReducer,
  ScreenReducer,
  SnackbarReducer,
  DrawerMainLayoutReducer,
  LinearProgressMainLayoutReducer,
  TableReducer,
});

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage,
  blacklist: ['SnackbarReducer', 'ScreenReducer', 'LinearProgressMainLayoutReducer', 'TableReducer'],
  whitelist: ['AuthenticationReducer', 'DrawerMainLayoutReducer'],
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

const reduxConfig = { store, persistor };

export default reduxConfig;
