import { GridFooter, GridFooterContainer, GridPanelFooter } from '@mui/x-data-grid';
import React from 'react';

import { formatNumberToEurCurrency, formatNumberWithThousandsSeparator } from '../../Util/NumberTool';

type Props = {
  totalGeneralValue: number;
  label?: string;
  valueType?: 'monetary' | 'float' | 'integer';
};

const FooterWithTotalGeneral: React.FC<Props> = ({
  totalGeneralValue,
  label = 'Total Général',
  valueType = 'monetary',
}) => (
  <GridFooterContainer>
    <GridFooter sx={{ border: 'none' }} />
    <GridPanelFooter sx={{ backgroundColor: 'yellow', marginRight: '1rem' }}>
      <strong>
        {label}:{' '}
        {valueType === 'monetary'
          ? formatNumberToEurCurrency(totalGeneralValue)
          : formatNumberWithThousandsSeparator(totalGeneralValue)}
      </strong>
    </GridPanelFooter>
  </GridFooterContainer>
);

export default FooterWithTotalGeneral;
