import { QrCodeScanner } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useSound from 'use-sound';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function CreateReappro() {
  const [beep] = useSound('/mp3/beep.mp3');
  const [boop] = useSound('/mp3/boop.mp3');

  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [loading, setLoading] = React.useState(true);
  const [loadingForm, setLoadingForm] = React.useState(false);
  const [byProducts, setByProducts] = React.useState([]);
  const [reapproState, setReapproState] = React.useState([]);
  const [isFocus, setFocus] = React.useState(false);
  const [disabled, setDisabled] = React.useState(null);

  const ref = useRef(null);
  const [formRef, setFormRef] = React.useState({
    picking: {
      name: 'picking',
      label: 'piking',
      type: 'text',
      defaultValue: '',
    },
  });
  const handlerFormRef = initFormHandler(formRef, setFormRef);

  // Form
  const [inputs, setInputs] = React.useState({});
  const handlerForm = initFormHandler(inputs, setInputs);

  const onChange = (value) => {
    for (const index in byProducts) {
      if (byProducts[index].ean && value === byProducts[index].ean) {
        dispatch(
          SnackbarOpen({
            text: 'Article enregistré.',
            variant: 'success',
          }),
        );
        beep();

        handlerForm.setValue(
          'amount_' + byProducts[index].id,
          (inputs['amount_' + byProducts[index].id].value ?? 0) + 1,
        );
        return;
      }
    }
    boop();
    dispatch(
      SnackbarOpen({
        text: 'Article introuvable !',
        variant: 'error',
      }),
    );
  };
  const save = (callback = false) => {
    if (handlerForm.checkError() < 1) {
      setLoadingForm(true);
      ApiHandler.post(
        {
          route: 'api_shop_reappro_reception_by_products_save',
          params: { reapproId: params.reapproId },
          data: { byProduct: handlerForm.getData() },
        },
        (response) => {
          setLoadingForm(false);
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Récéption enregistrée.',
                variant: 'success',
              }),
            );

            if (callback) callback();
            else navigate(getRoutePathname('shop_reappro_list', { shopId: authenticationReducer.shopSelected }));
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  };

  const precheck = () => {
    if (handlerForm.checkError() < 1) {
      setLoadingForm(true);
      ApiHandler.post(
        {
          route: 'api_shop_reappro_reception_by_products_precheck',
          params: { reapproId: params.reapproId },
          data: { byProduct: handlerForm.getData() },
        },
        (response) => {
          setLoadingForm(false);
          if (response.status === 200) {
            if (response.data.noError === 0) {
              for (const index in response.data.lignesAlerte) {
                document.getElementById(response.data.lignesAlerte[index].id).style.backgroundColor = '#FA8072';
              }
              dispatch(
                SnackbarOpen({
                  text: 'Des erreurs nécessitent votre attention.',
                  variant: 'error',
                }),
              );
              setDisabled(false);
            } else {
              save();

              dispatch(
                SnackbarOpen({
                  text: 'Demande de réappro vérifiée.',
                  variant: 'success',
                }),
              );
            }
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  };

  const presave = (callback = false) => {
    if (handlerForm.checkError() < 1) {
      setLoadingForm(true);
      ApiHandler.post(
        {
          route: 'api_shop_reappro_reception_by_products_presave',
          params: { reapproId: params.reapproId },
          data: { byProduct: handlerForm.getData() },
        },
        (response) => {
          setLoadingForm(false);
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Récéption pré-enregistrée.',
                variant: 'success',
              }),
            );

            if (callback) callback();
            else navigate(getRoutePathname('shop_reappro_list', { shopId: authenticationReducer.shopSelected }));
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  };

  React.useEffect(() => {
    handlerForm.start();
    setDisabled(true);

    ApiHandler.get(
      {
        route: 'api_shop_reappro_reception_by_products',
        params: { reapproId: params.reapproId },
      },
      (response) => {
        const byProductsInput = {};
        const marksTmp = {};
        for (const index in response.data.byProducts) {
          marksTmp[response.data.byProducts[index].markId] = {
            label: response.data.byProducts[index].markLabel,
            value: response.data.byProducts[index].markId,
          };
          byProductsInput['amount_' + response.data.byProducts[index].id] = {
            name: 'amount_' + response.data.byProducts[index].id,
            textHelper: "Saisissez la quantité de l'article.",
            label: 'Quantité',
            type: 'integer',
            defaultValue: response.data.byProducts[index].amountReceived ?? '',
            value: response.data.byProducts[index].amountReceived ?? '',
            options: {},
          };
        }

        setReapproState(response.data.reapproState);

        setInputs(byProductsInput);
        setByProducts(response.data.byProducts);
        setLoading(false);
      },
    );
  }, []);

  return (
    <ContentViewComponent
      loading={loading}
      breadcrumbs={{ title: 'Récéption de réapprovisionnement', context: 'Gestion des stocks' }}
    >
      {isFocus && (
        <QrCodeScanner style={{ color: '#28a745', position: 'absolute', top: 25, right: 50, fontSize: 35 }} />
      )}
      <TextFieldComponent
        style={{ position: 'absolute', right: 0, top: 0, height: 0, width: 50, opacity: 0, zIndex: 1000 }}
        id={'picking'}
        handler={handlerFormRef}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        inputRef={ref}
        onScan={onChange}
        autoComplete={false}
      />

      <ShadowBoxComponent className={classes.shadowBox}>
        {isFocus ? (
          <ButtonComponent
            color={'#a47b00'}
            onClick={() => {
              ref.current.blur();
            }}
            label={'Désactiver le lecteur code barre'}
            className={classes.buttonTop}
          />
        ) : (
          <ButtonComponent
            onClick={() => {
              ref.current.focus();
            }}
            label={'Activer le lecteur code barre'}
            className={classes.buttonTop}
          />
        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }}>SKU</TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Nom
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Parfum / Couleur
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Conditionnement
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Marque
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité demandée
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité reçue
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {byProducts?.length > 0 ? (
                byProducts.map((row) => (
                  <TableRow id={row.id} key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell style={{ fontSize: 11 }} component="th" scope="row">
                      {row.sku}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {row.flavor}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {row.packSize}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {row.mark}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {row.amountRequested}
                    </TableCell>
                    <TableCell style={{ fontSize: 11 }} align="right">
                      {reapproState !== 1 && reapproState !== 3 && reapproState !== 6 && (
                        <TextFieldComponent id={'amount_' + row.id} handler={handlerForm} />
                      )}
                      {(reapproState === 1 || reapproState === 3 || reapproState === 6) && row.amountReceived}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {reapproState !== 1 && reapproState !== 3 && disabled && (
            <ButtonComponent
              label={'Enregistrer'}
              className={classes.button}
              onClick={() => presave()}
              loading={loadingForm}
            />
          )}
          {reapproState !== 1 && reapproState !== 3 && disabled && (
            <ButtonComponent
              label={'Valider'}
              className={classes.button}
              onClick={() => precheck()}
              loading={loadingForm}
            />
          )}
          {reapproState !== 1 && reapproState !== 3 && !disabled && (
            <ButtonComponent
              label={'Finaliser'}
              className={classes.button}
              onClick={() => save()}
              loading={loadingForm}
            />
          )}
        </div>
      </ShadowBoxComponent>
      <br />
      <br />
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 60,
    paddingTop: 60,
  },
  button: {
    margin: '15px 15px 15px 0 !important',
  },
  buttonTop: {
    margin: '15px !important',
    top: '0 !important',
    right: '0 !important',
    position: 'absolute !important',
  },
});

export default CreateReappro;
