import moment from 'moment';
import React from 'react';

import OrderPurveyorListComponent from '../../../../Component/OrderPurveyorListComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { isNumber } from '../../../../Util/NumberTool';

function BuyProductPurveyorOrderList(props) {
  const promiseData = (resolve) => {
    ApiHandler.get(
      {
        route: 'api_buy_order_purveyor_list',
        data: { byProduct: props.byProduct.id },
      },
      (response) => {
        const data = response.data.data;
        for (const index in data) {
          data[index].amountTotal = isNumber(data[index].amountTotal)
            ? parseFloat(data[index].amountTotal).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
            : '-';
          data[index].createdAt = moment(data[index].createdAt).format('L');
          const deliveryAt = moment(data[index].deliveryAt).format('L');
          data[index].deliveryAt =
            !data[index].deliveryAt || (moment(data[index].deliveryAt).isBefore(moment()) && data[index].state <= 2) ? (
              <span style={{ color: '#982525' }}>{deliveryAt}</span>
            ) : (
              <span>{deliveryAt}</span>
            );
        }

        resolve(data);
      },
    );
  };

  return (
    <OrderPurveyorListComponent
      promiseData={promiseData}
      tableId="api_buy_order_purveyor_list"
      displayPurveyor={true}
    />
  );
}

export default BuyProductPurveyorOrderList;
