import { AnyAction } from 'redux';

import { SnackbarOpen } from '../Action/SnackbarAction';
import { dispatch } from '../App';

type ErrorResponse = {
  error?: {
    message?: string;
  };
};

export const displayErrorMessage = (response: ErrorResponse): AnyAction => {
  const errorMessage = response?.error?.message ?? "Une erreur inattendue s'est produite.";
  return dispatch(SnackbarOpen({ text: errorMessage, variant: 'error' }));
};
