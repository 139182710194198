import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { dispatch } from '../../../App';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import SaleCreatePromoForm from './SalePromoComponents/SaleCreatePromoForm';

type Promo = {
  [key: string]: any;
};

const SalePromo: React.FC = () => {
  const params = useParams<{ id?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [promo, setPromo] = useState<Promo | null>(null);

  useEffect(() => {
    ApiHandler.get(
      {
        route: params.id ? 'api_sale_promo' : 'api_sale_promo_add',
        params: params.id ? { id: params.id } : undefined,
      },
      (response) => {
        if (response.status === 200) {
          setPromo(response.data);
          setLoading(false);
        } else {
          dispatch(
            SnackbarOpen({
              text: response.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  }, []);

  return (
    <ContentViewComponent
      loading={loading}
      breadcrumbs={{
        title: 'Code promo',
        context: 'Vente',
        links: [{ path: getRoutePathname('sale_promo_list'), label: 'Liste des promos' }],
      }}
    >
      {promo && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SaleCreatePromoForm promo={promo} />
          </Grid>
        </Grid>
      )}

      <br />
      <br />
    </ContentViewComponent>
  );
};

export default SalePromo;
