import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateType } from '#app/Store';
import { Client } from '#app/View/Sale/Client/SaleClient';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';

type Props = {
  client: Client;
};

const SaleCreateClientForm: React.FC<Props> = ({ client }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);

  const [optionsCountry, setOptionsCountry] = useState<Array<{ value: number; label: string }>>([]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_SHOP_SHOP_SALES') && !hasRight(authenticationReducer, 'ROLE_SHOP_SELLER');

  const [form, setForm] = useState<Form>({
    uuid: {
      name: 'uuid',
      label: 'ID automatique',
      textHelper: 'Ne pas remplir.',
      type: 'text',
    },
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      textHelper: 'Saisissez le prénom.',
      type: 'text',
      options: { validation: ['required'] },
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      textHelper: 'Saisissez le nom.',
      type: 'text',
      options: { validation: ['required'] },
    },
    zipCode: {
      name: 'zipCode',
      label: 'Code postal',
      textHelper: 'Saisissez le code postal.',
      type: 'text',
      options: { validation: ['required'] },
    },
    email: {
      name: 'email',
      label: 'Email',
      textHelper: "Saisissez l'adresse email.",
      type: 'text',
      options: { validation: ['required'] },
    },
    country: {
      name: 'country',
      label: 'Pays',
      textHelper: 'Sélectionnez le pays du client.',
      type: 'integer',
      options: { validation: ['required'] },
    },
    phone: {
      name: 'phone',
      label: 'Téléphone',
      textHelper: 'Saisissez le téléphone.',
      type: 'text',
      options: { validation: ['required'] },
    },
    optinSms: {
      name: 'optinSms',
      label: 'Recevoir SMS TLN',
      textHelper: 'Cochez si OK',
      type: 'boolean',
      defaultValue: client.optinSms ?? false,
    },
    optinEmail: {
      name: 'optinEmail',
      label: 'Recevoir emails TLN',
      textHelper: 'Cochez si OK',
      type: 'boolean',
      defaultValue: client.optinEmail ?? false,
    },
  });

  const handlerForm = initFormHandler(form, setForm);

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_sale_update_client',
          data: handlerForm.getData(),
        },
        (response: any) => {
          if (response.status === 200) {
            dispatch(SnackbarOpen({ text: 'Client modifié avec succès.', variant: 'success' }));
          } else if (response.status === 400) {
            handlerForm.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          dispatch(LinearProgressMainLayoutDeactivate());
          handlerForm.setFormLoading(false);
          setIsLoadingForm(false);
        },
      );
    }
  };
  React.useEffect(() => {
    handlerForm.start();
  }, []);
  React.useEffect(() => {
    ApiHandler.get({ route: 'api_select_country' }, (response) => {
      setOptionsCountry(response.data);
    });

    if (client) {
      handlerForm.setDataApi(client);
    }
  }, [client]);

  useEffect(() => {
    handlerForm.setFormLoading(justWatch);
  }, [justWatch]);

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title="Nouveau client" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="uuid" handler={handlerForm} disabled />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="firstname" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="lastname" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="zipCode" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="email" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <SelectComponent id="country" options={optionsCountry} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldComponent id="phone" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxComponent id="optinSms" handler={handlerForm} orientation="right" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxComponent id="optinEmail" handler={handlerForm} orientation="right" />
        </Grid>
      </Grid>

      {!justWatch && (
        <ButtonComponent label="Enregistrer" className={classes.button} onClick={save} loading={isLoadingForm} />
      )}
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default SaleCreateClientForm;
