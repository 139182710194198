import { HourglassEmpty, AddCircleOutline, RemoveCircleOutline, QrCode2, QrCodeScanner } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';
import useSound from 'use-sound';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function InventoryManagementPreparerOrderList() {
  const classes = useStyles();
  const screenReducer = useSelector((state) => state.ScreenReducer);

  const [beep] = useSound('/mp3/beep.mp3');
  const [boop] = useSound('/mp3/boop.mp3');
  const [completeOrder, setCompleteOrder] = React.useState(true);
  const [line, setLine] = React.useState(null);
  const [tooMuch, setTooMuch] = React.useState(false);
  const [isFocus, setFocus] = React.useState(false);
  const [isSmallView, setSmallView] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadingDialog, setLoadingDialog] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const [order, setOrder] = React.useState(null);
  const columns = [
    {
      name: 'sku',
      label: 'SKU',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'amount',
      label: 'Quantité',
      options: { filter: true, sort: true },
    },
  ];
  const columnsPacking = [
    {
      name: 'sku',
      label: 'SKU',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'amount',
      label: 'Quantité',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountPicking',
      label: 'Quantité scannée',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  // Picking
  const ref = useRef(null);
  const [form, setForm] = React.useState({
    picking: {
      name: 'picking',
      label: 'piking',
      type: 'text',
      defaultValue: '',
    },
  });
  const handlerForm = initFormHandler(form, setForm);

  // Save
  const [openDialogText, setOpenDialogText] = React.useState('');
  const save = (withConfirm, orderComplete = true) => {
    if (handlerForm.checkError() < 1) {
      order.complete = orderComplete;
      if (withConfirm) {
        let success = true;
        setTooMuch(false);
        const text = (
          <div style={{ width: '100%', padding: 10 }}>
            <p style={{ fontWeight: 800 }}>Récapitulatif du colisage de commande : </p>
            {order.byProducts.map((byProduct, key) => {
              let info;
              let state;

              if (byProduct.amountPicking < byProduct.amount) {
                info = '...colisage incomplet';
                state = 'packagingWarning';
                success = false;
              } else if (byProduct.amountPicking > byProduct.amount) {
                info = '...colisage trop élevé';
                state = 'packagingError';
                setTooMuch(true);
                success = false;
              } else {
                info = '...colisage complet';
                state = 'packagingSuccess';
              }

              return (
                <div key={key}>
                  {byProduct.name} <span className={classes[state]}> {info}.</span>
                </div>
              );
            })}
          </div>
        );

        if (success) beep();
        else boop();

        setCompleteOrder(success);
        setOpenDialogText(text);
        return;
      }

      beep();
      setLoadingDialog(true);
      dispatch(LinearProgressMainLayoutActivate());

      setOrders([]);
      ApiHandler.post(
        {
          route: 'api_inventory_management_preparer_order_edit',
          data: order,
          params: { uuid: order.uuid },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Colisage terminé.',
                variant: 'success',
              }),
            );
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          dispatch(LinearProgressMainLayoutDeactivate());
          setLoadingDialog(false);

          setOpenDialogText('');
          setOrder(null);
          ApiHandler.get({ route: 'api_inventory_management_preparer_order_list' }, (response) =>
            setOrders(response.data),
          );
        },
      );
    }
  };

  // Line
  const [forceFocus, setForceFocus] = React.useState(false);
  const [formLine, setFormLine] = React.useState({
    ean: {
      name: 'ean',
      label: 'Code EAN',
      textHelper: 'Saisissez le code EAN.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    amount: {
      name: 'amount',
      label: 'Quantité préparée',
      textHelper: 'Saisissez la quantité préparée.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const handlerFormLine = initFormHandler(formLine, setFormLine);
  const saveLine = () => {
    if (handlerFormLine.checkError() < 1) {
      const data = handlerFormLine.getData();
      if (order.byProducts[line].ean) {
        if (data.ean === order.byProducts[line].ean) {
          order.byProducts[line].amountPicking = data.amount;
          if (order.byProducts[line].amountPicking > order.byProducts[line].amount) {
            boop();
            handlerFormLine.setError('amount', "Trop d'articles saisis !");
            return;
          }

          beep();
          setOrder({ ...order });
          dispatch(TableReload('api_inventory_management_preparer_order_list'));
          setLine(null);
          dispatch(
            SnackbarOpen({
              text: 'Article(s) enregistré(s).',
              variant: 'success',
            }),
          );
        } else {
          handlerFormLine.setError('ean', 'Code EAN erroné.');
        }
      }
    }
  };
  const getAction = (index, isPiking) => {
    return !isPiking ? (
      <>
        <Tooltip title={'Supprimer'} placement="left">
          <IconButton
            disabled={order.byProducts[index].amountPicking < 1}
            onClick={() => {
              order.byProducts[index].amountPicking -= 1;
              setOrder({ ...order });
              dispatch(TableReload('api_inventory_management_preparer_order_list'));
            }}
          >
            <RemoveCircleOutline style={order.byProducts[index].amountPicking < 1 ? {} : { color: '#982525' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Ajouter'} placement="right">
          <IconButton
            onClick={() => {
              order.byProducts[index].amountPicking += 1;
              setOrder({ ...order });
              dispatch(TableReload('api_inventory_management_preparer_order_list'));
            }}
          >
            <AddCircleOutline style={{ color: '#28a745' }} />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip title={'Supprimer'} placement="left">
          <IconButton
            disabled={order.byProducts[index].amountPicking < 1}
            onClick={() => {
              order.byProducts[index].amountPicking -= 1;
              setOrder({ ...order });
              dispatch(TableReload('api_inventory_management_preparer_order_list'));
              ref.current.focus();
            }}
          >
            <RemoveCircleOutline style={order.byProducts[index].amountPicking < 1 ? {} : { color: '#982525' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Saisie manuelle'} placement="right">
          <IconButton
            onClick={() => {
              handlerFormLine.reset();
              setLine(index);
            }}
          >
            <QrCode2 style={{ color: '#17a2b8' }} />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  const onChange = (value) => {
    if (loadingDialog) return;

    if (order) {
      if (order.ean === value) {
        save(!openDialogText, true);
        return;
      }

      for (const index in order.byProducts) {
        if (order.byProducts[index].ean) {
          if (value === order.byProducts[index].ean) {
            if (order.byProducts[index].amountPicking >= order.byProducts[index].amount) {
              boop();
              dispatch(
                SnackbarOpen({
                  text: "Trop d'articles scannés !",
                  variant: 'error',
                }),
              );
            } else {
              beep();
              order.byProducts[index].amountPicking += 1;
              setOrder({ ...order });
              dispatch(TableReload('api_inventory_management_preparer_order_list'));
              dispatch(
                SnackbarOpen({
                  text: 'Article enregistré.',
                  variant: 'success',
                }),
              );
            }

            return;
          }
        }
      }

      boop();
      dispatch(
        SnackbarOpen({
          text: 'Article introuvable !',
          variant: 'error',
        }),
      );
    } else {
      for (const index in orders) {
        if (orders[index].ean) {
          if (value === orders[index].ean) {
            if (orders[index].orderClientState !== 3) {
              boop();
              dispatch(
                SnackbarOpen({
                  text: 'Commande déjà colisage.',
                  variant: 'warning',
                }),
              );
            } else {
              beep();
              for (const index2 in orders[index].byProducts) orders[index].byProducts[index2].amountPicking = 0;
              setOrder(orders[index]);
              ref.current.focus();
              dispatch(
                SnackbarOpen({
                  text: 'Démarrage du colisage.',
                  variant: 'success',
                }),
              );
            }
            return;
          }
        }
      }

      boop();
      dispatch(
        SnackbarOpen({
          text: 'Commande introuvable !',
          variant: 'error',
        }),
      );
    }
  };

  React.useEffect(() => {
    ApiHandler.get({ route: 'api_inventory_management_preparer_order_list' }, (response) => {
      setOrders(response.data);
      setLoading(false);
    });
    const interval = setInterval(() => {
      if (orders.length < 1)
        ApiHandler.get({ route: 'api_inventory_management_preparer_order_list' }, (response) =>
          setOrders(response.data),
        );
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current && !line) ref.current.focus();
      setForceFocus(!forceFocus);
    }, 2000);
  }, [forceFocus]);
  React.useEffect(() => {
    setSmallView(screenReducer.screen === 'XS' || screenReducer.screen === 'SM');
  }, [screenReducer.screen]);

  return (
    <ContentViewComponent
      breadcrumbs={{
        title: 'Préparation commande',
        context: 'Gestion des stocks',
      }}
    >
      {isFocus && (
        <QrCodeScanner style={{ color: '#28a745', position: 'absolute', top: 25, right: 50, fontSize: 35 }} />
      )}
      {!openDialogText && (
        <TextFieldComponent
          style={{ position: 'absolute', right: 0, top: 0, height: 0, width: 50, opacity: 0, zIndex: 1000 }}
          id={'picking'}
          handler={handlerForm}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          inputRef={ref}
          onScan={onChange}
          autoComplete={false}
        />
      )}

      {order ? (
        <Fade in timeout={750}>
          <div>
            <ShadowBoxComponent className={classes.shadowBox}>
              <span className={classes.state}>{order.orderClientStateText}</span>
              <TitleComponent title={'Commande : ' + order.number} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span style={{ fontWeight: '600' }}>Transporteur : </span> <span>{order.carrier}</span>
                </div>
                <div style={{ flex: 1 }}>
                  <span style={{ fontWeight: '600' }}>Canal : </span> <span>{order.channel}</span>
                </div>
              </div>
              <br />

              <div>
                <TableComponent
                  noShadow={true}
                  id={'api_inventory_management_preparer_order_list'}
                  title={'Articles'}
                  columns={columnsPacking}
                  actionSecond={
                    isFocus
                      ? {
                          label: 'Désactiver le lecteur code barre',
                          onClick: () => {
                            ref.current.blur();
                          },
                          color: '#a47b00',
                        }
                      : {
                          label: 'Activer le lecteur code barre',
                          onClick: () => {
                            ref.current.focus();
                          },
                        }
                  }
                  promiseData={(resolve) => {
                    for (const index in order.byProducts)
                      order.byProducts[index].action = getAction(index, !!order.byProducts[index].ean);
                    resolve(order.byProducts);
                  }}
                />
              </div>

              <ButtonComponent
                className={classes.button}
                color={'#28a745'}
                label={'Colisage terminé'}
                onClick={() => save(true, true)}
              />
              <ButtonComponent
                className={classes.button}
                label={'Enregistrer (partiel)'}
                onClick={() => save(false, false)}
              />
              <ButtonComponent
                className={classes.button}
                color={'#982525'}
                label={'Annuler'}
                onClick={() => setOrder(null)}
              />
            </ShadowBoxComponent>
          </div>
        </Fade>
      ) : (
        <>
          {orders?.length < 1 && (
            <div className={classes.empty}>
              <div className={classes.emptyContent} style={isSmallView ? { width: 225 } : {}}>
                <ShadowBoxComponent>
                  <div className={classes.emptyActivity}>
                    <HourglassEmpty style={{ color: '#17a2b8', fontSize: 50 }} />
                    <br />
                  </div>
                  <p>Chargement des commandes...</p>
                </ShadowBoxComponent>
              </div>
            </div>
          )}
          <Grid container spacing={2}>
            {orders?.map((order, key) => {
              return (
                <Fade in timeout={750} key={key}>
                  <Grid key={key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <ShadowBoxComponent
                      className={classes.shadowBox}
                      style={order.orderClientState === 4 ? { paddingBottom: 10 } : {}}
                    >
                      <span className={classes.state}>{order.orderClientStateText}</span>
                      <TitleComponent title={'Commande : ' + order.number} />
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1 }}>
                          <span style={{ fontWeight: '600' }}>Transporteur : </span> <span>{order.carrier}</span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <span style={{ fontWeight: '600' }}>Canal : </span> <span>{order.channel}</span>
                        </div>
                      </div>

                      {order.ean && (
                        <>
                          <br />
                          <div style={{ textAlign: 'center' }}>
                            <Barcode value={order.ean} height={50} fontSize={10} />
                          </div>
                        </>
                      )}

                      <br />

                      <div>
                        <TableComponent
                          noShadow={true}
                          id={'api_inventory_management_preparer_order_list'}
                          title={'Articles'}
                          columns={columns}
                          promiseData={(resolve) => resolve(order.byProducts)}
                        />
                      </div>

                      {order.orderClientState === 3 && (
                        <ButtonComponent
                          label={'Démarrer le colisage'}
                          style={{ position: 'absolute', bottom: 5, right: 15 }}
                          className={classes.button}
                          onClick={() => {
                            for (const index in order.byProducts) {
                              order.byProducts[index].amountPicking ??= 0;
                            }
                            setOrder(order);
                            ref.current.focus();
                          }}
                        />
                      )}
                    </ShadowBoxComponent>
                  </Grid>
                </Fade>
              );
            })}
          </Grid>
        </>
      )}

      <Dialog open={!!openDialogText} maxWidth={'xl'} onClose={() => setOpenDialogText('')}>
        <DialogTitle style={{ fontSize: 15 }}>{'Confirmation colisage'}</DialogTitle>
        <DialogContent style={{ minWidth: '30vw', position: 'relative', overflow: 'hidden' }}>
          {openDialogText}
          {!!openDialogText && (
            <TextFieldComponent
              style={{ position: 'absolute', right: 0, top: 0, height: 0, width: 50, opacity: 0, zIndex: 1000 }}
              id={'picking'}
              handler={handlerForm}
              onBlur={() => setFocus(false)}
              onFocus={() => setFocus(true)}
              inputRef={ref}
              onScan={() => {
                if (completeOrder) onChange();
                else boop();
              }}
              autoComplete={false}
            />
          )}

          {!completeOrder && (
            <div style={{ color: '#982525', textAlign: 'center' }}>
              <UseAnimations wrapperStyle={{ margin: 'auto' }} animation={alertCircle} size={100} />
              <p>La commande est incomplète êtes-vous certain de vouloir faire une expédition partielle ?</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setOpenDialogText('')}
            loading={loadingDialog}
          />
          <ButtonComponent
            label={completeOrder ? 'Enregistrer' : 'Oui, je suis sur.'}
            onClick={() => {
              save(false, true);
            }}
            loading={loadingDialog}
            disabled={tooMuch}
            color={tooMuch ? 'rgba(132,132,132,0.48)' : ''}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={!!line} maxWidth={'xl'} onClose={() => setLine(null)}>
        <DialogTitle style={{ fontSize: 15 }}>
          {order && order.byProducts && order.byProducts[line] ? order.byProducts[line].sku : ''}
        </DialogTitle>
        <DialogContent style={{ minWidth: '40vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent id={'ean'} handler={handlerFormLine} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent id={'amount'} handler={handlerFormLine} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setLine(null)} disabled={loading} />
          <ButtonComponent label={'Enregistrer'} onClick={saveLine} loading={loading} />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 56,
    height: '100%',
  },
  state: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
    position: 'absolute',
    top: 8,
    right: 13,
  },
  empty: {
    textAlign: 'center',
  },
  emptyContent: {
    marginTop: 'calc(50vh - 130px)',
    transform: 'translateY(-50%)',
    width: 300,
    margin: 'auto',
  },
  emptyActivity: {
    '& > div': {
      margin: 'auto',
      width: '15% !important',
    },
  },
  button: {
    margin: '10px 15px 10px -5px !important',
    float: 'right',
  },
  packagingWarning: {
    color: '#a47b00',
  },
  packagingError: {
    color: '#982525',
  },
  packagingSuccess: {
    color: '#28a745',
  },
});

export default InventoryManagementPreparerOrderList;
