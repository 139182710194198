import ApiHandler from '../Handler/ApiHandler';

export type Option = { value: number | null; label: string };
type SetOptionsCallback = (options: Option[]) => void;

export const fetchPurveyors = (setOptionsPurveyor: SetOptionsCallback): void => {
  ApiHandler.get({ route: 'api_select_purveyor' }, (response) => {
    const data = [{ value: 0, label: 'Tous' }, ...(response.data || [])];
    setOptionsPurveyor(data);
  });
};

export const fetchPurveyorTypes = (setOptionsPurveyorType: SetOptionsCallback): void => {
  ApiHandler.get({ route: 'api_select_purveyor_type' }, (response) => {
    const data = [{ value: 0, label: 'Tous' }, ...(response.data || [])];
    setOptionsPurveyorType(data);
  });
};

export const fetchStatsChannels = (setOptionsStatsChannels: SetOptionsCallback): void => {
  ApiHandler.get(
    {
      route: 'api_select_channel_stats',
      data: { limited: true, from: 'stats' },
    },
    (response) => {
      const options = [{ value: null, label: 'Tous' }, ...(response.data || [])];
      setOptionsStatsChannels(options);
    },
  );
};

export const fetchPaymentMethods = (setOptionsPaymentMethods: SetOptionsCallback): void => {
  ApiHandler.get({ route: 'api_select_payment_method' }, (response) => {
    const data = [{ value: null, label: 'Tous' }, ...(response.data || [])];
    setOptionsPaymentMethods(data);
  });
};
