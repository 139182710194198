import { PowerSettingsNew } from '@mui/icons-material';
import { Toolbar, AppBar as AppBarMUI, IconButton, Tooltip, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { StateType } from '#app/Store';

import { AuthenticationLogout } from '../../../Action/AuthenticationAction';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

const AppBar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const shopSelected = authenticationReducer.shopSelected as string;

  const logout = () => {
    ApiHandler.get({ route: 'api_authentication_logout' }, () => {});
    dispatch(AuthenticationLogout());
    window.location.reload();
  };

  const actionLinks = [
    {
      title: 'Nouvelle vente',
      route: getRoutePathname('shop_sales_client_list', { shopId: shopSelected }),
    },
    {
      title: 'Historique des ventes',
      route: getRoutePathname('shop_sales_list', { shopId: shopSelected }),
    },
    {
      title: 'Demande appro',
      route: getRoutePathname('shop_reappro_create', { id: shopSelected }),
    },
    {
      title: 'Réceptionner appro',
      route: getRoutePathname('shop_reappro_list', { shopId: shopSelected }),
    },
    {
      title: 'Inventaire',
      route: getRoutePathname('inventory_shop_management_inventory_list'),
    },
    {
      title: 'Retour article',
      route: getRoutePathname('inventory_shop_management_return_order'),
    },
    {
      title: 'Articles et stocks',
      route: getRoutePathname('shop_list_products', { shopId: shopSelected }),
    },
  ];

  return (
    <AppBarMUI position="fixed" className={classes.appBar}>
      <Link to={getRoutePathname('shop_sales_list_today', { shopId: shopSelected })}>
        <img src="/image/shop_1.png" alt="logo" className={classes.appBarLogo} />
      </Link>
      <Tooltip title="Déconnexion" aria-label="Déconnexion" style={{ position: 'absolute' }}>
        <IconButton className={classes.appBarLogout} onClick={logout}>
          <PowerSettingsNew className={classes.appBarLogoutIcon} />
        </IconButton>
      </Tooltip>
      <Toolbar style={{ margin: '0 auto' }}>
        {actionLinks.map((action, index) => (
          <Tooltip key={index} title={action.title} placement="left">
            <Link to={action.route} className={classes.actionButton}>
              <IconButton style={{ fontSize: 18, color: '#fff' }}>{action.title}</IconButton>
            </Link>
          </Tooltip>
        ))}
      </Toolbar>
      <LinearProgress />
    </AppBarMUI>
  );
};

const useStyles = makeStyles({
  appBar: {
    zIndex: 2,
    backgroundColor: '#FFFFFF !important',
    color: 'rgba(0, 0, 0, 0.5)',
    height: 58,
    textAlign: 'center',
    '& .MuiToolbar-regular': {
      height: 58,
      minHeight: '58px !important',
      paddingLeft: 23,
      paddingRight: 23,
    },
  },
  appBarLogo: {
    height: 35,
    paddingLeft: 26,
    position: 'absolute',
    left: 0,
    top: '10px',
  },
  appBarLogout: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '10px !important',
    margin: '8px !important',
    marginRight: '12px !important',
    color: '#5E6E82 !important',
  },
  appBarLogoutIcon: {
    fontSize: 22,
  },
  actionButton: {
    border: '1px solid black',
    margin: '0 5px',
    background: '#0484c7',
  },
});

export default AppBar;
