import { AddCircleOutline } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import TabsComponent from '../../../../Component/TabsComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import BuyProductByProduct from './BuyProductByProduct';

function BuyProductByProductTabs(props) {
  const dispatch = useDispatch();

  const [tabPackSizes, setTabPackSizes] = React.useState([]);
  const [tabPanelPackSizes, setTabPanelPackSizes] = React.useState([]);
  const [tabByProductReload, setTabByProductReload] = React.useState(false);

  React.useEffect(() => {
    if (tabPanelPackSizes.length < 1) {
      setTabPackSizes([
        {
          id: 0,
          key: `tab_pack_size_${0}`,
          label: <span> Chargement des articles... </span>,
        },
      ]);

      setTabPanelPackSizes([]);
    }

    ApiHandler.get(
      {
        route: 'api_buy_product_by_product_list',
        params: { productId: props.product.id },
      },
      (response) => {
        if (response.status === 200) {
          // TAB PackSizes
          const tabPackSizesTMP = [];
          for (const index in response.data) {
            const byProduct = response.data[index];
            let mustSave = true;

            for (const index in tabPackSizesTMP) {
              if (tabPackSizesTMP[index].id === byProduct.packSize) {
                mustSave = false;
                break;
              }
            }

            if (mustSave) {
              tabPackSizesTMP.push({
                id: byProduct.packSize,
                key: `tab_pack_size_${byProduct.packSize}`,
                label: <span>{byProduct.packSizeLabel}</span>,
              });
            }
          }
          tabPackSizesTMP.push({
            id: 0,
            key: `tab_pack_size_${0}`,
            label: (
              <span>
                {' '}
                Ajouter un sous article{' '}
                <AddCircleOutline style={{ fontSize: 13, verticalAlign: 'sub', paddingLeft: 2 }} />
              </span>
            ),
          });
          setTabPackSizes(tabPackSizesTMP);

          // PANEL PackSizes => flavor
          const panelPackSizesTMP = [];
          for (const index in tabPackSizesTMP) {
            const panelPackSizeTMP = {
              id: tabPackSizesTMP[index].id,
              key: `tab_pack_size_${tabPackSizesTMP[index].id}`,
            };

            // TAB & PANEL Flavor
            const tabFlavorsTMP = [];
            const panelFlavorsTMP = [];
            for (const index in response.data) {
              const byProduct = response.data[index];
              let mustSave = true;

              if (byProduct.packSize !== panelPackSizeTMP.id) continue;

              for (const index in tabFlavorsTMP) {
                if (tabFlavorsTMP[index].id === byProduct.flavor) {
                  mustSave = false;
                  break;
                }
              }

              if (mustSave) {
                tabFlavorsTMP.push({
                  id: byProduct.flavor,
                  key: `tab_flavor_${byProduct.flavor}`,
                  label: <span>{byProduct.flavorLabel}</span>,
                });

                panelFlavorsTMP.push({
                  byProduct: byProduct,
                  id: byProduct.flavor,
                  key: `tab_flavor_${byProduct.flavor}`,
                });

                if (!panelPackSizeTMP.tabDefaultFlavor) {
                  panelPackSizeTMP.tabDefaultFlavor = `tab_flavor_${byProduct.flavor}`;
                }
              }
            }
            panelPackSizeTMP.tabFlavors = tabFlavorsTMP;
            panelPackSizeTMP.panelFlavors = panelFlavorsTMP;
            panelPackSizesTMP.push(panelPackSizeTMP);
          }

          setTabPanelPackSizes(panelPackSizesTMP);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  }, [tabByProductReload]);

  return (
    <TabsComponent
      tabDefault={'tab_pack_size_0'}
      tabs={tabPackSizes}
      tab={tabPackSizes && tabPackSizes[0] && tabPackSizes[0].key ? tabPackSizes[0].key : 'tab_pack_size_0'}
    >
      {tabPanelPackSizes.map((tabPanelPackSize) => {
        return (
          <div key={tabPanelPackSize.key}>
            <div>
              {tabPanelPackSize.id === 0 ? (
                <div style={{ padding: 12 }}>
                  <BuyProductByProduct
                    product={props.product}
                    reload={() => setTabByProductReload(!tabByProductReload)}
                    setIsUpdateSylius={props.setIsUpdateSylius}
                  />
                </div>
              ) : (
                <TabsComponent
                  color={'#9c27b0'}
                  tabDefault={tabPanelPackSize.tabDefaultFlavor}
                  tabs={tabPanelPackSize.tabFlavors}
                >
                  {tabPanelPackSize.panelFlavors.map((panelFlavor) => {
                    return (
                      <div key={panelFlavor.key} style={{ padding: 12 }}>
                        <BuyProductByProduct
                          reload={() => setTabByProductReload(!tabByProductReload)}
                          product={props.product}
                          byProduct={panelFlavor.byProduct}
                          setIsUpdateSylius={props.setIsUpdateSylius}
                        />
                      </div>
                    );
                  })}
                </TabsComponent>
              )}
            </div>
          </div>
        );
      })}
    </TabsComponent>
  );
}

export default BuyProductByProductTabs;
