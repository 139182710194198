import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '#app/Store';

type TabProps = {
  key: string;
  label: string;
  hidden?: boolean;
  disabled?: boolean;
  wrapped?: boolean;
};

type Props = {
  color?: string;
  tabs?: TabProps[];
  tab?: string;
  tabDefault?: string;
  centered?: boolean;
};

const TabsComponent: React.FC<PropsWithChildren<Props>> = ({
  color = '#57A585',
  tabs = [],
  tab = '',
  children,
  tabDefault = '',
  centered,
}) => {
  const classes = useStyles(color)();
  const screenReducer = useSelector((state: StateType) => state.ScreenReducer);
  const [isTall, setIsTall] = React.useState(true);

  const tabPanels = React.Children.map(children, (child, index) => ({
    key: tabs[index]?.key ?? index.toString(),
    content: child,
  }));

  const [value, setValue] = React.useState<string>(tabDefault);

  useEffect(() => {
    if (tab) {
      setValue(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (screenReducer.screen) {
      const isTallScreen =
        screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
      if (isTallScreen !== isTall) setIsTall(isTallScreen);
    }
  }, [screenReducer.screen]);

  return (
    <div className={`${classes.root} ${!isTall && classes.rootSmall}`}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={(_, newValue: string) => setValue(newValue)}
          centered={centered}
          TabIndicatorProps={{ style: { background: color } }}
        >
          {tabs
            .filter((tab) => !tab.hidden)
            .map((tab) => (
              <Tab
                key={tab.key}
                id={`wrapped-tab-${tab.key}`}
                aria-controls={`wrapped-tabpanel-${tab.key}`}
                value={tab.key}
                label={tab.label}
                disabled={tab.disabled}
                wrapped={tab.wrapped}
              />
            ))}
        </Tabs>
      </AppBar>
      {tabPanels?.map((tabPanel) => (
        <TabPanel key={tabPanel.key} className={classes.tab} value={value} index={tabPanel.key}>
          {tabPanel.content}
        </TabPanel>
      ))}
    </div>
  );
};

type TabPanelProps = {
  value: string;
  index: string;
  className?: string;
};

const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index, className }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    className={className}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const useStyles = (color: string) =>
  makeStyles({
    root: {
      boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
      borderRadius: 5,
      flexGrow: 1,
      '& > header': {
        background: 'transparent',
        boxShadow: 'none',
        display: 'block',
        height: 30,
        '& .MuiTabs-scroller': {
          height: 29,
          borderBottom: '1px solid #ccc',
        },
        '& .MuiTabs-root': {
          background: 'none',
          height: 30,
          minHeight: 30,
          minWidth: 100,
          padding: 0,
          '& .MuiTabs-indicator': {
            background: color,
          },
          '& .MuiTabs-flexContainer': {
            background: '#ffffff',
            height: 30,
            minHeight: 30,
            borderRadius: '5px 5px 0 0',
            '& .MuiButtonBase-root': {
              background: 'none',
              fontSize: 10,
              color: '#5E6E82',
              fontWeight: 400,
              padding: '0 15px',
              height: 30,
              minHeight: 30,
              lineHeight: '30px',
              '&.Mui-selected ': {
                color: color,
              },
              '& .MuiTab-wrapper': {
                textTransform: 'none',
              },
            },
          },
        },
      },
    },
    rootSmall: {
      '& header': {
        '& .MuiTabs-root': {
          '& .MuiTabs-flexContainer': {
            '& .MuiButtonBase-root': {
              fontSize: 10,
            },
          },
        },
      },
    },
    tab: {
      background: '#fff',
      margin: 0,
      borderRadius: '0 0 5px 5px',
      overflow: 'hidden',
      '& > div': {
        padding: 0,
        width: '100%',
        boxSizing: 'border-box',
        margin: 0,
        '& > div': {
          padding: 0,
          width: '100%',
          boxSizing: 'border-box',
          margin: 0,
        },
      },
    },
  });

export default TabsComponent;
