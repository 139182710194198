import { Edit } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { hasRight } from '../../../Common/UserCommon';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function SettingTvaCountryList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tvaCountry, setTvaCountry] = React.useState(null);
  const [optionCountry, setOptionCountry] = React.useState([]);
  const [optionTva, setOptionTva] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'code',
      label: 'Code',
      options: { filter: true, sort: true },
    },
    {
      name: 'countryText',
      label: 'Pays',
      options: { filter: true, sort: true },
    },
    {
      name: 'tvaText',
      label: 'TVA',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_SETTING_TVA_COUNTRY') ||
      hasRight(authenticationReducer, 'ROLE_SETTING_TVA_COUNTRY_EDIT')
    ) {
      return (
        <Tooltip title={'Modifier'} placement="left">
          <IconButton
            onClick={() => {
              handlerForm.reset();
              setTvaCountry(row.id);
              handlerForm.setValue('code', row.code);
              handlerForm.setValue('country', row.country);
              handlerForm.setValue('tva', row.tva);
              setOpenDialog(true);
            }}
          >
            <Edit style={{ color: '#17a2b8' }} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <IconButton style={{ cursor: 'no-drop' }}>
          <Edit />
        </IconButton>
      );
    }
  };
  React.useEffect(() => {
    handlerForm.start();

    ApiHandler.get({ route: 'api_select_country' }, (response) => {
      setOptionCountry(response.data);
    });
    ApiHandler.get({ route: 'api_select_tva' }, (response) => {
      setOptionTva(response.data);
    });

    setLoading(false);
  }, []);

  // Form
  const [form, setForm] = React.useState({
    code: {
      name: 'code',
      label: 'Code',
      textHelper: 'Saisissez le code de la TVA-Pays.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    country: {
      name: 'country',
      label: 'Pays',
      textHelper: 'Selectionnez un pays.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    tva: {
      name: 'tva',
      label: 'TVA',
      textHelper: 'Selectionnez une TVA.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const handlerForm = initFormHandler(form, setForm);
  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      if (tvaCountry) {
        ApiHandler.post(
          {
            route: 'api_setting_tva_country_edit',
            data: handlerForm.getData(),
            params: { id: tvaCountry },
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_tva_country_list'));
              setTvaCountry(null);
              dispatch(
                SnackbarOpen({
                  text: 'TVA du pays modifié avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_tva_country');
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      } else {
        ApiHandler.post(
          {
            route: 'api_setting_tva_country_add',
            data: handlerForm.getData(),
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_tva_country_list'));
              dispatch(
                SnackbarOpen({
                  text: 'TVA du pays créé avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_tva_country');
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      }
    }
  };

  return (
    <ContentViewComponent loading={loading} breadcrumbs={{ title: 'Liste des tva par pays', context: 'Paramétrage' }}>
      <TableComponent
        id={'api_setting_tva_country_list'}
        title={'TVA par pays'}
        columns={columns}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SETTING_TVA_COUNTRY') ||
          hasRight(authenticationReducer, 'ROLE_SETTING_TVA_COUNTRY_ADD')
            ? {
                label: 'Ajouter une TVA',
                onClick: () => {
                  setTvaCountry(null);
                  handlerForm.reset();
                  setOpenDialog(true);
                },
              }
            : false
        }
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_setting_tva_country_list' }, (response) => {
            const data = response.data;
            for (const index in data) {
              data[index].action = getAction(data[index]);
            }
            resolve(data);
          });
        }}
      />

      <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ fontSize: 15 }}>{tvaCountry ? 'Modifier une TVA' : 'Ajouter une TVA'}</DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <SelectComponent id={'country'} options={optionCountry} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectComponent id={'tva'} options={optionTva} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextFieldComponent id={'code'} handler={handlerForm} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setOpenDialog(false)}
            disabled={isLoadingForm}
          />
          <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm} />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

export default SettingTvaCountryList;
