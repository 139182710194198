import { Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';

type ShopCloseData = {
  id: string;
  env500: number;
  env200: number;
  env100: number;
  env50: number;
  env20: number;
  env10: number;
  env5: number;
  b500: number;
  b200: number;
  b100: number;
  b50: number;
  b20: number;
  b10: number;
  b5: number;
  p2: number;
  p1: number;
  p050: number;
  p020: number;
  p010: number;
  p005: number;
  p002: number;
  p001: number;
  totalCBreceived: number;
  comment: string;
};

type RecapData = {
  shop: string;
  dateCloture: string;
  user: string;
  totalOpening: number;
  totalCashDay: number;
  totalCBDay: number;
  totalAvoirDay: number;
  totalCreditToday: number;
  ordersDay: Array<{
    uuid: string;
    number: string;
    shopCard: number;
    shopCash: number;
    cashBack: number;
    shopAvoir: number;
  }>;
  ordersCreditDay: Array<{ uuid: string; orderNumber: string; priceTTC: number }>;
};

const ClosingShopEdit: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const classes = useStyles();

  // Global
  const [shopClose, setShopClose] = useState<ShopCloseData>();
  const [recap, setRecap] = useState<RecapData>();
  const [totalCashDay, setTotalCashDay] = useState<number>(0);
  const [caisseEspece, setCaisseEspece] = useState<number>(0);
  const [ecartEspece, setEcartEspece] = useState<number>(0);
  const [ecartCb, setEcartCb] = useState<number>(0);
  const [totalCBDay, setTotalCBDay] = useState<number>(0);
  const [totalRemise, setTotalRemise] = useState<number>(0);

  useEffect(() => {
    if (params.id !== 'add') {
      ApiHandler.get(
        {
          route: 'api_shop_management_shop_closing_get',
          params: { id: params.id as string },
        },
        (response) => {
          if (response.status === 200) {
            const data = response.data;
            setShopClose(data);

            setTimeout(() => {
              ApiHandler.post(
                {
                  route: 'api_shop_management_shop_closing_get_recap_day',
                  params: { closeId: response.data.id },
                },
                (response2) => {
                  const recapData = response2.data;
                  setRecap(recapData);

                  const totalCaisseEspece =
                    500 * data.env500 +
                    200 * data.env200 +
                    100 * data.env100 +
                    50 * data.env50 +
                    20 * data.env20 +
                    10 * data.env10 +
                    5 * data.env5;

                  const totalCash =
                    500 * data.b500 +
                    200 * data.b200 +
                    100 * data.b100 +
                    50 * data.b50 +
                    20 * data.b20 +
                    10 * data.b10 +
                    5 * data.b5 +
                    2 * data.p2 +
                    1 * data.p1 +
                    0.5 * data.p050 +
                    0.2 * data.p020 +
                    0.1 * data.p010 +
                    0.05 * data.p005 +
                    0.02 * data.p002 +
                    0.01 * data.p001;

                  setCaisseEspece(totalCaisseEspece);
                  setTotalCashDay(totalCash);

                  const totalCBDayFromReporting = parseFloat(data.totalCBreceived);
                  setTotalCBDay(totalCBDayFromReporting);
                  setEcartEspece(totalCash + totalCaisseEspece - (recapData.totalOpening + recapData.totalCashDay));
                  setEcartCb(totalCBDayFromReporting - parseFloat(recapData.totalCBDay));
                  setTotalRemise(recapData.totalAvoirDay + totalCBDayFromReporting + totalCaisseEspece);

                  dispatch(TableReload('recapClotureTable'));
                },
              );
            }, 500);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  }, [location]);

  if (!recap || !shopClose) {
    return null;
  }

  return (
    <ContentViewShopComponent>
      <br />
      <TitleComponent title="Récapitulatif" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          Boutique : {recap.shop}
        </Grid>
        <Grid item xs={12} sm={3}>
          Date de clôture : {recap.dateCloture}
        </Grid>
        <Grid item xs={12} sm={3}>
          Utilisateur : {recap.user}
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper as any} id="recapClotureTable">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: 'lightgrey' }}>
            <TableRow>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }}></TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Fonds de caisse à l'ouverture
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Chiffres d'affaires
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Ventilation encaissements
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Avoirs émis
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Remise au siège
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Fond de caisse après clôture
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Ecart
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ fontSize: 11 }} component="th">
                Carte bancaire
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center"></TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalCBDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalCBDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center"></TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {totalCBDay} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center"></TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 14 }} component="th" align="center">
                {ecartCb.toFixed(2)} €
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ fontSize: 11 }} component="th">
                Espèce
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalOpening.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalCashDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalCashDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center"></TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {caisseEspece} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {totalCashDay} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 14 }} component="th" align="center">
                {ecartEspece.toFixed(2)} €
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ fontSize: 11 }} component="th">
                Avoirs
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                -
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {(recap.totalAvoirDay - recap.totalCreditToday).toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalAvoirDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                - {recap.totalCreditToday.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                {recap.totalAvoirDay.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontSize: 11 }} component="th" align="center">
                -
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 14 }} component="th" align="center">
                0
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter sx={{ backgroundColor: 'lightgrey' }}>
            <TableRow>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                Total
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                {recap.totalOpening.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                {(recap.totalCashDay + recap.totalCBDay + (recap.totalAvoirDay - recap.totalCreditToday)).toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} component="th" align="center">
                {(recap.totalCBDay + recap.totalCashDay + recap.totalAvoirDay).toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} component="th" align="center">
                - {recap.totalCreditToday.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                {totalRemise.toFixed(2)} €
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                -
              </TableCell>
              <TableCell style={{ fontWeight: 900, fontSize: 14 }} align="center">
                {(ecartCb + ecartEspece).toFixed(2)} €
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <br />
      <hr />
      <br />
      <ShadowBoxComponent className={classes.shadowBox}>
        <TitleComponent title="Espèces remis au siège" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            500€ : {500 * shopClose.env500} €
          </Grid>
          <Grid item xs={12} sm={3}>
            200€ : {200 * shopClose.env200} €
          </Grid>
          <Grid item xs={12} sm={3}>
            100€ : {100 * shopClose.env100} €
          </Grid>
          <Grid item xs={12} sm={3}>
            50€ : {50 * shopClose.env50} €
          </Grid>
          <Grid item xs={12} sm={3}>
            20€ : {20 * shopClose.env20} €
          </Grid>
          <Grid item xs={12} sm={3}>
            10€ : {10 * shopClose.env10} €
          </Grid>
          <Grid item xs={12} sm={3}>
            5€ : {5 * shopClose.env5} €
          </Grid>
        </Grid>
        <br />
        <br />
        <TitleComponent title="Fonds de caisse à la cloture" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            500€ : {500 * shopClose.b500} €
          </Grid>
          <Grid item xs={12} sm={3}>
            200€ : {200 * shopClose.b200} €
          </Grid>
          <Grid item xs={12} sm={3}>
            100€ : {100 * shopClose.b100} €
          </Grid>
          <Grid item xs={12} sm={3}>
            50€ : {50 * shopClose.b50} €
          </Grid>
          <Grid item xs={12} sm={3}>
            20€ : {20 * shopClose.b20} €
          </Grid>
          <Grid item xs={12} sm={3}>
            10€ : {10 * shopClose.b10} €
          </Grid>
          <Grid item xs={12} sm={3}>
            5€ : {5 * shopClose.b5} €
          </Grid>
          <Grid item xs={12} sm={3}>
            2€ : {2 * shopClose.p2} €
          </Grid>
          <Grid item xs={12} sm={3}>
            1€ : {shopClose.p1} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.5€ : {0.5 * shopClose.p050} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.2€ : {0.2 * shopClose.p020} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.1€ : {0.1 * shopClose.p010} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.05€ : {0.05 * shopClose.p005} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.02€ : {0.02 * shopClose.p002} €
          </Grid>
          <Grid item xs={12} sm={3}>
            0.01€ : {0.01 * shopClose.p001} €
          </Grid>
        </Grid>
        <br />
        <hr />
        <h4>Liste des paiements CB</h4>
        <TableContainer component={Paper as any} id="ordersList" sx={{ backgroundColor: 'lightgrey' }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Num
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Montant
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  {' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recap.ordersDay.length > 0 ? (
                recap.ordersDay
                  .filter((row) => row.shopCard > 0)
                  .map((row) => (
                    <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                        {row.number}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                        {row.shopCard.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center"></TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  Total
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  {recap.totalCBDay.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center"></TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  Remis au siège
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  {shopClose.totalCBreceived.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <h4>Liste des paiements Espèces</h4>
        <TableContainer component={Paper as any} id="ordersList" sx={{ backgroundColor: 'lightgrey' }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Num
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Montant
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recap.ordersDay.length > 0 ? (
                recap.ordersDay.map(
                  (row) =>
                    row.shopCash > 0 && (
                      <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{ fontSize: 11 }} scope="row" align="center">
                          {row.number}
                        </TableCell>
                        <TableCell style={{ fontSize: 11 }} scope="row" align="center">
                          {(row.shopCash - row.cashBack).toLocaleString('fr-FR', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          €
                        </TableCell>
                      </TableRow>
                    ),
                )
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  Total
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  {recap.totalCashDay.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <h4>Liste des paiements Avoirs</h4>
        <TableContainer component={Paper} id="ordersList" sx={{ backgroundColor: 'lightgrey' }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Num
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Montant
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recap.ordersDay.length > 0 ? (
                recap.ordersDay
                  .filter((row) => row.shopAvoir > 0)
                  .map(
                    (row) =>
                      row.shopAvoir > 0 && (
                        <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                            {row.number}
                          </TableCell>
                          <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                            {row.shopAvoir.toLocaleString('fr-FR', {
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </TableCell>
                        </TableRow>
                      ),
                  )
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  Total
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  {recap.totalAvoirDay.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <h4>Liste des avoirs</h4>
        <TableContainer component={Paper as any} id="ordersList" sx={{ backgroundColor: 'lightgrey' }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Num
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="center">
                  Montant
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recap.ordersCreditDay.length > 0 ? (
                recap.ordersCreditDay.map((row) => {
                  return (
                    <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                        {row.orderNumber}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                        {row.priceTTC.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  Total
                </TableCell>
                <TableCell style={{ fontSize: 11 }} component="th" scope="row" align="center">
                  {recap.totalCreditToday.toFixed(2)} €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            Commentaire :{shopClose.comment}
          </Grid>
        </Grid>
      </ShadowBoxComponent>
    </ContentViewShopComponent>
  );
};

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
  owner: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
    position: 'absolute',
    top: 7,
    right: 14,
  },
});

export default ClosingShopEdit;
