type ScreenAction = {
  type: 'ScreenChange' | 'ScreenFull';
  data: any;
};

type ScreenState = {
  [key: string]: any;
  screen?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL';
};

const ScreenReducer = (state: ScreenState = {}, action: ScreenAction): ScreenState => {
  switch (action.type) {
    case 'ScreenChange':
      return action.data;
    case 'ScreenFull':
      return { ...state, full: action.data.full };
    default:
      return state;
  }
};

export default ScreenReducer;
