import { makeStyles } from '@mui/styles';
import { PropsWithChildren } from 'react';
import React, { CSSProperties } from 'react';

type Props = {
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
};

const ShadowBoxComponent: React.FC<PropsWithChildren<Props>> = ({ disabled, className, style, children }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.content} ${disabled ? classes.disabled : ''} ${className ?? ''}`} style={style}>
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    margin: 0,
    padding: '6px 12px',
    boxSizing: 'border-box',
    position: 'relative',
    color: '#5E6E82',
    borderRadius: 5,
  },
  disabled: {
    boxShadow: 'inset 0px 0px 30px 0px rgba(0,0,0,0.25);',
  },
});

export default ShadowBoxComponent;
