import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateType } from '#app/Store';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import ShopCashRegisterForm from './ShopCashRegisterComponent/ShopCashRegisterForm';

type Shop = {
  isOpen: number;
};

const ShopCashRegister: React.FC = () => {
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const [shop, setShop] = useState<Shop>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticationReducer.shopSelected) {
      ApiHandler.get(
        {
          route: 'api_shop_sales_shop_info',
          params: { id: authenticationReducer.shopSelected },
        },
        (response) => {
          if (response.status === 200) {
            setShop(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  }, [authenticationReducer.shopSelected]);

  return (
    <ContentViewShopComponent>
      {shop && authenticationReducer.shopSelected && (
        <>
          <p>Boutique : {authenticationReducer.shopNameSelected}</p>
          <p>
            Responsable Régional :{' '}
            {authenticationReducer.rightShops?.region.includes(authenticationReducer.shopSelected) ? 'Oui' : 'Non'}
          </p>
          <p>
            Responsable National :{' '}
            {authenticationReducer.rightShops?.national.includes(authenticationReducer.shopSelected) ? 'Oui' : 'Non'}
          </p>
          <p>
            Responsable Logistique :{' '}
            {authenticationReducer.rightShops?.logistique.includes(authenticationReducer.shopSelected) ? 'Oui' : 'Non'}
          </p>
          <p>
            Responsable Vendeur :{' '}
            {authenticationReducer.rightShops?.seller.includes(authenticationReducer.shopSelected) ? 'Oui' : 'Non'}
          </p>

          {shop.isOpen ? (
            <div>Votre caisse est déjà ouverte.</div>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <ShopCashRegisterForm shopId={authenticationReducer.shopSelected} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </ContentViewShopComponent>
  );
};

export default ShopCashRegister;
