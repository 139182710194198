import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import AdministratorUserForm from './AdministratorUserComponents/AdministratorUserForm';
import AdministratorUserRoleList from './AdministratorUserComponents/AdministratorUserRoleList';

function AdministratorUser() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  // Global
  const [user, setUser] = React.useState(null);
  const [isLoadingContent, setIsLoadingContent] = React.useState(true);

  React.useEffect(() => {
    if (params.id === 'add') {
      setIsLoadingContent(false);
    } else {
      ApiHandler.get(
        {
          route: 'api_administrator_user_get',
          params: { id: params.id },
        },
        (response) => {
          if (response.status === 200) {
            setUser(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Ajouter un utilisateur' : 'Éditer un utilisateur',
        context: 'Administrateur',
        links: [{ path: getRoutePathname('administrator_user_list'), label: 'Liste des utilisateurs' }],
      }}
    >
      <AdministratorUserForm user={user} />
      <br />
      {user && <AdministratorUserRoleList user={user} />}
    </ContentViewComponent>
  );
}

export default AdministratorUser;
