import { Article, Face, FaceRetouchingOff } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { listingStyles } from '../../../Style/useStyles';

function SaleOrderClientList() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [optionsChannel, setOptionsChannel] = useState([]);
  const [optionsOrderClientState, setOptionsOrderClientState] = useState([]);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
  const classes = listingStyles();

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'numberSylius',
      label: 'Num sylius',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'carrier',
      label: 'Transporteur',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'Etat',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date de commande',
      options: { filter: true, sort: true },
    },
    {
      name: 'isGuest',
      label: 'Guest',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => (
    <Tooltip title={'Visualiser'} placement="left">
      <Link to={getRoutePathname('sale_order_client', { uuid: row.uuid })}>
        <IconButton>
          <Article style={{ color: '#17a2b8' }} />
        </IconButton>
      </Link>
    </Tooltip>
  );

  // FormSearch
  const [formSearch, setFormSearch] = useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par référence.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].number
          ? cacheTableServer['api_sale_order_client_list'].number
          : '',
    },
    numberCarrier: {
      name: 'numberCarrier',
      label: 'Num suivi colis',
      textHelper: 'Rechercher par suivi.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].numberCarrier
          ? cacheTableServer['api_sale_order_client_list'].numberCarrier
          : '',
    },
    clientEmail: {
      name: 'clientEmail',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].clientEmail
          ? cacheTableServer['api_sale_order_client_list'].clientEmail
          : '',
    },
    clientName: {
      name: 'clientName',
      label: 'Client',
      textHelper: 'Rechercher par client.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].clientName
          ? cacheTableServer['api_sale_order_client_list'].clientName
          : '',
    },
    numberSylius: {
      name: 'numberSylius',
      label: 'Id sylius',
      textHelper: 'Rechercher par id sylius.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].numberSylius
          ? cacheTableServer['api_sale_order_client_list'].numberSylius
          : '',
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] && cacheTableServer['api_sale_order_client_list'].channel
          ? cacheTableServer['api_sale_order_client_list'].channel
          : '',
    },
    orderClientState: {
      name: 'orderClientState',
      label: 'État',
      textHelper: 'Rechercher par état.',
      type: 'array',
      defaultValue:
        cacheTableServer['api_sale_order_client_list'] &&
        cacheTableServer['api_sale_order_client_list'].orderClientState
          ? cacheTableServer['api_sale_order_client_list'].orderClientState
          : 0,
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel', data: { limited: true } }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsChannel(data);
    });
    ApiHandler.get({ route: 'api_select_order_client_state' }, (response) => {
      const data = [...response.data];
      setOptionsOrderClientState(data);
    });

    setLoadingContent(false);
  }, []);

  const submitSearch = () => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    const datas = handlerFormSearch.getData();
    const filters = {
      number: formSearch.number.value ? formSearch.number.value : '',
      numberCarrier: formSearch.numberCarrier.value ? formSearch.numberCarrier.value : '',
      clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
      clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
      numberSylius: formSearch.numberSylius.value ? formSearch.numberSylius.value : '',
      channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
      orderClientState: datas.orderClientState === 0 || !datas.orderClientState ? '' : datas.orderClientState.join('|'),
    };

    ApiHandler.get(
      {
        route: 'api_sale_order_client_list',
        data: filters,
      },
      () => {
        setDisabledStatusButtonSearch(false);
        setLoadingContent(false);

        dispatch(TableReload('api_sale_order_client_list'));
      },
    );
  };

  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{ title: 'Liste des commandes client', context: 'Vente' }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <TextFieldComponent id={'number'} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <TextFieldComponent id={'numberCarrier'} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <TextFieldComponent id={'clientEmail'} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                <TextFieldComponent id={'clientName'} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                <TextFieldComponent id={'numberSylius'} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <SelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <MultipleSelectComponent
                  id={'orderClientState'}
                  options={optionsOrderClientState}
                  handler={handlerFormSearch}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label={'Rechercher'}
                  onClick={() => submitSearch()}
                  loading={loadingContent}
                  disabled={disabledStatusButtonSearch}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_sale_order_client_list'}
        title={'Commandes client'}
        columns={columns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            numberCarrier: formSearch.numberCarrier.value ? formSearch.numberCarrier.value : '',
            clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
            clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
            numberSylius: formSearch.numberSylius.value ? formSearch.numberSylius.value : '',
            channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
            orderClientState:
              datas.orderClientState === 0 || !datas.orderClientState ? '' : datas.orderClientState.join('|'),
          };

          ApiHandler.get(
            {
              route: 'api_sale_order_client_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].confirmedAt = data[index].confirmedAt ? moment(data[index].confirmedAt).format('lll') : '-';
                data[index].clientEmail = data[index].clientEmail ?? '-';
                data[index].clientName = data[index].clientName ?? '-';
                data[index].numberSylius = data[index].numberSylius ?? '-';
                data[index].channel = data[index].channel ?? '-';
                data[index].carrier = data[index].carrier ?? '-';
                data[index].orderClientState = data[index].orderClientState ?? '-';
                data[index].isGuest = data[index].isGuest ? (
                  <Tooltip title={'Guest'} placement="left">
                    <FaceRetouchingOff style={{ color: '#ffc107' }} />
                  </Tooltip>
                ) : (
                  <Face style={{ color: '#28a745' }} />
                );
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default SaleOrderClientList;
