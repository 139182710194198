import { Article, Face, FaceRetouchingOff, LocalShipping, Visibility } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Order } from '#app/typings/order';

import ButtonComponent from '../../../../../Component/ButtonComponent';
import ShadowBoxComponent from '../../../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../../../Component/TitleComponent';
import { getRoutePathname } from '../../../../../Handler/RouteHandler';
import { formatNumberToEurCurrency } from '../../../../../Util/NumberTool';
import CancelOrderModal from '../../../../../View/Sale/OrderClient/SaleOrderClient/components/CancelOrderModal';

type Props = {
  order: Order;
  reload: () => void;
};

const Information: React.FC<Props> = ({ order, reload }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const classes = useStyles();

  const getLinkCarrier = (id: number, number: string, zipCode: string): string => {
    let url: URL;

    switch (id) {
      case 1:
      case 6:
      case 15:
        url = new URL('https://www.mondialrelay.fr/suivi-de-colis');
        url.searchParams.set('numeroExpedition', number);
        url.searchParams.set('CodePostal', zipCode);
        url.searchParams.set('language', 'FR');
        return url.toString();
      case 2:
      case 4:
      case 9:
      case 16:
        url = new URL('https://www.chronopost.fr/expedier/inputLTNumbersNoJahia.do');
        url.searchParams.set('listeNumeros', number);
        return url.toString();
      case 3:
      case 5:
      case 8:
      case 10:
        url = new URL('https://colisprive.com/moncolis/pages/detailColis.aspx');
        url.searchParams.set('numColis', number);
        url.searchParams.set('lang', 'FR');
        return url.toString();
      default:
        url = new URL('https://www.dpdgroup.com/fr/mydpd/my-parcels/incoming');
        url.searchParams.set('parcelNumber', number);
        return url.toString();
    }
  };

  return (
    <>
      <Grid item md={12} lg={8} xl={9}>
        <ShadowBoxComponent className={classes.box}>
          <span className={classes.state}>{order.state}</span>
          <TitleComponent
            title={
              <>
                {order.isGuest ? (
                  <Tooltip title="Guest" placement="left">
                    <FaceRetouchingOff className={classes.iconGuest} />
                  </Tooltip>
                ) : (
                  <Face className={classes.iconConnect} />
                )}{' '}
                Information Commande client
                {order?.orderClientLitiges?.some((dispute) => !dispute.dealAt) && (
                  <span className={classes.litige}>En litige</span>
                )}
              </>
            }
          />
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={4} xl={6}>
              <div>
                <b>Référence</b> : {order.number ?? '-'}
              </div>
              <div>
                <b>Nom du client</b> : {order.clientName}
                {order.clientUuid && (
                  <Tooltip title="Visualisation la fiche client" placement="right">
                    <Link to={getRoutePathname('sale_client', { uuid: order.clientUuid })}>
                      <IconButton>
                        <Article className={classes.icon} style={{ color: '#17a2b8' }} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
              <div>
                <b>Email du client</b> : {order.clientEmail ?? '-'}
              </div>
              <div>
                <b>Canal</b> : {order.channel ?? '-'}
              </div>
              <div>
                <b>Transporteur</b> : {order.carrier.name ?? '-'}
                {order.history.numberCarrier && order.carrier.id && order.addressShipping.zipCode && (
                  <Tooltip title="Suivi de colis" placement="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getLinkCarrier(
                        order.carrier.id,
                        order.history.numberCarrier,
                        order.addressShipping.zipCode,
                      )}
                    >
                      <IconButton>
                        <LocalShipping className={classes.icon} style={{ color: '#9c27b0' }} />
                      </IconButton>
                    </a>
                  </Tooltip>
                )}
              </div>
              <div>
                <b>Paiement</b> : {order.payment.method ?? '-'}
              </div>
              <div>
                <b>Numero Sylius</b> : {order.numberSylius ?? '-'}
                {order.numberSylius && (
                  <a
                    href={`https://www.terravita.fr/terradmin/orders/${order.numberSylius}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton>
                      <Visibility className={classes.icon} style={{ color: '#9c27b0' }} />
                    </IconButton>
                  </a>
                )}
              </div>
              <br />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
              <b>Adresse de facturation</b>
              <div>
                {order.addressBilling.firstname} {order.addressBilling.lastname}
              </div>
              <div>{order.addressBilling.address1}</div>
              <div>{order.addressBilling.address2}</div>
              <div>{order.addressBilling.city}</div>
              <div>{order.addressBilling.zipCode}</div>
              <div>{order.addressBilling.phone}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
              <b>Adresse d'expédition</b>
              <div>
                {order.addressShipping.firstname} {order.addressShipping.lastname}
              </div>
              <div>{order.addressShipping.company}</div>
              <div>{order.addressShipping.address1}</div>
              <div>{order.addressShipping.address2}</div>
              <div>{order.addressShipping.city}</div>
              <div>{order.addressShipping.zipCode}</div>
              <div>{order.addressShipping.phone}</div>
            </Grid>
            {order.promotions.length > 0 && (
              <Grid item xs={12} style={{ fontSize: 12 }}>
                <strong>Promotions :</strong>
                {order.promotions.map((promotion) => (
                  <p key={promotion.coupon}>
                    <strong>Coupon :</strong> {promotion.coupon}
                    <br />
                    <strong>Label :</strong> {promotion.label}
                  </p>
                ))}
              </Grid>
            )}
            <Grid item sm={12} lg={4} xl={3}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Prix d'origine</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceOriginalHT ? `${formatNumberToEurCurrency(order.priceOriginalHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceOriginalTTC ? `${formatNumberToEurCurrency(order.priceOriginalTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Transport Prix d'origine</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.carrier.priceOriginalHT
                        ? `${formatNumberToEurCurrency(order.carrier.priceOriginalHT)} HT`
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.carrier.priceOriginalTTC
                        ? `${formatNumberToEurCurrency(order.carrier.priceOriginalTTC)} TTC`
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Paiement Prix</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.payment.priceHT ? `${formatNumberToEurCurrency(order.payment.priceHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.payment.priceTTC ? `${formatNumberToEurCurrency(order.payment.priceTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Prix final</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceHT ? `${formatNumberToEurCurrency(order.priceHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceTTC ? `${formatNumberToEurCurrency(order.priceTTC)} TTC` : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Remise</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceDiscountHT ? `${formatNumberToEurCurrency(order.priceDiscountHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceDiscountTTC ? `${formatNumberToEurCurrency(order.priceDiscountTTC)} TTC` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Transport Remise</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.carrier.priceDiscountHT
                        ? `${formatNumberToEurCurrency(order.carrier.priceDiscountHT)} HT`
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.carrier.priceDiscountTTC
                        ? `${formatNumberToEurCurrency(order.carrier.priceDiscountTTC)} TTC`
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Prix facture</b>
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceInvoiceHT ? `${formatNumberToEurCurrency(order.priceInvoiceHT)} HT` : '-'}
                    </td>
                    <td style={{ textAlign: 'right', padding: '0 5px' }}>
                      {order.priceInvoiceTTC ? `${formatNumberToEurCurrency(order.priceInvoiceTTC)} TTC` : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <br />
          {order.stateId === 2 && (
            <ButtonComponent
              color="#982525"
              label="Annuler la commande"
              className={classes.button}
              onClick={() => setOpenModal(true)}
            />
          )}
        </ShadowBoxComponent>
      </Grid>
      {openModal && <CancelOrderModal uuid={order.uuid} onClose={() => setOpenModal(false)} reload={reload} />}
    </>
  );
};

const useStyles = makeStyles({
  box: {
    height: '100%',
    fontSize: 12,
  },
  iconGuest: {
    color: '#ffc107',
    fontSize: 22,
    verticalAlign: 'middle',
  },
  iconConnect: {
    color: '#28a745',
    fontSize: 22,
    verticalAlign: 'middle',
  },
  icon: {
    fontSize: '17px !important',
    position: 'absolute',
    right: -5,
    top: -2,
  },
  state: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
    position: 'absolute',
    top: 8,
    right: 13,
  },
  litige: {
    backgroundColor: '#F17105',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default Information;
