import { Article } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function SaleClientList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [loadingContent, setLoadingContent] = React.useState(true);
  const [optionsChannelClient, setOptionsChannelClient] = React.useState([]);
  const [optionsShop, setOptionsShop] = React.useState([]);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'email',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'firstname',
      label: 'Prénom',
      options: { filter: true, sort: true },
    },
    {
      name: 'lastname',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'zipCode',
      label: 'Code postal',
      options: { filter: true, sort: true },
    },
    {
      name: 'channelClient',
      label: 'Canal client',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par références.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].number
          ? cacheTableServer['api_sale_client_list'].number
          : '',
    },
    email: {
      name: 'email',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].email
          ? cacheTableServer['api_sale_client_list'].email
          : '',
    },
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      textHelper: 'Rechercher par prénom.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].firstname
          ? cacheTableServer['api_sale_client_list'].firstname
          : '',
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      textHelper: 'Rechercher par nom.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].lastname
          ? cacheTableServer['api_sale_client_list'].lastname
          : '',
    },
    zipCode: {
      name: 'zipCode',
      label: 'Code postal',
      textHelper: 'Rechercher par code postal.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].zipCode
          ? cacheTableServer['api_sale_client_list'].zipCode
          : '',
    },
    channelClient: {
      name: 'channelClient',
      label: 'Canal client',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].channelClient
          ? cacheTableServer['api_sale_client_list'].channelClient
          : 0,
    },
    fromShop: {
      name: 'fromShop',
      label: 'Boutique',
      textHelper: 'Rechercher par boutique.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_sale_client_list'] && cacheTableServer['api_sale_client_list'].fromShop
          ? cacheTableServer['api_sale_client_list'].fromShop
          : 0,
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage.api_sale_client_list = 0;
    cacheTableServer.api_sale_client_list.page = 0;
    cacheTableServer.api_sale_client_list[index] = value;
    dispatch(TableReload('api_sale_client_list'));
  };
  const getAction = (row) => (
    <Tooltip title={'Visualiser'} placement="left">
      <Link to={getRoutePathname('sale_client', { uuid: row.uuid })}>
        <IconButton>
          <Article style={{ color: '#17a2b8' }} />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const downloadAction = () => {
    const datas = handlerFormSearch.getData();
    const params = {
      number: formSearch.number.value ? formSearch.number.value : '',
      email: formSearch.email.value ? formSearch.email.value : '',
      firstname: formSearch.firstname.value ? formSearch.firstname.value : '',
      lastname: formSearch.lastname.value ? formSearch.lastname.value : '',
      zipCode: formSearch.zipCode.value ? formSearch.zipCode.value : '',
      channelClient: datas.channelClient === 0 || !datas.channelClient ? '' : datas.channelClient,
      fromShop: datas.fromShop === 0 || !datas.fromShop ? '' : datas.fromShop,
    };
    const route = ApiHandler.route({ route: 'api_sale_client_download', data: params });

    window.open(route, '_blank');
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel_client' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsChannelClient(data);
    });

    ApiHandler.get({ route: 'api_select_shop' }, (response) => {
      const data = [{ value: 0, label: '-' }, { value: 9999, label: 'Tous' }, ...response.data];
      setOptionsShop(data);
    });

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent loading={loadingContent} breadcrumbs={{ title: 'Liste des clients', context: 'Vente' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'number'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('number', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'email'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('email', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'firstname'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('firstname', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'lastname'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('lastname', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'zipCode'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('zipCode', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'channelClient'}
                  options={optionsChannelClient}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('channelClient', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'fromShop'}
                  options={optionsShop}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromShop', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_sale_client_list'}
        title={'Clients'}
        columns={columns}
        search={false}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SALE_CLIENT_EXPORT')
            ? {
                label: 'Télécharger un CSV',
                onClick: () => downloadAction(),
              }
            : false
        }
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            email: formSearch.email.value ? formSearch.email.value : '',
            firstname: formSearch.firstname.value ? formSearch.firstname.value : '',
            lastname: formSearch.lastname.value ? formSearch.lastname.value : '',
            zipCode: formSearch.zipCode.value ? formSearch.zipCode.value : '',
            channelClient: datas.channelClient === 0 || !datas.channelClient ? '' : datas.channelClient,
            fromShop: datas.fromShop === 0 || !datas.fromShop ? '' : datas.fromShop,
          };

          ApiHandler.get(
            {
              route: 'api_sale_client_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;

              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].channelClient = data[index].channelClient ? data[index].channelClient : '-';
                data[index].firstname = data[index].firstname ? data[index].firstname : '-';
                data[index].lastname = data[index].lastname ? data[index].lastname : '-';
                data[index].zipCode = data[index].zipCode ? data[index].zipCode : '-';
                data[index].number = data[index].number ? data[index].number : '-';
                data[index].email = data[index].email ? data[index].email : '-';
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default SaleClientList;
