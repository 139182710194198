import { Box } from '@mui/material';
import { DataGrid, GridColDef, gridExpandedSortedRowIdsSelector } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { GridCsvGetRowsToExportParams } from '@mui/x-data-grid/models/gridExport';
import React, { useEffect, useState } from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import ApiHandler from '../../../Handler/ApiHandler';

type ProductRow = {
  id: number;
  sku: string;
  productName: string;
  brand: string;
  packSize: string;
  flavor: string;
  type: string;
  lifeCycle: string;
  suppliers: string;
  warehouseStock: number;
  storeStock: number;
  amazonStock: number;
  incomingOrders: Record<number, number>;
  threeMonthAvgSales: number;
  expectedQuantity: number;
  weeklySales: number;
  currentCoverageWeeks: number;
  projectedStock12Weeks: number;
  projectedStock16Weeks: number;
  projectedCoverage12Weeks: number;
  projectedCoverage16Weeks: number;
  lucySales: Record<number, number>;
  amazonSales: Record<number, number>;
};

const SalesStatisticsList: React.FC = () => {
  const [rows, setRows] = useState<ProductRow[]>([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const rowsWithoutSubTotalToExport = ({ apiRef }: GridCsvGetRowsToExportParams) => {
    const rows = gridExpandedSortedRowIdsSelector(apiRef);
    return rows.filter((rowId) => {
      const row = apiRef.current.getRow(rowId);

      return !row.isSubtotalRow;
    });
  };
  useEffect(() => {
    setLoadingContent(true);
    dispatch(LinearProgressMainLayoutActivate());

    // Fetch data from your API
    ApiHandler.get({ route: 'api_statistic_sale_monthly' }, (response: any) => {
      const formattedData = response.map((item: any) => ({
        id: item.byProduct.id,
        sku: item.byProduct.sku,
        productName: item.byProduct.productName,
        brand: item.byProduct.brand,
        packSize: item.byProduct.packSize,
        flavor: item.byProduct.flavor,
        type: item.byProduct.type,
        lifeCycle: item.byProduct.lifeCycle,
        suppliers: item.byProduct.suppliers,
        warehouseStock: item.warehouseStock,
        storeStock: item.storeStock,
        amazonStock: item.amazonStock,
        incomingOrders: item.incomingOrders,
        threeMonthAvgSales: item.threeMonthAvgSales,
        expectedQuantity: item.expectedQuantity,
        weeklySales: item.weeklySales,
        currentCoverageWeeks: item.currentCoverageWeeks,
        projectedStock12Weeks: item.projectedStock12Weeks,
        projectedStock16Weeks: item.projectedStock16Weeks,
        projectedCoverage12Weeks: item.projectedCoverage12Weeks,
        projectedCoverage16Weeks: item.projectedCoverage16Weeks,
        lucySales: item.lucySales, // Ventes Lucy mensuelles
        amazonSales: item.amazonSales, // Ventes Amazon mensuelles
      }));

      setRows(formattedData);
      setLoadingContent(false);
      dispatch(LinearProgressMainLayoutDeactivate());
    });
  }, []);

  const lucySalesColumns = Array.from({ length: 12 }, (_, i): GridColDef => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculer les mois en partant de 12 mois en arrière jusqu'au mois précédent
    const displayMonthIndex = (currentMonthIndex - 12 + i + 12) % 12;
    const displayYear = currentMonthIndex - 12 + i < 0 ? currentYear - 1 : currentYear;

    // Les données "lucySales" commencent à 1 pour le mois précédent, donc `i + 1`
    const monthNumber = 12 - i; // Inverser les indices pour commencer du mois le plus ancien
    const monthName = monthNames[displayMonthIndex];

    return {
      field: `lucySales.${monthNumber}`,
      headerName: `Ventes (${monthName} ${displayYear})`,
      flex: 1,
      valueGetter: (_, row) => (row.lucySales ? row.lucySales[monthNumber] || 0 : 0),
    };
  });

  const incomingOrdersColumns = Array.from({ length: 13 }, (_, i): GridColDef => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const displayMonthIndex = (currentMonthIndex + i) % 12;
    const displayYear = currentMonthIndex + i >= 12 ? currentYear + 1 : currentYear;

    const monthName = monthNames[displayMonthIndex];

    return {
      field: `incomingOrders.${i}`,
      headerName: `Quantité mois ${monthName} ${displayYear}`,
      flex: 1,
      valueGetter: (_, row) => (row.incomingOrders ? row.incomingOrders[i] || 0 : 0),
    };
  });

  const amazonSalesColumns = Array.from({ length: 3 }, (_, i): GridColDef => {
    const month = 1 + i;
    return {
      field: `amazonSales.${month}`,
      headerName: `Ventes M-${month} Amazon`,
      flex: 1,
      valueGetter: (_, row) => (row.amazonSales ? row.amazonSales[month - 1] || 0 : 0), // Indices de tableau
    };
  });

  // Colonnes de base + colonnes de ventes Lucy et Amazon
  const columns: GridColDef[] = [
    { field: 'productName', headerName: 'Désignation produit', flex: 2 },
    { field: 'sku', headerName: 'SKU', flex: 1 },
    { field: 'brand', headerName: 'Marque', flex: 1 },
    { field: 'packSize', headerName: 'Conditionnement', flex: 1 },
    { field: 'flavor', headerName: 'Parfum', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'lifeCycle', headerName: 'Cycle de vie', flex: 1 },
    { field: 'suppliers', headerName: 'Fournisseur', flex: 1 },
    ...lucySalesColumns, // Colonnes dynamiques pour les ventes Lucy
    { field: 'warehouseStock', headerName: 'Stock Entrepôt', flex: 1 },
    { field: 'storeStock', headerName: 'Stock boutiques', flex: 1 },
    ...amazonSalesColumns, // Colonnes dynamiques pour les ventes Amazon
    { field: 'amazonStock', headerName: 'Stock Amazon', flex: 1 },
    { field: 'threeMonthAvgSales', headerName: 'Moyenne mensuelle des 3 derniers mois', flex: 1 },
    { field: 'expectedQuantity', headerName: 'Quantité retard', flex: 1 },
    ...incomingOrdersColumns, // Colonnes dynamiques pour les commandes fournisseur
    { field: 'weeklySales', headerName: 'Ecoulement /semaine', flex: 1 },
    { field: 'currentCoverageWeeks', headerName: 'Couverture à date (S)', flex: 1 },
    { field: 'projectedStock12Weeks', headerName: 'Stock projeté à 12s', flex: 1 },
    { field: 'projectedStock16Weeks', headerName: 'Stock projeté à 16s', flex: 1 },
    { field: 'projectedCoverage12Weeks', headerName: 'Couverture projetée dans 12sem (S)', flex: 1 },
    { field: 'projectedCoverage16Weeks', headerName: 'Couverture projetée dans 16sem (S)', flex: 1 },
  ];

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Récap stat ventes mensuelles', context: 'Statistiques' }}>
      <Box sx={{ height: '100vm', width: '500%', overflow: 'auto' }}>
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          loading={loadingContent}
          rows={rows}
          columns={columns}
          rowHeight={25}
          disableColumnMenu
          disableRowSelectionOnClick
          slots={{
            toolbar: () => (
              <CustomToolbar fileName="stats_ventes_mensuelles.csv" getRowsToExport={rowsWithoutSubTotalToExport} />
            ),
          }}
          sx={{
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'auto',
            },
          }}
        />
      </Box>
    </ContentViewComponent>
  );
};

export default SalesStatisticsList;
