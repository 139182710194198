export const cacheTableData: Record<string, Array<object | number[] | string[]>> = {};

export const cacheTableServer: Record<
  string,
  {
    rowsPerPage: number;
    page: number;
    searchText: string | null;
    sortOrder?: { name: string; direction: 'asc' | 'desc' };
    startDate: string;
    endDate: string;
    purveyor: string;
  }
> = {};

export const cacheTableFilter: Record<string, string[][]> = {};
export const cacheTablePage: Record<string, number> = {};
export const cacheTableOrderBy: Record<string, { name: string; direction: 'asc' | 'desc' }> = {};
export const cacheTableRowsPerPage: Record<string, number> = {};
