type AuthenticationAction = {
  type: 'AuthenticationLogin' | 'AuthenticationLogout';
  data?: Record<string, any>;
};

type AuthenticationState = {
  email?: string;
  roles: string[];
  defaultRoute?: string;
  gender?: string;
  firstname?: string;
  lastname?: string;
  shopSelected?: string;
  shopNameSelected?: string;
  rightShops?: {
    region: string[];
    national: string[];
    logistique: string[];
    seller: string[];
  };
};

const AuthenticationReducer = (
  state: AuthenticationState = { roles: [] },
  action: AuthenticationAction,
): AuthenticationState => {
  switch (action.type) {
    case 'AuthenticationLogin':
      return {
        ...state,
        ...action.data,
      };
    case 'AuthenticationLogout':
      return {
        roles: [],
      };
    default:
      return state;
  }
};

export default AuthenticationReducer;
