import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';

function BuyPurveyorForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') && !hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_EDIT');

  // Purveyor Form
  const [form, setForm] = React.useState({
    name: {
      name: 'name',
      label: 'Nom',
      textHelper: 'Saisissez le nom du fournisseur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    address: {
      name: 'address',
      label: 'Adresse',
      textHelper: "Saisissez l'adresse du fournisseur.",
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    zipCode: {
      name: 'zipCode',
      label: 'Code postal',
      textHelper: 'Saisissez le code postal du fournisseur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    city: {
      name: 'city',
      label: 'Ville',
      textHelper: 'Saisissez la ville du fournisseur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    purveyorType: {
      name: 'purveyorType',
      label: 'Type de fournisseur',
      textHelper: 'Choisissez le type de fournisseur.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    paymentTerms: {
      name: 'paymentTerms',
      label: 'Condition de payment',
      textHelper: 'Choisissez la condition de payment.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    productionTime: {
      name: 'productionTime',
      label: 'Délai de production',
      textHelper: 'Saisissez le délai de production.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    deliveryQuantityVariation: {
      name: 'deliveryQuantityVariation',
      label: 'Variation des quantités livrées',
      textHelper: 'Saisissez la variation des quantités.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    transport: {
      name: 'transport',
      label: 'Franco de port',
      textHelper: 'Saisissez la prise en charge du transport.',
      type: 'boolean',
      defaultValue: false,
    },
    divalto: {
      name: 'divalto',
      label: 'Référence Divalto',
      textHelper: 'Saisissez la référence divalto du fournisseur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    deposit: {
      name: 'deposit',
      label: 'Dépôt',
      textHelper: 'Choisissez un dépôt.',
      type: 'integer',
      defaultValue: '',
    },
    depositDefault: {
      name: 'depositDefault',
      label: 'Dépôt par défaut de livraison',
      textHelper: 'Choisissez un dépôt par défaut de livraison.',
      type: 'integer',
      defaultValue: '',
    },
    country: {
      name: 'country',
      label: 'Pays',
      textHelper: 'Choisissez le pays.',
      type: 'integer',
      defaultValue: '',
    },
  });
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const handlerForm = initFormHandler(form, setForm);
  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      if (props.purveyor) {
        ApiHandler.post(
          {
            route: 'api_buy_purveyor_edit',
            data: handlerForm.getData(),
            params: { id: props.purveyor.id },
          },
          (response) => {
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Fournisseur modifié avec succès.',
                  variant: 'success',
                }),
              );
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }
            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      } else {
        ApiHandler.post(
          {
            route: 'api_buy_purveyor_add',
            data: handlerForm.getData(),
          },
          (response) => {
            if (response.status === 200) {
              navigate(getRoutePathname('buy_purveyor', { id: response.data.id }));
              dispatch(
                SnackbarOpen({
                  text: 'Fournisseur créé avec succès.',
                  variant: 'success',
                }),
              );
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }
            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      }
    }
  };
  React.useEffect(() => {
    handlerForm.start();

    if (props.purveyor) handlerForm.setDataApi(props.purveyor);
  }, []);
  React.useEffect(() => {
    handlerForm.setFormLoading(justWatch);
  }, [justWatch]);

  // Purveyor Options
  const [optionsPurveyorType, setOptionsPurveyorType] = React.useState([]);
  const [optionsPaymentTerms, setOptionsPaymentTerms] = React.useState([]);
  const [optionsDeposit, setOptionsDeposit] = React.useState([]);
  const [optionsCountry, setOptionsCountry] = React.useState([]);
  React.useEffect(() => {
    ApiHandler.get({ route: 'api_select_purveyor_type' }, (response) => setOptionsPurveyorType(response.data));
    ApiHandler.get({ route: 'api_select_payment_terms' }, (response) => setOptionsPaymentTerms(response.data));
    ApiHandler.get({ route: 'api_select_country' }, (response) => setOptionsCountry(response.data));
    ApiHandler.get({ route: 'api_select_deposit' }, (response) => {
      const data = response.data;
      data.unshift({ value: '', label: '' });
      setOptionsDeposit(response.data);
    });
  }, []);

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title={'Information fournisseur'} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <TextFieldComponent id={'name'} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <TextFieldComponent id={'address'} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={1}>
          <TextFieldComponent id={'zipCode'} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
          <TextFieldComponent id={'city'} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <SelectComponent id={'country'} options={optionsCountry} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectComponent id={'purveyorType'} options={optionsPurveyorType} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectComponent id={'depositDefault'} options={optionsDeposit} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectComponent id={'paymentTerms'} options={optionsPaymentTerms} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <TextFieldComponent id={'divalto'} handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <TextFieldComponent id={'deliveryQuantityVariation'} handler={handlerForm} adornment={<span>%</span>} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <TextFieldComponent id={'productionTime'} handler={handlerForm} adornment={<span>semaine(s)</span>} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <CheckboxComponent id={'transport'} handler={handlerForm} />
        </Grid>
      </Grid>

      {!justWatch && (
        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm} />
      )}
    </ShadowBoxComponent>
  );
}

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important',
  },
});

export default BuyPurveyorForm;
