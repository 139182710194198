import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StateType } from '#app/Store';

import { SnackbarClose } from '../Action/SnackbarAction';

let timeoutClose: ReturnType<typeof setTimeout> | null = null;

const SnackbarComponent: React.FC = () => {
  const snackbarReducer = useSelector((state: StateType) => state.SnackbarReducer);
  const dispatch = useDispatch();

  const close = () => dispatch(SnackbarClose());

  useEffect(() => {
    if (timeoutClose) {
      clearTimeout(timeoutClose);
    }
    timeoutClose = setTimeout(close, snackbarReducer.duration);
    return () => {
      if (timeoutClose) {
        clearTimeout(timeoutClose);
      }
    };
  }, [snackbarReducer]);

  return (
    <Snackbar open={snackbarReducer.isOpen} onClose={close} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={close} severity={snackbarReducer.variant} sx={{ width: '100%' }} elevation={6} variant="filled">
        {snackbarReducer.text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
