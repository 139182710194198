import { Grid } from '@mui/material';
import React from 'react';

import TabsComponent from '../../../../Component/TabsComponent';
import BuyProductByProductByProductList from './BuyProductByProductByProductList';
import BuyProductByProductForm from './BuyProductByProductForm';
import BuyProductByProductPurveyor from './BuyProductByProductPurveyor';
import BuyProductPurveyorOrderList from './BuyProductPurveyorOrderList';
import BuyProductStockList from './BuyProductStockList';

function BuyProductByProduct(props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ position: 'relative', paddingBottom: 55 }}>
          <BuyProductByProductForm
            product={props.product}
            byProduct={props.byProduct}
            reload={props.reload}
            setIsUpdateSylius={props.setIsUpdateSylius}
          />
        </Grid>
      </Grid>
      {props.byProduct && (
        <div style={{ margin: -12, paddingTop: 15 }}>
          <TabsComponent
            tabDefault={'buy_product_by_product_by_product_list'}
            tabs={[
              { key: 'buy_product_by_product_by_product_list', label: 'Article de conditionnement' },
              { key: 'buy_product_by_product_purveyor', label: 'Fournisseurs' },
              { key: 'buy_product_stock_list', label: 'Stocks' },
              { key: 'buy_product_purveyor_order_list', label: 'Commandes fournisseurs' },
            ]}
          >
            <div key={'buy_product_by_product_by_product_list'}>
              <BuyProductByProductByProductList product={props.product} byProduct={props.byProduct} />
            </div>
            <div key={'buy_product_by_product_purveyor'}>
              <BuyProductByProductPurveyor product={props.product} byProduct={props.byProduct} />
            </div>
            <div key={'buy_product_stock_list'}>
              <BuyProductStockList product={props.product} byProduct={props.byProduct} />
            </div>
            <div key={'buy_product_purveyor_order_list'}>
              <BuyProductPurveyorOrderList product={props.product} byProduct={props.byProduct} />
            </div>
          </TabsComponent>
        </div>
      )}
    </>
  );
}

export default BuyProductByProduct;
