export enum PaymentMethod {
  ENCAISSEMENT_BOUTIQUE = 'encaissement_boutique',
  PAYPAL_TERRAVITA = 'paypal_terravita',
  PAYPAL_TLN = 'paypal_tln',
  PAYZEN_EMBED_EIYOLAB = 'payzen_embed_eiyolab',
  PAYZEN_EMBED_PROD = 'payzen_embed_prod',
  PAYZEN_TERRAVITA = 'payzen_terravita',
  TLN_PREPROD = 'tln_preprod',
}

export const PaymentMethodTranslation: Record<PaymentMethod, string> = {
  [PaymentMethod.PAYZEN_EMBED_EIYOLAB]: 'CB',
  [PaymentMethod.PAYZEN_EMBED_PROD]: 'CB',
  [PaymentMethod.PAYZEN_TERRAVITA]: 'CB',
  [PaymentMethod.TLN_PREPROD]: 'CB',

  [PaymentMethod.PAYPAL_TERRAVITA]: 'Paypal',
  [PaymentMethod.PAYPAL_TLN]: 'Paypal',

  [PaymentMethod.ENCAISSEMENT_BOUTIQUE]: 'Boutique',
};
