type TableAction = {
  type: string;
  id?: string;
  withCache?: boolean;
};

type TableState = {
  id?: string;
  withCache?: boolean;
};

const TableReducer = (state: TableState = {}, action: TableAction): TableState => {
  switch (action.type) {
    case 'TableReload':
      return {
        id: action.id,
        withCache: action.withCache,
      };
    default:
      return state;
  }
};

export default TableReducer;
