export enum Channels {
  TLN = 1,
  TERRAVITA = 2,
  EIYOLAB = 3,
  B2B = 4,
  BOUTIQUES = 5,
  AMAZON = 6,
  REAPPROBOUTIQUES = 7,
}

export type ChannelSelect = {
  label: string;
  value: Channels;
};
