import { Article } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ByProduct, OrderClientLitige } from '#app/typings/order';

import ButtonComponent from '../../../../../Component/ButtonComponent';
import { getRoutePathname } from '../../../../../Handler/RouteHandler';
import { styleDataGridDefault } from '../../../../../Style/styleDataGridDefault';
import { transformObjectToDataGridRowsArray } from '../../../../../Util/DataGridDataTransformer';
import DisputeModal from '../../../../../View/Sale/OrderClient/SaleOrderClient/components/DisputeModal';

type Props = {
  disputes: OrderClientLitige[];
  loading: boolean;
  orderUuid: string;
  products: ByProduct[];
  canOpenDispute?: boolean;
};

const DisputeList: React.FC<Props> = ({ disputes, loading, orderUuid, products, canOpenDispute = true }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const columns: GridColDef[] = [
    {
      field: 'ref',
      headerName: 'Réf Simplifiée',
      minWidth: 180,
    },
    {
      field: 'uuid',
      headerName: 'Réf Uuid',
      minWidth: 280,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'Statut',
      minWidth: 150,
    },
    {
      field: 'reason',
      headerName: 'Raison',
      flex: 1,
    },
    {
      field: 'author',
      headerName: 'Auteur',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'litigeAt',
      headerName: 'Date de création',
      minWidth: 150,
      valueGetter: (value) => moment(value).format('lll'),
    },
    {
      field: 'dealAt',
      headerName: 'Date de cloture',
      minWidth: 150,
      valueGetter: (value) => (value ? moment(value).format('lll') : '-'),
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'actions',
      getActions: ({ row }) => [
        <Tooltip title="Visualiser" placement="left">
          <Link to={getRoutePathname('client_service_order_client_litige', { uuid: row.uuid })}>
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>,
      ],
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        sx={styleDataGridDefault}
        loading={loading}
        ignoreDiacritics
        columns={columns}
        rows={transformObjectToDataGridRowsArray(disputes)}
        disableColumnMenu
        slots={{
          toolbar: canOpenDispute
            ? () => (
                <GridToolbarContainer>
                  <ButtonComponent color="#F17105" label="Ouvrir un litige" onClick={() => setOpenModal(true)} />
                </GridToolbarContainer>
              )
            : undefined,
        }}
        rowHeight={30}
        disableRowSelectionOnClick
        hideFooter
      />
      {openModal && canOpenDispute && (
        <DisputeModal onClose={() => setOpenModal(false)} uuid={orderUuid} products={products} />
      )}
    </div>
  );
};

export default DisputeList;
