type SnackbarAction = {
  type: 'SnackbarOpen' | 'SnackbarClose';
  text?: string;
  variant?: string;
  duration?: number;
};

type SnackbarState = {
  isOpen?: boolean;
  text?: string;
  variant?: any;
  duration?: number;
};

const SnackbarReducer = (state: SnackbarState = {}, action: SnackbarAction): SnackbarState => {
  switch (action.type) {
    case 'SnackbarOpen':
      return {
        ...state,
        isOpen: true,
        text: action.text,
        variant: action.variant,
        duration: action.duration,
      };
    case 'SnackbarClose':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
