import { QrCodeScanner } from '@mui/icons-material';
import { Grid, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function CreateReappro() {
  const [beep] = useSound('/mp3/beep.mp3');
  const [boop] = useSound('/mp3/boop.mp3');

  const classes = useStyles();
  const navigate = useNavigate();
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [loading, setLoading] = React.useState(true);
  const [loadingForm, setLoadingForm] = React.useState(false);
  const [byProducts, setByProducts] = React.useState([]);
  const [isFocus, setFocus] = React.useState(false);
  const [optionsMark, setOptionsMark] = React.useState([]);
  const [optionsFamily, setOptionsFamily] = React.useState([]);
  const [mark, setMark] = React.useState(null);
  const [family, setFamily] = React.useState(null);
  const [optionsDeposit, setOptionsDeposit] = React.useState([]);
  const [depositId, setDepositId] = React.useState({});
  const [depositSource, setDepositSource] = React.useState({});
  const [isBrouillon, setIsBrouillon] = React.useState({});

  const ref = useRef(null);
  const [formRef, setFormRef] = React.useState({
    picking: {
      name: 'picking',
      label: 'piking',
      type: 'text',
      defaultValue: '',
    },
  });
  const handlerFormRef = initFormHandler(formRef, setFormRef);
  // Form
  const [inputs, setInputs] = React.useState({});
  const handlerForm = initFormHandler(inputs, setInputs);

  const onChange = (value) => {
    for (const index in byProducts) {
      if (byProducts[index].ean) {
        if (value === byProducts[index].ean) {
          dispatch(
            SnackbarOpen({
              text: 'Article enregistré.',
              variant: 'success',
            }),
          );
          beep();

          handlerForm.setValue(
            'amount_' + byProducts[index].id,
            (inputs['amount_' + byProducts[index].id].value ?? 0) + 1,
          );
          return;
        }
      }
    }
    boop();
    dispatch(
      SnackbarOpen({
        text: 'Article introuvable !',
        variant: 'error',
      }),
    );
  };
  const save = (callback = false) => {
    if (handlerForm.checkError() < 1) {
      setLoadingForm(true);

      const data = handlerForm.getData();
      let isOk = 0;
      for (const key in handlerForm.getData()) {
        if (parseInt(data[key]) > 0) {
          isOk = 1;
          break;
        }
      }

      if (isOk === 1) {
        ApiHandler.post(
          {
            route: 'api_shop_reappro_list_by_products_save',
            params: { shopId: authenticationReducer.shopSelected, validate: 0, depositId: depositId },
            data: { byProduct: handlerForm.getData() },
          },
          (response) => {
            setLoadingForm(false);
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Demande de réappro enregistré.',
                  variant: 'success',
                }),
              );

              if (callback) callback();
              else navigate(getRoutePathname('shop_reappro_list', { shopId: authenticationReducer.shopSelected }));
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }
          },
        );
      } else {
        setLoadingForm(false);
        dispatch(
          SnackbarOpen({
            text: 'Aucune quantité saisie, merci de corriger votre demande de réappro',
            variant: 'error',
          }),
        );
      }
    }
  };

  const validate = (callback = false) => {
    if (handlerForm.checkError() < 1) {
      setLoadingForm(true);

      const data = handlerForm.getData();
      let isOk = 0;
      for (const key in handlerForm.getData()) {
        if (parseInt(data[key]) > 0) {
          isOk = 1;
          break;
        }
      }

      if (isOk === 1) {
        ApiHandler.post(
          {
            route: 'api_shop_reappro_list_by_products_save',
            params: { shopId: authenticationReducer.shopSelected, validate: 1, depositId: depositId },
            data: { byProduct: handlerForm.getData() },
          },
          (response) => {
            setLoadingForm(false);
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Demande de réappro enregistré.',
                  variant: 'success',
                }),
              );

              if (callback) callback();
              else navigate(getRoutePathname('shop_reappro_list', { shopId: authenticationReducer.shopSelected }));
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }
          },
        );
      } else {
        setLoadingForm(false);
        dispatch(
          SnackbarOpen({
            text: 'Aucune quantité saisie, merci de corriger votre demande de réappro',
            variant: 'error',
          }),
        );
      }
    }
  };

  const reloadDeposit = (deposit) => {
    if (depositSource && depositSource === deposit) {
      setDepositId(1);
      ApiHandler.get({ route: 'api_select_deposit', data: { limited: true } }, (responseDeposits) => {
        setOptionsDeposit(responseDeposits.data);
        handlerFormDeposit.setValue('deposit', 1);
        setLoading(false);
      });

      dispatch(
        SnackbarOpen({
          text: 'Le dépot source et d origine ne peuvent pas être identique, merci de corriger.',
          variant: 'error',
        }),
      );
    } else {
      ApiHandler.get(
        {
          route: 'api_shop_reappro_list_by_products',
          params: { shopId: authenticationReducer.shopSelected, depositId: deposit },
        },
        (response) => {
          setDepositId(deposit);

          const byProductsInput = {};
          const marksTmp = {};
          const familysTmp = {};
          for (const index in response.data.byProducts) {
            marksTmp[response.data.byProducts[index].markId] = {
              label: response.data.byProducts[index].markLabel,
              value: response.data.byProducts[index].markId,
            };
            familysTmp[response.data.byProducts[index].familyId] = {
              label: response.data.byProducts[index].familyLabel,
              value: response.data.byProducts[index].familyId,
            };
            byProductsInput['amount_' + response.data.byProducts[index].id] = {
              name: 'amount_' + response.data.byProducts[index].id,
              textHelper: "Saisissez la quantité de l'article.",
              label: 'Quantité',
              type: 'integer',
              defaultValue: response.data.byProducts[index].amountDefault ?? '',
              value: response.data.byProducts[index].amountDefault ?? '',
              options: {},
            };
          }

          const marks = [{ label: '', value: '' }];
          for (const index in marksTmp) {
            marks.push(marksTmp[index]);
          }

          const familys = [{ label: '', value: '' }];
          for (const index in familysTmp) {
            familys.push(familysTmp[index]);
          }

          const strAscending = [...marks].sort((a, b) => (a.label > b.label ? 1 : -1));
          const strAscending2 = [...familys].sort((a, b) => (a.label > b.label ? 1 : -1));

          setOptionsMark(strAscending);
          setOptionsFamily(strAscending2);

          setInputs(byProductsInput);
          setByProducts(response.data.byProducts);
          setLoading(false);
        },
      );
    }
  };

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: 'Choisissez une marque.',
      type: 'integer',
      defaultValue: '',
    },
    family: {
      name: 'family',
      label: 'Famille',
      textHelper: 'Choisissez une famille.',
      type: 'integer',
      defaultValue: '',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const [formDeposit, setFormDeposit] = React.useState({
    deposit: {
      name: 'deposit',
      label: 'depot',
      textHelper: 'Choisissez un dépôt.',
      type: 'integer',
      defaultValue: '',
    },
  });
  const handlerFormDeposit = initFormHandler(formDeposit, setFormDeposit);

  React.useEffect(() => {
    handlerForm.start();

    ApiHandler.get(
      { route: 'api_shop_reappro_get_last', params: { shopId: authenticationReducer.shopSelected } },
      (response) => {
        setDepositId(response.data.depositId);
        setDepositSource(response.data.depositSourceId);
        setIsBrouillon(response.data.lock);

        handlerFormDeposit.start();
        ApiHandler.get({ route: 'api_select_deposit', data: { limited: true } }, (responseDeposits) => {
          setOptionsDeposit(responseDeposits.data);
          if (depositId) {
            handlerFormDeposit.setValue('deposit', response.data.depositId);
          } else {
            handlerFormDeposit.setValue('deposit', 1);
          }
          setLoading(false);
        });

        ApiHandler.get(
          {
            route: 'api_shop_reappro_list_by_products',
            params: { shopId: authenticationReducer.shopSelected, depositId: response.data.depositId },
          },
          (response) => {
            const byProductsInput = {};
            const marksTmp = {};
            const familysTmp = {};
            for (const index in response.data.byProducts) {
              marksTmp[response.data.byProducts[index].markId] = {
                label: response.data.byProducts[index].markLabel,
                value: response.data.byProducts[index].markId,
              };
              familysTmp[response.data.byProducts[index].familyId] = {
                label: response.data.byProducts[index].familyLabel,
                value: response.data.byProducts[index].familyId,
              };
              byProductsInput['amount_' + response.data.byProducts[index].id] = {
                name: 'amount_' + response.data.byProducts[index].id,
                textHelper: "Saisissez la quantité de l'article.",
                label: 'Quantité',
                type: 'integer',
                defaultValue: response.data.byProducts[index].amountDefault ?? '',
                value: response.data.byProducts[index].amountDefault ?? '',
                options: {},
              };
            }

            const marks = [{ label: '', value: '' }];
            for (const index in marksTmp) {
              marks.push(marksTmp[index]);
            }

            const strAscending = [...marks].sort((a, b) => (a.label > b.label ? 1 : -1));

            setOptionsMark(strAscending);

            const familys = [{ label: '', value: '' }];
            for (const index in familysTmp) {
              familys.push(familysTmp[index]);
            }

            const strAscending2 = [...familys].sort((a, b) => (a.label > b.label ? 1 : -1));

            setOptionsFamily(strAscending2);

            setInputs(byProductsInput);
            setByProducts(response.data.byProducts);
            setLoading(false);
          },
        );
      },
    );
  }, []);

  return (
    <ContentViewComponent
      loading={loading}
      breadcrumbs={{ title: 'Demande de réapprovisionnement', context: 'Gestion des stocks' }}
    >
      {isFocus && (
        <QrCodeScanner style={{ color: '#28a745', position: 'absolute', top: 25, right: 50, fontSize: 35 }} />
      )}
      <TextFieldComponent
        style={{ position: 'absolute', right: 0, top: 0, height: 0, width: 50, opacity: 0, zIndex: 1000 }}
        id={'picking'}
        handler={handlerFormRef}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        inputRef={ref}
        onScan={onChange}
        autoComplete={false}
      />

      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SelectComponent
                  id={'mark'}
                  options={optionsMark}
                  handler={handlerFormSearch}
                  onChange={(val) => setMark(val)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SelectComponent
                  id={'family'}
                  options={optionsFamily}
                  handler={handlerFormSearch}
                  onChange={(val) => setFamily(val)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SelectComponent
                  disabled={isBrouillon}
                  id={'deposit'}
                  options={optionsDeposit}
                  handler={handlerFormDeposit}
                  onChange={(val) => reloadDeposit(val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />

      <ShadowBoxComponent className={classes.shadowBox}>
        {isFocus ? (
          <ButtonComponent
            color={'#a47b00'}
            onClick={() => {
              ref.current.blur();
            }}
            label={'Désactiver le lecteur code barre'}
            className={classes.buttonTop}
          />
        ) : (
          <ButtonComponent
            onClick={() => {
              ref.current.focus();
            }}
            label={'Activer le lecteur code barre'}
            className={classes.buttonTop}
          />
        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }}>SKU</TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Nom
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Parfum / Couleur
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Conditionnement
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Marque
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Famille
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité en boutique
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité dépot
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité minimale
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Stats vente
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                  Quantité
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {byProducts.length > 0 ? (
                byProducts
                  .filter((row) => !((mark && row.markId !== mark) || (family && row.familyId !== family)))
                  .map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ fontSize: 11 }} component="th" scope="row">
                        {row.sku}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.name}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.flavor}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.packSize}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.mark}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.family}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.amountAtHomeAvailable}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.amountRequestedAvailable}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.amountMini}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        {row.monthSell}
                      </TableCell>
                      <TableCell style={{ fontSize: 11 }} align="right">
                        <TextFieldComponent id={'amount_' + row.id} handler={handlerForm} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
          <ButtonComponent
            label={'Enregistrer'}
            className={classes.button}
            onClick={() => save()}
            loading={loadingForm}
          />
          <ButtonComponent
            label={'Valider'}
            className={classes.button}
            onClick={() => validate()}
            loading={loadingForm}
          />
        </div>
      </ShadowBoxComponent>
      <br />
      <br />
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 60,
    paddingTop: 60,
  },
  button: {
    margin: '15px 15px 15px 0 !important',
  },
  buttonTop: {
    margin: '15px !important',
    top: '0 !important',
    right: '0 !important',
    position: 'absolute !important',
  },
});

export default CreateReappro;
