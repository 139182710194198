import { Box, Grid, Slide } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import type { GridRowParams } from '@mui/x-data-grid/models/params';
import React, { useEffect, useState } from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { CustomPagination } from '../../../Component/DataGrid/CustomPagination';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import FooterWithTotalGeneral from '../../../Component/DataGrid/FooterWithTotalGeneral';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { displayErrorMessage } from '../../../Error/Errors';
import { fetchPaymentMethods, fetchStatsChannels, Option } from '../../../Filter/FetchDropdownData';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler, Form } from '../../../Handler/FormHandler';
import { styleDataGridDefault } from '../../../Style/styleDataGridDefault';
import { listingStyles } from '../../../Style/useStyles';
import { transformObjectToDataGridRowsArray } from '../../../Util/DataGridDataTransformer';
import { momentDateFormatter } from '../../../Util/MomentDateFormatter';
import { formatNumberToEurCurrency } from '../../../Util/NumberTool';
import { momentDateComparator } from '../../../Util/SortMomentDate';
import { floatSortComparator } from '../../../Util/SortNumber';
import { PaymentMethodTranslation, PaymentMethod } from '../../../typings/orderPayment';

type RowDetails = {
  orderRef: string;
  confirmedAt: { date: string };
  channel: string;
  paymentMethod: string;
  shopName: string;
  amountShopCard: number;
  amountShopCash: number;
  amountShopAvoir: number;
  amountCashBack: number;
  ttcOrder: number;
};

const StatisticSalePayment: React.FC = () => {
  const [loadingContent, setLoadingContent] = useState(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
  const [optionsChannels, setOptionsChannels] = useState<Option[]>([]);
  const [optionsPaymentMethods, setOptionsPaymentMethods] = useState<Option[]>([]);
  const [cellsTable, setCellsTable] = useState<RowDetails[]>([]);
  const [totalGeneral, setTotalGeneral] = useState<number>(0);
  const [rowsTableDetails, setRowsTableDetails] = useState<RowDetails[]>([]);
  const [orderDetailsTitle, setOrderDetailsTitle] = useState<string>('');

  const classes = listingStyles();

  const [formSearch, setFormSearch] = useState<Form>({
    startDate: {
      name: 'startDate',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      options: { validation: ['date'] },
    },
    endDate: {
      name: 'endDate',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      options: { validation: ['date'] },
    },
    channels: {
      name: 'channels',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'array',
    },
    paymentMethods: {
      name: 'paymentMethods',
      label: 'Moyen de paiement',
      textHelper: 'Rechercher par moyen de paiement.',
      type: 'array',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  useEffect(() => {
    handlerFormSearch.start();
    fetchPaymentMethods(setOptionsPaymentMethods);
    fetchStatsChannels(setOptionsChannels);
  }, []);

  const onSearch = (): void => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();

    const filters = {
      startDate: formSearch.startDate.value ? momentDateFormatter.formatStartOfDay(formSearch.startDate.value) : '',
      endDate: formSearch.endDate.value ? momentDateFormatter.formatEndOfDay(formSearch.endDate.value) : '',
      channels: datas.channels[0] === null ? [] : datas.channels,
      paymentMethods: datas.paymentMethods[0] === null ? [] : datas.paymentMethods,
    };

    ApiHandler.get(
      {
        route: 'api_statistic_sale_payment',
        data: filters,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          const { totalGeneral, ...data } = response.data;
          setCellsTable(transformObjectToDataGridRowsArray<RowDetails>(data));
          setTotalGeneral(totalGeneral);
          setRowsTableDetails([]);

          dispatch(LinearProgressMainLayoutDeactivate());
        } else {
          displayErrorMessage(response);
        }
        setDisabledStatusButtonSearch(false);
        setLoadingContent(false);
        dispatch(LinearProgressMainLayoutDeactivate());
      },
    );
  };

  const exportFileName = 'stats_sale_payment';

  const columns: GridColDef[] = [
    {
      field: 'confirmedAt',
      headerName: 'Date de confirmation',
      minWidth: 150,
      sortComparator: momentDateComparator,
      valueGetter: (value: { date: string }) => momentDateFormatter.dateTimeToFormatFr(value.date),
    },
    {
      field: 'channel',
      headerName: 'Canal',
      minWidth: 150,
    },
    {
      field: 'paymentMethod',
      headerName: 'Moyen de paiement',
      minWidth: 150,
    },
    {
      field: 'ttcGroupedOrders',
      headerName: 'Total TTC',
      headerAlign: 'right',
      flex: 1,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
  ];

  const columnsTableDetails: GridColDef[] = [
    {
      field: 'orderRef',
      headerName: 'Cmd Référence',
      minWidth: 160,
    },
    {
      field: 'confirmedAt',
      headerName: 'Date de confirmation',
      minWidth: 120,
      align: 'right',
      sortComparator: momentDateComparator,
      valueGetter: (value: { date: string }) => momentDateFormatter.dateTimeToFormatFr(value.date),
    },
    {
      field: 'channel',
      headerName: 'Canal',
      minWidth: 130,
    },
    {
      field: 'paymentMethod',
      headerName: 'Moyen de paiement',
      minWidth: 150,
      valueGetter: (value: PaymentMethod) => PaymentMethodTranslation[value],
    },
    {
      field: 'shopName',
      headerName: 'Nom de boutique',
      minWidth: 250,
    },
    {
      field: 'amountShopCard',
      headerName: 'Montant payé en boutique en CB',
      headerAlign: 'right',
      minWidth: 150,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'amountShopCash',
      headerName: 'Montant payé en boutique en espèce',
      headerAlign: 'right',
      minWidth: 150,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'amountShopAvoir',
      headerName: 'Montant avoir en boutique',
      headerAlign: 'right',
      minWidth: 150,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'amountCashBack',
      headerName: 'Montant rendu en boutique',
      headerAlign: 'right',
      minWidth: 150,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'ttcOrder',
      headerName: 'Montant TTC commande',
      headerAlign: 'right',
      minWidth: 150,
      flex: 1,
      align: 'right',
      sortComparator: floatSortComparator,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
  ];

  const loadCommandDetails = (params: GridRowParams): void => {
    const selectedRowDetails = transformObjectToDataGridRowsArray(params.row.details) as RowDetails[];
    setRowsTableDetails(selectedRowDetails);

    setOrderDetailsTitle(`
        Détails des commandes du:
            "${momentDateFormatter.dateTimeToFormatFr(params.row.confirmedAt.date)} - 
            ${params.row.channel} - 
            ${params.row.paymentMethod} - 
            Total: ${formatNumberToEurCurrency(params.row.ttcGroupedOrders)}"
            `);
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats Encaissements commandes', context: 'Statistiques' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title="Filtres" />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <DatePickerComponent id="startDate" handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <DatePickerComponent id="endDate" handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <MultipleSelectComponent id="channels" options={optionsChannels} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <MultipleSelectComponent
                  id="paymentMethods"
                  options={optionsPaymentMethods}
                  handler={handlerFormSearch}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={2}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label="Rechercher"
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />

      <Stack spacing={2}>
        <Box sx={{ height: 420, width: '100%' }}>
          <DataGrid
            sx={styleDataGridDefault}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            ignoreDiacritics
            columns={columns}
            rows={cellsTable}
            disableColumnMenu
            rowHeight={25}
            slots={{
              pagination: CustomPagination,
              footer: () => <FooterWithTotalGeneral totalGeneralValue={totalGeneral} />,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
            }}
            disableRowSelectionOnClick
            onRowClick={loadCommandDetails}
          />
        </Box>

        {orderDetailsTitle && (
          <Box>
            <Alert severity="info" sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {orderDetailsTitle}
            </Alert>
            <DataGrid
              sx={styleDataGridDefault}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              ignoreDiacritics
              disableRowSelectionOnClick
              columns={columnsTableDetails}
              rows={rowsTableDetails}
              disableColumnMenu
              rowHeight={25}
              slots={{
                toolbar: () => <CustomToolbar fileName={exportFileName} />,
                pagination: CustomPagination,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
              }}
            />
          </Box>
        )}
      </Stack>
    </ContentViewComponent>
  );
};

export default StatisticSalePayment;
