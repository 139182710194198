import moment from 'moment';

export const momentDateFormatter = {
  formatMomentDate: (date: string | Date | moment.Moment, format: string): string => {
    return moment(date).format(format);
  },

  formatStartOfDay: (date: string | Date | moment.Moment, format: string = 'YYYY-MM-DD'): string => {
    return moment(date).format(`${format} 00:00:00`);
  },

  formatEndOfDay: (date: string | Date | moment.Moment, format: string = 'YYYY-MM-DD'): string => {
    return moment(date).format(`${format} 23:59:59`);
  },

  dateTimeToFormatFr: (date: string | Date): string => {
    return new Date(date).toLocaleDateString('fr-FR');
  },
};
