import { Edit } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { hasRight } from '../../../Common/UserCommon';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function SettingPrinterList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [printer, setPrinter] = React.useState(null);

  // Global
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'label',
      label: 'Libellé',
      options: { filter: true, sort: true },
    },
    {
      name: 'ip',
      label: 'Ip',
      options: { filter: true, sort: true },
    },
    {
      name: 'port',
      label: 'Port',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_SETTING_PRINTER') ||
      hasRight(authenticationReducer, 'ROLE_SETTING_PRINTER_EDIT')
    ) {
      return (
        <Tooltip title={'Modifier'} placement="left">
          <IconButton
            onClick={() => {
              handlerForm.reset();
              setPrinter(row.id);
              handlerForm.setValue('label', row.label);
              handlerForm.setValue('ip', row.ip);
              handlerForm.setValue('port', row.port);
              setOpenDialog(true);
            }}
          >
            <Edit style={{ color: '#17a2b8' }} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <IconButton style={{ cursor: 'no-drop' }}>
          <Edit />
        </IconButton>
      );
    }
  };
  // Form
  const [form, setForm] = React.useState({
    label: {
      name: 'label',
      label: 'Libellé',
      textHelper: "Saisissez le libellé de l'imprimante.",
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    ip: {
      name: 'ip',
      label: 'Ip',
      textHelper: "Saisissez l'adresse ip de l'imprimante.",
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    port: {
      name: 'port',
      label: 'Port',
      textHelper: "Saisissez le port de l'imprimante.",
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const handlerForm = initFormHandler(form, setForm);
  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      if (printer) {
        ApiHandler.post(
          {
            route: 'api_setting_printer_edit',
            data: handlerForm.getData(),
            params: { id: printer },
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_printer_list'));
              setPrinter(null);
              dispatch(
                SnackbarOpen({
                  text: 'Imprimante modifié avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_printer');
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      } else {
        ApiHandler.post(
          {
            route: 'api_setting_printer_add',
            data: handlerForm.getData(),
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_printer_list'));
              dispatch(
                SnackbarOpen({
                  text: 'Imprimante créé avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_printer');
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      }
    }
  };
  React.useEffect(() => {
    handlerForm.start();
  }, []);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des imprimantes', context: 'Paramétrage' }}>
      <TableComponent
        id={'api_setting_printer_list'}
        title={'Imprimantes'}
        columns={columns}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SETTING_PRINTER') ||
          hasRight(authenticationReducer, 'ROLE_SETTING_PRINTER_ADD')
            ? {
                label: 'Ajouter une imprimante',
                onClick: () => {
                  setPrinter(null);
                  handlerForm.reset();
                  setOpenDialog(true);
                },
              }
            : false
        }
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_setting_printer_list' }, (response) => {
            const data = response.data;
            for (const index in data) {
              data[index].action = getAction(data[index]);
            }
            resolve(data);
          });
        }}
      />

      <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ fontSize: 15 }}>
          {printer ? 'Modifier une imprimante' : 'Ajouter une imprimante'}
        </DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextFieldComponent id={'label'} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextFieldComponent id={'ip'} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextFieldComponent id={'port'} handler={handlerForm} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setOpenDialog(false)}
            disabled={isLoadingForm}
          />
          <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm} />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

export default SettingPrinterList;
