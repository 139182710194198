import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import ApiHandler from '../../../../Handler/ApiHandler';
import { transformObjectToDataGridRowsArray } from '../../../../Util/DataGridDataTransformer';
import { formatNumberToEurCurrency } from '../../../../Util/NumberTool';
import { Filters } from '../../../../View/Statistic/Order/StatisticOrder';
import { TaxTypeId, TaxTypeTranslation } from '../../../../typings/orderDocument';

type Props = {
  filters: Filters;
};

type TotalPrice = Record<TaxTypeId, number> & {
  totalHT: number;
  totalTTC: number;
};

const ListTotals: React.FC<Props> = ({ filters }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<TotalPrice>({
    totalHT: 0,
    totalTTC: 0,
    2: 0,
    3: 0,
    5: 0,
    10: 0,
    12: 0,
    19: 0,
  });

  const handleSearch = () => {
    setLoading(true);
    ApiHandler.get<TotalPrice>(
      {
        route: 'api_statistic_order_document_totals',
        data: { ...filters },
      },
      (response) => {
        if (response.data) {
          setTotalPrice(response.data);
        }
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    handleSearch();
  }, [filters]);

  return (
    <div className={classes.container}>
      <DataGrid
        loading={loading}
        disableColumnMenu
        ignoreDiacritics
        hideFooter
        columns={[
          ...(Object.keys(TaxTypeTranslation) as TaxTypeId[])
            .filter((taxTypeId) => totalPrice[taxTypeId] > 0)
            .map((taxTypeId) => ({
              field: taxTypeId,
              headerName: `TVA ${TaxTypeTranslation[taxTypeId]}`,
              flex: 1,
              valueGetter: (value: number) => formatNumberToEurCurrency(value),
            })),
          {
            field: 'totalHT',
            headerName: 'Total HT',
            flex: 1,
            valueGetter: (value) => formatNumberToEurCurrency(value),
          },
          {
            field: 'totalTTC',
            headerName: 'Total TTC',
            flex: 1,
            valueGetter: (value) => formatNumberToEurCurrency(value),
          },
        ]}
        rows={transformObjectToDataGridRowsArray([totalPrice])}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20,
  },
});

export default ListTotals;
