type ScreenChangeAction = {
  type: 'ScreenChange';
  data: any;
};

export const ScreenChange = (data: any): ScreenChangeAction => ({
  type: 'ScreenChange',
  data,
});

type ScreenFullAction = {
  type: 'ScreenFull';
  data: { full: boolean };
};

export const ScreenFull = (full: boolean = false): ScreenFullAction => ({
  type: 'ScreenFull',
  data: { full },
});
