import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
} from '@mui/x-data-grid';
import React from 'react';

type Props = Pick<GridCsvExportOptions, 'getRowsToExport'> & {
  fileName: string;
};

const CustomToolbar: React.FC<Props> = ({ fileName, getRowsToExport }) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    {getRowsToExport && (
      <GridToolbarExport
        csvOptions={{
          fileName: fileName,
          getRowsToExport: getRowsToExport,
          delimiter: ';',
        }}
        printOptions={{
          fileName: fileName,
          hideToolbar: true,
          hideFooter: true,
        }}
      />
    )}
    <GridToolbarQuickFilter
      sx={{
        marginLeft: 'auto',
        '& .MuiInputBase-input': {
          fontSize: '0.8rem',
        },
      }}
    />
  </GridToolbarContainer>
);

export default CustomToolbar;
