import { formatCurrencyToFloat } from './NumberTool';

export const floatSortComparator = (val1: string | number, val2: string | number): number => {
  // Exclude rows that do not contain a number value
  if (val1 === '-') {
    val1 = '0';
  }
  if (val2 === '-') {
    val2 = '0';
  }

  const num1 = formatCurrencyToFloat(val1.toString());
  const num2 = formatCurrencyToFloat(val2.toString());

  return num1 - num2;
};

export const floatSortComparatorForTableComponent = (
  order: 'asc' | 'desc',
  val1: { data: string | number },
  val2: { data: string | number },
): number => {
  if (val1.data === '-') {
    val1.data = '0';
  }
  if (val2.data === '-') {
    val2.data = '0';
  }

  const num1 = formatCurrencyToFloat(val1.data.toString());
  const num2 = formatCurrencyToFloat(val2.data.toString());

  return (num1 - num2) * (order === 'asc' ? 1 : -1);
};
