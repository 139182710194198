type LinearProgressMainLayoutAction = {
  type: 'LinearProgressMainLayoutActivate' | 'LinearProgressMainLayoutDeactivate';
};

type LinearProgressMainLayoutState = {
  isActivated?: boolean;
};

const LinearProgressMainLayoutReducer = (
  state: LinearProgressMainLayoutState = {},
  action: LinearProgressMainLayoutAction,
): LinearProgressMainLayoutState => {
  switch (action.type) {
    case 'LinearProgressMainLayoutActivate':
      return {
        isActivated: true,
      };
    case 'LinearProgressMainLayoutDeactivate':
      return {
        isActivated: false,
      };
    default:
      return state;
  }
};

export default LinearProgressMainLayoutReducer;
