import { Fade, Grid } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TabsComponent from '../../../Component/TabsComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import BuyPurveyorByProductList from './BuyPurveyorComponents/BuyPurveyorByProductList';
import BuyPurveyorContactList from './BuyPurveyorComponents/BuyPurveyorContactList';
import BuyPurveyorForm from './BuyPurveyorComponents/BuyPurveyorForm';
import BuyPurveyorOrderList from './BuyPurveyorComponents/BuyPurveyorOrderList';

function BuyPurveyor() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // Global
  const [isLoadingContent, setIsLoadingContent] = React.useState(true);
  const [purveyor, setPurveyor] = React.useState(null);
  React.useEffect(() => {
    setPurveyor(null);
    if (params.id === 'add') {
      setTimeout(() => setIsLoadingContent(false), 1500);
    } else {
      setIsLoadingContent(true);

      ApiHandler.get(
        {
          route: 'api_buy_purveyor_get',
          params: { id: params.id },
        },
        (response) => {
          if (response.status === 200) {
            setPurveyor(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Ajouter un fournisseur' : 'Éditer un fournisseur',
        context: 'Achat',
        links: [{ path: getRoutePathname('buy_purveyor_list'), label: 'Liste des fournisseurs' }],
      }}
    >
      <Grid container spacing={2}>
        <Fade in timeout={750}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BuyPurveyorForm purveyor={purveyor} />
          </Grid>
        </Fade>
      </Grid>

      <br />

      {purveyor && (
        <TabsComponent
          tabDefault={'purveyor_by_product'}
          tabs={[
            { key: 'purveyor_by_product', label: 'Produits' },
            { key: 'purveyor_order', label: 'Commandes' },
            { key: 'purveyor_contact', label: 'Contacts' },
          ]}
        >
          <div key={'purveyor_by_product'}>
            <BuyPurveyorByProductList purveyor={purveyor} />
          </div>
          <div key={'purveyor_order'}>
            <BuyPurveyorOrderList purveyor={purveyor} />
          </div>
          <div key={'purveyor_contact'}>
            <BuyPurveyorContactList purveyor={purveyor} />
          </div>
        </TabsComponent>
      )}

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BuyPurveyor;
