import { Article, Face, FaceRetouchingOff } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function SaleOrderClientListByStatus() {
  const params = useParams();

  const [loadingContent, setLoadingContent] = React.useState(true);
  const [optionsChannel, setOptionsChannel] = React.useState([]);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'carrier',
      label: 'Transporteur',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date de commande',
      options: { filter: true, sort: true },
    },
    {
      name: 'isGuest',
      label: 'Guest',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => (
    <Tooltip title={'Visualiser'} placement="left">
      <Link to={getRoutePathname('sale_order_client', { uuid: row.uuid })}>
        <IconButton>
          <Article style={{ color: '#17a2b8' }} />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const getTitle = (orderClientState) => {
    switch (orderClientState) {
      case '6':
        return 'Commandes Terminées';
      case '5':
        return 'Commandes En Transit';
      case '2':
        return 'Commandes Confirmées';
      case '7':
        return 'Commandes En Litige';
      case '3':
        return 'Commandes En Préparation';
      default:
        return 'Commandes Clients';
    }
  };

  // FormSearch
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par référence.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list_by_status'] &&
        cacheTableServer['api_sale_order_client_list_by_status'].number
          ? cacheTableServer['api_sale_order_client_list_by_status'].number
          : '',
    },
    numberCarrier: {
      name: 'numberCarrier',
      label: 'Num suivi colis',
      textHelper: 'Rechercher par suivi.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list_by_status'] &&
        cacheTableServer['api_sale_order_client_list_by_status'].numberCarrier
          ? cacheTableServer['api_sale_order_client_list_by_status'].numberCarrier
          : '',
    },
    clientEmail: {
      name: 'clientEmail',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list_by_status'] &&
        cacheTableServer['api_sale_order_client_list_by_status'].clientEmail
          ? cacheTableServer['api_sale_order_client_list_by_status'].clientEmail
          : '',
    },
    clientName: {
      name: 'clientName',
      label: 'Client',
      textHelper: 'Rechercher par client.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_sale_order_client_list_by_status'] &&
        cacheTableServer['api_sale_order_client_list_by_status'].clientName
          ? cacheTableServer['api_sale_order_client_list_by_status'].clientName
          : '',
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_sale_order_client_list_by_status'] &&
        cacheTableServer['api_sale_order_client_list_by_status'].channel
          ? cacheTableServer['api_sale_order_client_list_by_status'].channel
          : 0,
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_sale_order_client_list_by_status'] = 0;
    cacheTableServer['api_sale_order_client_list_by_status'].page = 0;
    cacheTableServer['api_sale_order_client_list_by_status'][index] = value;
    dispatch(TableReload('api_sale_order_client_list_by_status'));
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsChannel(data);
    });

    setLoadingContent(false);
  }, []);

  React.useEffect(() => {
    dispatch(TableReload('api_sale_order_client_list_by_status'));
  }, [params]);

  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{ title: 'Liste des commandes client', context: 'Vente' }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <TextFieldComponent
                  id={'number'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('number', val)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <TextFieldComponent
                  id={'numberCarrier'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('numberCarrier', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <TextFieldComponent
                  id={'clientEmail'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientEmail', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                <TextFieldComponent
                  id={'clientName'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientName', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <SelectComponent
                  id={'channel'}
                  options={optionsChannel}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('channel', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_sale_order_client_list_by_status'}
        title={getTitle(params.orderClientState)}
        columns={columns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            numberCarrier: formSearch.numberCarrier.value ? formSearch.numberCarrier.value : '',
            clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
            clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
            channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
            orderClientState: parseInt(params.orderClientState),
          };

          ApiHandler.get(
            {
              route: 'api_sale_order_client_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].confirmedAt = data[index].confirmedAt ? moment(data[index].confirmedAt).format('lll') : '-';
                data[index].clientEmail = data[index].clientEmail ?? '-';
                data[index].clientName = data[index].clientName ?? '-';
                data[index].channel = data[index].channel ?? '-';
                data[index].carrier = data[index].carrier ?? '-';
                data[index].orderClientState = data[index].orderClientState ?? '-';
                data[index].isGuest = data[index].isGuest ? (
                  <Tooltip title={'Guest'} placement="left">
                    <FaceRetouchingOff style={{ color: '#ffc107' }} />
                  </Tooltip>
                ) : (
                  <Face style={{ color: '#28a745' }} />
                );
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default SaleOrderClientListByStatus;
