import { AirlineStopsOutlined, AppRegistration, HourglassEmpty } from '@mui/icons-material';
import { Bedtime, MoveToInbox } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { LinearProgressMainLayoutActivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import { dispatch } from '../../../../App';
import ButtonComponent from '../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import TableComponent from '../../../../Component/TableComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';

function InventoryManagementPreparerManagerPreparerList() {
  const [loading, setLoading] = React.useState(null);
  const [preparer, setPreparer] = React.useState(null);
  const [desactivatePreparer, setDesactivatePreparer] = React.useState(null);

  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'amount',
      label: 'Commande en simultanée',
      options: { filter: true, sort: true },
    },
    {
      name: 'channelText',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'carrierText',
      label: 'Transporteur',
      options: { filter: true, sort: true },
    },
    {
      name: 'printerText',
      label: 'Imprimante',
      options: { filter: true, sort: true },
    },
    {
      name: 'labelPrinterText',
      label: 'Imprimante à étiquette',
      options: { filter: true, sort: true },
    },
    {
      name: 'ordersText',
      label: 'Commande en cours',
      options: { filter: true, sort: true },
    },
    {
      name: 'isActiveText',
      label: 'Actif',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    return (
      <>
        <Tooltip title={'Modifier'} placement="left">
          <IconButton
            onClick={() => {
              handlerForm.reset();
              handlerForm.setValue('isActive', row.isActive);
              handlerForm.setValue('amount', row.amount);
              handlerForm.setValue('channel', row.channel);
              handlerForm.setValue('carriers', row.carriersId);
              handlerForm.setValue('printer', row.printer);
              handlerForm.setValue('labelPrinter', row.labelPrinter);
              setPreparer(row);
            }}
          >
            <AppRegistration style={{ color: '#17a2b8' }} />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const getDesactivateButton = (row) => {
    return (
      <>
        <Tooltip title={'Désactiver'} placement="left">
          <IconButton
            onClick={() => {
              setDesactivatePreparer(row);
            }}
          >
            <AirlineStopsOutlined style={{ color: '#FFA07A' }} />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_inventory_management_preparer_manager_preparer_edit',
          data: handlerForm.getData(),
          params: { id: preparer.id },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(TableReload('api_inventory_management_preparer_manager_preparer_list'));
            dispatch(
              SnackbarOpen({
                text: 'Modification enregistrée avec succès.',
                variant: 'success',
              }),
            );
            setPreparer(null);
          } else if (response.status === 400) {
            handlerForm.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          handlerForm.setFormLoading(false);
          setLoading(false);
        },
      );
    }
  };

  const edit = () => {
    setLoading(true);
    ApiHandler.post(
      {
        route: 'api_inventory_management_preparer_manager_preparer_activity_inactive',
        data: {},
        params: { id: desactivatePreparer.lastActivityId },
      },
      (response) => {
        if (response.status === 200) {
          dispatch(TableReload('api_inventory_management_preparer_manager_preparer_list'));
          dispatch(
            SnackbarOpen({
              text: 'Modification enregistrée avec succès.',
              variant: 'success',
            }),
          );
          setDesactivatePreparer(null);
        } else if (response.status === 400) {
          handlerForm.setErrorApi(response.error);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
        setLoading(false);
      },
    );
  };

  const [form, setForm] = React.useState({
    isActive: {
      name: 'isActive',
      label: 'Actif',
      type: 'boolean',
      defaultValue: true,
    },
    amount: {
      name: 'amount',
      label: 'Quantité commande simultanée',
      textHelper: 'Saisissez la quantité de commande simultanée.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Choisissez un canal.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    carriers: {
      name: 'carriers',
      label: 'Transporteurs',
      textHelper: 'Choisissez un transporteur.',
      type: 'array',
      defaultValue: '',
    },
    printer: {
      name: 'printer',
      label: 'Imprimante',
      textHelper: 'Choisissez une imprimante.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    labelPrinter: {
      name: 'labelPrinter',
      label: 'Imprimante à étiquette',
      textHelper: 'Choisissez une imprimante à étiquette.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const [optionsCarrier, setOptionsCarrier] = React.useState([]);
  const [optionsPrinter, setOptionsPrinter] = React.useState([]);
  const [optionsLabelPrinter, setOptionsLabelPrinter] = React.useState([]);
  const [optionsChannel, setOptionsChannel] = React.useState([]);
  const handlerForm = initFormHandler(form, setForm);

  React.useEffect(() => {
    ApiHandler.get({ route: 'api_select_carrier' }, (response) => setOptionsCarrier(response.data));
    ApiHandler.get({ route: 'api_select_printer' }, (response) => setOptionsPrinter(response.data));
    ApiHandler.get({ route: 'api_select_label_printer' }, (response) => setOptionsLabelPrinter(response.data));
    ApiHandler.get({ route: 'api_select_channel' }, (response) => setOptionsChannel(response.data));

    setInterval(() => dispatch(TableReload('api_inventory_management_preparer_manager_preparer_list')), 20000);
  }, []);

  return (
    <>
      <TableComponent
        hideLoading={true}
        id={'api_inventory_management_preparer_manager_preparer_list'}
        title={'Préparateurs'}
        columns={columns}
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_inventory_management_preparer_manager_preparer_list' }, (response) => {
            const data = response.data;
            for (const index in data) {
              if (data[index].isActive) data[index].action = getDesactivateButton(data[index]);
              else data[index].action = getAction(data[index]);

              data[index].amount = data[index].amount ?? '-';
              data[index].ordersText =
                data[index].orders && data[index].orders.length > 0
                  ? data[index].orders.map((order) => <div key={`${index}-${order.number}`}>{order.number}</div>)
                  : '-';

              data[index].carrierText =
                data[index].carriersLabel && data[index].carriersLabel.length > 0
                  ? data[index].carriersLabel.map((carrierLabel) => (
                      <div key={`${index}-${carrierLabel}`}>{carrierLabel}</div>
                    ))
                  : '-';

              if (!data[index].isActive) data[index].isActiveText = <Bedtime style={{ color: '#000000' }} />;
              else if (!data[index].orders || data[index].orders.length < 1)
                data[index].isActiveText = <HourglassEmpty style={{ color: '#17a2b8' }} />;
              else data[index].isActiveText = <MoveToInbox style={{ color: '#28a745' }} />;
            }
            resolve(data);
          });
        }}
      />

      <Dialog open={!!desactivatePreparer} maxWidth={'xl'} onClose={() => setDesactivatePreparer(null)}>
        <DialogTitle style={{ fontSize: 15 }}>{desactivatePreparer ? desactivatePreparer.name : ''}</DialogTitle>
        <DialogContent style={{ minWidth: '40vw' }}></DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setDesactivatePreparer(null)}
            disabled={loading}
          />
          <ButtonComponent label={'Désactiver'} onClick={edit} loading={loading} />
        </DialogActions>
      </Dialog>

      <Dialog open={!!preparer} maxWidth={'xl'} onClose={() => setPreparer(null)}>
        <DialogTitle style={{ fontSize: 15 }}>{preparer ? preparer.name : ''}</DialogTitle>
        <DialogContent style={{ minWidth: '40vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={2}>
              <CheckboxComponent id={'isActive'} handler={handlerForm} orientation={'right'} />
            </Grid>
            <Grid item xs={8} sm={2}>
              <TextFieldComponent id={'amount'} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectComponent id={'printer'} options={optionsPrinter} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectComponent id={'labelPrinter'} options={optionsLabelPrinter} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectComponent id={'channel'} options={optionsChannel} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultipleSelectComponent id={'carriers'} options={optionsCarrier} handler={handlerForm} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setPreparer(null)} disabled={loading} />
          <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading} />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InventoryManagementPreparerManagerPreparerList;
