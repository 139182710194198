import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function ClosingShopList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

  // List Deposits
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'shop',
      label: 'Boutique',
      options: { filter: true, sort: true },
    },
    {
      name: 'closedAt',
      label: 'Date fermeture',
      options: { filter: true, sort: true },
    },
    {
      name: 'user',
      label: 'Utilisateur',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') ||
      hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_CLOSING')
    ) {
      return (
        <Tooltip title={'Modifier'} placement="left">
          <Link to={getRoutePathname('shop_management_shop_closing', { id: row.id })}>
            <IconButton>
              <Edit style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des clotures de caisse', context: 'Gestion boutique' }}>
      <TableComponent
        id={'api_shop_management_shop_closing_list'}
        title={'Fermetures de caisses'}
        columns={columns}
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_shop_management_closing_list' }, (response) => {
            const data = response.data;
            for (const index in data) {
              data[index].action = getAction(data[index]);
              data[index].id = data[index].id ? data[index].id : '-';
              data[index].shop = data[index].shop ? data[index].shop : '-';
              data[index].user = data[index].user ? data[index].user : '-';
            }
            resolve(data);
          });
        }}
      />
    </ContentViewComponent>
  );
}

export default ClosingShopList;
