import { Article, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { hasRight } from '../../../../Common/UserCommon';
import OrderPurveyorListComponent from '../../../../Component/OrderPurveyorListComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';
import { isNumber } from '../../../../Util/NumberTool';

function BuyPurveyorOrderList(props) {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

  const getAction = (row) => {
    return (
      <>
        {hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR') ||
        hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_EDIT') ? (
          <Tooltip title={'Modifier'} placement="left">
            <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
              <IconButton>
                <Edit style={{ color: '#17a2b8' }} />
              </IconButton>
            </Link>
          </Tooltip>
        ) : hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_GET') ? (
          <Tooltip title={'Visualiser'} placement="left">
            <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
              <IconButton>
                <Article style={{ color: '#28a745' }} />
              </IconButton>
            </Link>
          </Tooltip>
        ) : (
          <IconButton disabled={true}>
            <Edit style={{ color: '#17a2b8' }} />
          </IconButton>
        )}
      </>
    );
  };

  const promiseData = (resolve) => {
    ApiHandler.get(
      {
        route: 'api_buy_purveyor_by_purveyor_order_list',
        params: { id: props.purveyor.id },
      },
      (response) => {
        const data = response.data;
        for (const index in data) {
          data[index].action = getAction(data[index]);
          data[index].createdAt = moment(data[index].createdAt).format('L');
          data[index].deliveryAt = (
            <span
              style={
                !data[index].deliveryAt || (moment(data[index].deliveryAt).isBefore(moment()) && data[index].state <= 2)
                  ? { color: '#982525' }
                  : {}
              }
            >
              {moment(data[index].deliveryAt).format('L')}
            </span>
          );
          data[index].amountTotal = isNumber(data[index].amountTotal)
            ? parseFloat(data[index].amountTotal).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
            : '-';
        }
        resolve(data);
      },
    );
  };

  return (
    <OrderPurveyorListComponent
      promiseData={promiseData}
      displayActions={true}
      displayDeposit={true}
      tableId="api_buy_purveyor_by_purveyor_order_list"
    />
  );
}

export default BuyPurveyorOrderList;
