import { LinearProgress as LinearProgressMUI } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '#app/Store';

const LinearProgress: React.FC = () => {
  const classes = useStyles();
  const [isSmallView, setSmallView] = React.useState(false);

  const linearProgressMainLayoutReducer = useSelector((state: StateType) => state.LinearProgressMainLayoutReducer);
  const screenReducer = useSelector((state: StateType) => state.ScreenReducer);

  useEffect(() => {
    setSmallView(screenReducer.screen === 'XS' || screenReducer.screen === 'SM');
  }, [screenReducer.screen]);

  return linearProgressMainLayoutReducer.isActivated ? (
    <LinearProgressMUI className={`${classes.linearProgress} ${isSmallView ? classes.linearProgressSmall : ''}`} />
  ) : null;
};

const useStyles = makeStyles({
  linearProgress: {
    position: 'absolute !important' as Property.Position,
    top: 58,
    width: '100vw',
    height: '2px !important',
    backgroundColor: 'rgba(87,165,133,0.25) !important',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#57A585',
    },
    '& .MuiLinearProgress-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  linearProgressSmall: {
    top: 56,
  },
});

export default LinearProgress;
