type LinearProgressMainLayoutActivateAction = {
  type: 'LinearProgressMainLayoutActivate';
};

export const LinearProgressMainLayoutActivate = (): LinearProgressMainLayoutActivateAction => ({
  type: 'LinearProgressMainLayoutActivate',
});

type LinearProgressMainLayoutDeactivateAction = {
  type: 'LinearProgressMainLayoutDeactivate';
};

export const LinearProgressMainLayoutDeactivate = (): LinearProgressMainLayoutDeactivateAction => ({
  type: 'LinearProgressMainLayoutDeactivate',
});
