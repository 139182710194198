import { Article } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { listingStyles } from '../../../Style/useStyles';
import ShopCreateClientForm from './ShopSaleComponent/ShopCreateClientForm';

function SelectClient() {
  const navigate = useNavigate();
  const [loadingContent, setLoadingContent] = React.useState(true);
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [shop, setShop] = React.useState(null);
  const classes = listingStyles();
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'firstname',
      label: 'Prénom',
      options: { filter: true, sort: true },
    },
    {
      name: 'lastname',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'zipCode',
      label: 'Code postal',
      options: { filter: true, sort: true },
    },
    {
      name: 'phone',
      label: 'Téléphone',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par références.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].number
          ? cacheTableServer['api_shop_sales_client_list'].number
          : '',
    },
    email: {
      name: 'email',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].email
          ? cacheTableServer['api_shop_sales_client_list'].email
          : '',
    },
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      textHelper: 'Rechercher par prénom.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].firstname
          ? cacheTableServer['api_shop_sales_client_list'].firstname
          : '',
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      textHelper: 'Rechercher par nom.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].lastname
          ? cacheTableServer['api_shop_sales_client_list'].lastname
          : '',
    },
    zipCode: {
      name: 'zipCode',
      label: 'Code postal',
      textHelper: 'Rechercher par code postal.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].zipCode
          ? cacheTableServer['api_shop_sales_client_list'].zipCode
          : '',
    },
    phone: {
      name: 'phone',
      label: 'Téléphone',
      textHelper: 'Rechercher par téléphone.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_sales_client_list'] && cacheTableServer['api_shop_sales_client_list'].zipCode
          ? cacheTableServer['api_shop_sales_client_list'].zipCode
          : '',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const getAction = (row) => {
    return (
      <>
        <Tooltip title={'Choisir'} placement="left">
          <Link
            to={getRoutePathname('shop_sales_client_edit', {
              id: row.uuid,
              shopId: authenticationReducer.shopSelected,
            })}
          >
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  };

  const onSearch = () => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    dispatch(LinearProgressMainLayoutActivate());

    const filters = {
      number: formSearch.number.value ? formSearch.number.value : '',
      email: formSearch.email.value ? formSearch.email.value : '',
      firstname: formSearch.firstname.value ? formSearch.firstname.value : '',
      lastname: formSearch.lastname.value ? formSearch.lastname.value : '',
      phone: formSearch.phone.value ? formSearch.phone.value : '',
      zipCode: formSearch.zipCode.value ? formSearch.zipCode.value : '',
    };

    ApiHandler.get(
      {
        route: 'api_shop_sales_client_list',
        params: { shopId: authenticationReducer.shopSelected },
        data: filters,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);

          if (response.data.error !== '') {
            dispatch(
              SnackbarOpen({
                text: response.data.error,
                variant: 'error',
              }),
            );
          } else {
            dispatch(LinearProgressMainLayoutDeactivate());
            setTimeout(function () {
              dispatch(TableReload('api_shop_sales_client_list'));
            }, 2000);
          }
        } else {
          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);
          dispatch(LinearProgressMainLayoutDeactivate());
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };

  React.useEffect(() => {
    ApiHandler.post(
      {
        route: 'api_shop_sales_check_opening',
        params: { shopId: authenticationReducer.shopSelected },
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.open === 0) {
            dispatch(
              SnackbarOpen({
                text: 'Vous devez ouvrir votre caisse pour accéder à cet onglet.',
                variant: 'error',
              }),
            );
            navigate(getRoutePathname('shop'));
          }
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );

    handlerFormSearch.start();

    setLoadingContent(false);

    ApiHandler.get(
      {
        route: 'api_shop_sales_shop_info',
        params: { id: authenticationReducer.shopSelected },
      },
      (response) => {
        if (response.status === 200) {
          setShop(response.data);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  }, []);

  const openShop = () => {
    const path = `shop`;
    navigate(path);
  };

  return (
    <ContentViewShopComponent loading={loadingContent} breadcrumbs={{ title: 'Liste des clients', context: 'Vente' }}>
      {shop && (
        <>
          {(() => {
            if (shop.isOpen === 0) {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p>Pour saisir une vente, vous devez ouvrir la caisse de votre boutique.</p>
                    <ButtonComponent label={'Ouvrir la caisse'} onClick={openShop} />
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShopCreateClientForm shopId={authenticationReducer.shopSelected} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                      <TitleComponent title={'Filtres'} />

                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'number'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'email'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'firstname'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'lastname'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'zipCode'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                          <TextFieldComponent id={'phone'} handler={handlerFormSearch} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <ButtonComponent
                            className={classes.buttonActionFirst}
                            label={'Rechercher'}
                            onClick={() => onSearch()}
                            disabled={disabledStatusButtonSearch}
                            loading={loadingContent}
                          />
                        </Grid>
                      </Grid>
                    </ShadowBoxComponent>

                    <br />

                    <TableComponent
                      id={'api_shop_sales_client_list'}
                      title={'Clients'}
                      columns={columns}
                      search={false}
                      promiseServerData={(resolve) => {
                        const filters = {
                          number: formSearch.number.value ? formSearch.number.value : '',
                          email: formSearch.email.value ? formSearch.email.value : '',
                          firstname: formSearch.firstname.value ? formSearch.firstname.value : '',
                          lastname: formSearch.lastname.value ? formSearch.lastname.value : '',
                          phone: formSearch.phone.value ? formSearch.phone.value : '',
                          zipCode: formSearch.zipCode.value ? formSearch.zipCode.value : '',
                        };

                        ApiHandler.get(
                          {
                            route: 'api_shop_sales_client_list',
                            params: { shopId: authenticationReducer.shopSelected },
                            data: filters,
                          },
                          (response) => {
                            const data = response.data?.data ?? [];

                            for (const index in data) {
                              data[index].action = getAction(data[index]);
                              data[index].createdAt = moment(data[index].createdAt).format('lll');
                              data[index].firstname = data[index].firstname ? data[index].firstname : '-';
                              data[index].lastname = data[index].lastname ? data[index].lastname : '-';
                              data[index].zipCode = data[index].zipCode ? data[index].zipCode : '-';
                              data[index].number = data[index].number ? data[index].number : '-';
                              data[index].phone = data[index].phone ? data[index].phone : '-';
                            }
                            resolve(data, response.data?.count ?? 0);
                          },
                        );
                      }}
                    />
                    <br />
                    <br />
                  </Grid>
                </Grid>
              );
            }
          })()}
        </>
      )}
    </ContentViewShopComponent>
  );
}

export default SelectClient;
