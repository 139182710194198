import { Edit, Article } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function InventoryManagementDepositList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [optionsOwner, setOptionsOwner] = React.useState([]);

  // List Deposits
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'owner',
      label: 'Affiliation',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    name: {
      name: 'name',
      label: 'Nom du dépôt',
      textHelper: 'Rechercher par nom de dépôt.',
      type: 'text',
      defaultValue:
        cacheTableServer.api_inventory_management_deposit_list &&
        cacheTableServer.api_inventory_management_deposit_list.name
          ? cacheTableServer.api_inventory_management_deposit_list.name
          : '',
    },
    owner: {
      name: 'owner',
      label: 'Affiliation',
      textHelper: 'Rechercher par affiliations.',
      type: 'integer',
      defaultValue:
        cacheTableServer.api_inventory_management_deposit_list &&
        cacheTableServer.api_inventory_management_deposit_list.owner
          ? cacheTableServer.api_inventory_management_deposit_list.owner
          : 0,
    },
  });
  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') ||
      hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_GET')
    ) {
      return (
        <>
          {hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') ||
          hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_EDIT') ? (
            <Tooltip title={'Modifier'} placement="left">
              <Link to={getRoutePathname('inventory_management_deposit', { id: row.id })}>
                <IconButton>
                  <Edit style={{ color: '#17a2b8' }} />
                </IconButton>
              </Link>
            </Tooltip>
          ) : hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_GET') ? (
            <Tooltip title={'Visualiser'} placement="left">
              <Link to={getRoutePathname('inventory_management_deposit', { id: row.id })}>
                <IconButton>
                  <Article style={{ color: '#28a745' }} />
                </IconButton>
              </Link>
            </Tooltip>
          ) : (
            <IconButton disabled={true}>
              <Edit style={{ color: '#17a2b8' }} />
            </IconButton>
          )}
        </>
      );
    } else {
      return (
        <>
          {hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_EDIT') ? (
            <IconButton style={{ cursor: 'no-drop' }}>
              <Edit />
            </IconButton>
          ) : (
            <IconButton style={{ cursor: 'no-drop' }}>
              <Article />
            </IconButton>
          )}
        </>
      );
    }
  };

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage.api_inventory_management_deposit_list = 0;
    cacheTableServer.api_inventory_management_deposit_list.page = 0;
    cacheTableServer.api_inventory_management_deposit_list[index] = value;
    dispatch(TableReload('api_inventory_management_deposit_list'));
  };

  React.useEffect(() => {
    handlerFormSearch.start();
    // get options deposit
    ApiHandler.get({ route: 'api_select_purveyor' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsOwner(data);
    });
  }, []);

  return (
    <ContentViewComponent
      breadcrumbs={{
        title: 'Liste des dépôts',
        context: 'Gestion des stocks',
      }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextFieldComponent
                  id={'name'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('name', val)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectComponent
                  id={'owner'}
                  options={optionsOwner}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('owner', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />
      <TableComponent
        id={'api_inventory_management_deposit_list'}
        title={'Dépôts'}
        columns={columns}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') ||
          hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_ADD')
            ? {
                label: 'Ajouter un dépôt',
                link: getRoutePathname('inventory_management_deposit', { id: 'add' }),
              }
            : false
        }
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            name: formSearch.name.value ? formSearch.name.value : '',
            owner: datas.owner === 0 || !datas.owner ? '' : datas.owner,
          };
          ApiHandler.get(
            {
              route: 'api_inventory_management_deposit_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].owner = data[index].owner ? `Fournisseur : ${data[index].owner}` : 'Aucune';
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default InventoryManagementDepositList;
