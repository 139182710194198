import { Print } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function AdministratorLogPrinterList() {
  const [log, setLog] = React.useState(null);
  const [loadingContent, setLoadingContent] = React.useState(true);
  const [optionsIsSuccess] = React.useState([
    { value: 'all', label: 'Tous' },
    { value: 'true', label: 'Succès' },
    { value: 'false', label: 'Échec' },
  ]);
  const [optionsLabelPrinter, setOptionsLabelPrinter] = React.useState([]);
  const [optionsPrinter, setOptionsPrinter] = React.useState([]);
  const moveColumns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'message',
      label: 'Message',
      options: { filter: true, sort: true },
    },
    {
      name: 'path',
      label: 'Chemin',
      options: { filter: true, sort: true },
    },
    {
      name: 'ip',
      label: 'Adresse IP',
      options: { filter: true, sort: true },
    },
    {
      name: 'port',
      label: 'Port',
      options: { filter: true, sort: true },
    },
    {
      name: 'isSuccess',
      label: 'État',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    message: {
      name: 'message',
      label: 'Message',
      textHelper: 'Rechercher par messages.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_printer_list'] &&
        cacheTableServer['api_administrator_log_printer_list'].message
          ? cacheTableServer['api_administrator_log_printer_list'].message
          : '',
    },
    isSuccess: {
      name: 'isSuccess',
      label: 'État',
      textHelper: 'Rechercher par état.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_printer_list'] &&
        cacheTableServer['api_administrator_log_printer_list'].isSuccess
          ? cacheTableServer['api_administrator_log_printer_list'].isSuccess
          : 'all',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_administrator_log_printer_list'] = 0;
    cacheTableServer['api_administrator_log_printer_list'].page = 0;
    cacheTableServer['api_administrator_log_printer_list'][index] = value;
    dispatch(TableReload('api_administrator_log_printer_list'));
  };
  const getAction = (row) => (
    <Tooltip title={"Réimprimer l'étiquette"} placement="left">
      <IconButton
        onClick={() => {
          setLog(row.id);
          handlerForm.reset();
        }}
      >
        <Print style={{ color: '#17a2b8' }} />
      </IconButton>
    </Tooltip>
  );

  // Form
  const [form, setForm] = React.useState({
    labelPrinter: {
      name: 'labelPrinter',
      label: 'Imprimante à étiquette',
      textHelper: 'Choisissez une imprimante à étiquette.',
      type: 'integer',
    },
    printer: {
      name: 'printer',
      label: 'Imprimante',
      textHelper: 'Choisissez une imprimante.',
      type: 'integer',
    },
  });
  const handlerForm = initFormHandler(form, setForm);

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_label_printer' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsLabelPrinter(data);
    });
    ApiHandler.get({ route: 'api_select_printer' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsPrinter(data);
    });

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{
        title: 'Liste des Log - Imprimantes',
        context: 'Administrateur',
      }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextFieldComponent
                  id={'message'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('message', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectComponent
                  id={'isSuccess'}
                  options={optionsIsSuccess}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('isSuccess', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_administrator_log_printer_list'}
        title={'Log Imprimante'}
        columns={moveColumns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            message: formSearch.message.value ? formSearch.message.value : '',
            isSuccess: datas.isSuccess === 'all' ? '' : datas.isSuccess === 'true',
          };

          ApiHandler.get(
            {
              route: 'api_administrator_log_printer_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].isSuccess = data[index].isSuccess ? (
                  <span style={{ color: '#28a745' }}>Succès</span>
                ) : (
                  <span style={{ color: '#982525' }}>Échec</span>
                );
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].action = getAction(data[index]);
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />

      <br />
      <br />

      <Dialog open={!!log} maxWidth={'xl'} onClose={() => setLog(null)}>
        <DialogTitle style={{ fontSize: 15 }}>Choix de l'imprimante à étiquette</DialogTitle>
        <DialogContent style={{ minWidth: '40vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <SelectComponent id={'labelPrinter'} options={optionsLabelPrinter} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
              Ou
            </Grid>
            <Grid item xs={12} md={5}>
              <SelectComponent id={'printer'} options={optionsPrinter} handler={handlerForm} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setLog(null)} />
          <ButtonComponent
            label={'Enregistrer'}
            onClick={() => {
              dispatch(LinearProgressMainLayoutActivate());
              dispatch(
                SnackbarOpen({
                  text: 'Impression en cours...',
                  variant: 'info',
                }),
              );
              setLog(null);

              ApiHandler.post(
                {
                  route: 'api_administrator_log_printer_printing',
                  params: { id: log },
                  data: {
                    labelPrinter: handlerForm.getData()['labelPrinter'],
                    printer: handlerForm.getData()['printer'],
                  },
                },
                () => {
                  dispatch(TableReload('api_administrator_log_printer_list'));
                  dispatch(LinearProgressMainLayoutDeactivate());
                  dispatch(
                    SnackbarOpen({
                      text: 'Impression terminée',
                      variant: 'success',
                    }),
                  );
                },
              );
            }}
          />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

export default AdministratorLogPrinterList;
