import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StateType } from '#app/Store';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { dispatch } from '../../../App';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import ShopCreateClientForm from './ShopSaleComponent/ShopCreateClientForm';

type Client = {
  [key: string]: any;
};

const EditClient: React.FC = () => {
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const [client, setClient] = useState<Client>();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    setLoadingContent(false);

    ApiHandler.get(
      {
        route: 'api_shop_sales_client_info',
        params: { id: params.id as string, shopId: authenticationReducer.shopSelected as string },
      },
      (response) => {
        if (response.status === 200) {
          setClient(response.data.client);
        } else {
          dispatch(
            SnackbarOpen({
              text: response.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  }, []);

  return (
    <ContentViewShopComponent loading={loadingContent}>
      {client && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ShopCreateClientForm shopId={authenticationReducer.shopSelected} client={client} />
          </Grid>
        </Grid>
      )}
    </ContentViewShopComponent>
  );
};

export default EditClient;
