import { Select, InputLabel, FormControl, FormHelperText, MenuItem } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

export type SelectOption = {
  value: string | number | null;
  label: string;
  customClass?: string;
};

type Props = {
  id: string;
  name?: string;
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: string | number) => void;
    getErrorByField: (input: any) => string;
    setError: (id: string, error: string) => void;
  };
  options: SelectOption[];
  onChange?: (value: string | number) => void;
  onBlur?: (value: string | number) => void;
  disabled?: boolean;
};

const SelectComponent: React.FC<Props> = ({ id, name, handler, options, onChange, onBlur, disabled }) => {
  const input = handler.form[id];
  const label = input.label;

  const classes = useStyles();
  const [error, setError] = useState<string>(input.error);
  const [isFocus, setFocus] = useState<boolean>(false);

  const [value, setValue] = useState<string | number>('');
  useEffect(() => {
    if (input.value !== undefined && input.value !== value) {
      setValue(input.value.toString());
    }
  }, [input.value]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  const handleChange: SelectInputProps['onChange'] = (event) => {
    const newValue = (event.target.value as string | number) ?? '';
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (): void => {
    setFocus(false);
    handler.setValue(id, value);
    const validationError = handler.getErrorByField(input);
    setError(validationError);
    handler.setError(id, validationError);

    if (onBlur) {
      onBlur(value);
    }
  };

  const handleFocus = (): void => {
    setFocus(true);
  };

  return (
    <div className={classes.content}>
      <FormControl
        variant="outlined"
        className={`
          ${classes.select}
          ${isFocus && !input.disabled && !disabled ? classes.selectFocus : ''}
          ${error && !input.disabled && !disabled ? classes.selectError : ''}
          ${value && !input.disabled && !disabled ? classes.selectValid : ''}
        `}
      >
        <InputLabel htmlFor={id} style={value ? { transform: 'translate(15px, -4px) scale(0.75)' } : {}}>
          {label + (input.options?.validation?.includes('required') ? ' *' : '')}
        </InputLabel>
        <Select
          value={value}
          defaultValue={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label={label + (input.options?.validation?.includes('required') ? ' *' : '')}
          inputProps={{
            name: label + (input.options?.validation?.includes('required') ? ' *' : ''),
            id: name,
          }}
          disabled={input.disabled || disabled}
          MenuProps={{
            className: classes.menu,
          }}
        >
          {options.map((data, key) => (
            <MenuItem key={key} className={data.customClass ?? ''} value={data.value as string}>
              {data.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error && !isFocus ? error : input.textHelper}</FormHelperText>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
  },
  select: {
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiSelect-select:focus': {
      background: '#FFFFFF',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -7px) scale(0.75) !important',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      height: 9,
      lineHeight: '9px',
    },
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54) !important',
      backgroundColor: '#FFFFFF',
      padding: '0 2px',
    },
  },
  selectFocus: {
    '& fieldset': {
      borderColor: '#3F51BF !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#3F51BF !important',
    },
    '& label': {
      color: '#3F51BF !important',
    },
  },
  selectValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500 !important',
    },
    '& label': {
      color: '#006500 !important',
    },
  },
  selectError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525 !important',
    },
    '& label': {
      color: '#982525 !important',
    },
  },
  menu: {
    '& li': {
      fontSize: 12,
      padding: '4px 10px',
      lineHeight: '16px',
      minHeight: '16px',
    },
  },
});

export default SelectComponent;
