import { DeleteOutline } from '@mui/icons-material';
import { Article } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

import { LinearProgressMainLayoutActivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewShopComponent from '../../../Component/ContentViewShopComponent';
import SelectComponent from '../../../Component/SelectComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { isNumber } from '../../../Util/NumberTool';

function SelectProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [reload, setReload] = React.useState(false);
  const [orderClientId, setOrderClientId] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const [byProducts, setByProducts] = React.useState([]);
  const [paymentInProgress, setPaymentInProgress] = React.useState(false);
  const optionsPaymentType = [
    { value: 'byCard', label: 'Carte bancaire', customAbbreviation: 'CB' },
    { value: 'byCash', label: 'Espèces', customAbbreviation: 'E' },
    { value: 'avoirClient', label: 'Avoir', customAbbreviation: 'E' },
  ];

  const [beep] = useSound('/mp3/beep.mp3');
  const [boop] = useSound('/mp3/boop.mp3');

  // LIST
  const columns = [
    {
      name: 'sku',
      label: 'SKU',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'flavor',
      label: 'Parfum / Couleur',
      options: { filter: true, sort: true },
    },
    {
      name: 'mark',
      label: 'Marque',
      options: { filter: true, sort: true },
    },
    {
      name: 'packSize',
      label: 'Taille du conditionnement',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  const columnsEncaissement = [
    {
      name: 'methodeText',
      label: 'Méthode',
      options: { filter: false, sort: false },
    },
    {
      name: 'montantCashText',
      label: 'Montant Espèces',
      options: { filter: false, sort: false },
    },
    {
      name: 'montantCardText',
      label: 'Montant CB',
      options: { filter: false, sort: false },
    },
    {
      name: 'montantAvoirText',
      label: 'Montant Avoir',
      options: { filter: false, sort: false },
    },
    {
      name: 'actionPayment',
      label: 'Actions',
      options: { filter: false, sort: false },
    },
  ];

  const columnsOffered = [
    {
      name: 'name',
      label: 'Nom',
      options: { filter: false, sort: false },
    },
    {
      name: 'action',
      label: 'action',
      options: { filter: false, sort: false },
    },
  ];

  const [formPayment, setFormPayment] = React.useState({
    montant: {
      name: 'montant',
      label: 'Montant encaissé',
      textHelper: 'Saisissez le montant encaissé',
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    type: {
      name: 'type',
      label: 'Type',
      textHelper: 'Choisissez le moyen de paiement.',
      type: 'array',
      defaultValue: '',
    },
  });
  const handlerFormPayment = initFormHandler(formPayment, setFormPayment);

  const [formPac, setFormPac] = React.useState({
    codePac: {
      name: 'codePac',
      label: 'Code promo',
      textHelper: 'Saisissez le codo promo',
      type: 'text',
      options: { validation: ['required'] },
    },
  });
  const handlerFormPac = initFormHandler(formPac, setFormPac);

  const [formFlashProduct, setFlashProduct] = React.useState({
    flashcode: {
      name: 'flashcode',
      label: 'Scanner le produit',
      textHelper: 'Saisissez le code ean',
      type: 'text',
      defaultValue: null,
    },
  });
  const handlerFormFlashProduct = initFormHandler(formFlashProduct, setFlashProduct);

  const [formCloseOrderClient, setFormCloseOrderClient] = React.useState({
    idSeller: {
      name: 'idSeller',
      label: 'Numéro vendeur',
      textHelper: 'Saisissez votre numéro de vendeur',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const handlerFormCloseOrderClient = initFormHandler(formCloseOrderClient, setFormCloseOrderClient);

  const savePaymentForm = () => {
    if (handlerFormPayment.checkError() < 1 && orderClientId != null) {
      handlerFormPayment.setFormLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      setPaymentInProgress(true);

      ApiHandler.post(
        {
          route: 'api_shop_sales_add_payment',
          data: handlerFormPayment.getData(),
          params: { orderClientId: orderClientId },
        },
        (response) => {
          if (response.status === 200) {
            setReload(reload);
            setOrder(response.data);

            dispatch(TableReload('table_api_shop_sales_get_payment'));
            dispatch(TableReload('totalOrderbloc'));

            dispatch(
              SnackbarOpen({
                text: 'Modification enregistrée avec succès.',
                variant: 'success',
              }),
            );
          } else if (response.status === 400) {
            handlerFormPayment.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          dispatch(TableReload('table_api_shop_sales_get_payment'));
          handlerFormPayment.setFormLoading(false);
          handlerFormPayment.reset();
          setPaymentInProgress(false);
        },
      );
    }
  };

  const savePacForm = () => {
    if (handlerFormPac.checkError() < 1 && orderClientId != null) {
      handlerFormPac.setFormLoading(true);
      dispatch(LinearProgressMainLayoutActivate());
      ApiHandler.post(
        {
          route: 'api_shop_sales_check_promo',
          data: handlerFormPac.getData(),
          params: { orderClientId: orderClientId },
        },
        (response) => {
          if (response.status === 200) {
            setReload(reload);

            setOrder(response.data);
            setByProducts(response.data.byProducts);

            dispatch(TableReload('byproductsList'));
            dispatch(TableReload('totalOrderbloc'));
            dispatch(
              SnackbarOpen({
                text: 'Code promo appliqué avec succès.',
                variant: 'success',
              }),
            );

            setTimeout(function () {
              removePayment();
            }, 2000);
          } else if (response.status === 400) {
            handlerFormPac.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          handlerFormPac.setFormLoading(false);
        },
      );
    }
  };

  const saveCloseOrderClientForm = () => {
    if (handlerFormCloseOrderClient.checkError() < 1 && orderClientId != null) {
      handlerFormCloseOrderClient.setFormLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_order_client_close',
          data: handlerFormCloseOrderClient.getData(),
          params: { orderClientId: orderClientId },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'La vente a été enregistrée avec succès.',
                variant: 'success',
              }),
            );
            navigate(getRoutePathname('shop_sales_list_today', { shopId: params.shopId }));
          } else if (response.status === 400) {
            handlerFormCloseOrderClient.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          handlerFormCloseOrderClient.setFormLoading(false);
        },
      );
    }
  };

  React.useEffect(() => {
    if (params.clientId && params.clientId !== '') {
      ApiHandler.post(
        {
          route: 'api_shop_sales_create_order_shop',
          params: { shopId: params.shopId, id: params.clientId },
        },
        (response) => {
          if (response.status === 200) {
            params.orderClientId = response.data.uuid;
            setOrderClientId(response.data.uuid);
            setOrder(response.data);
            dispatch(TableReload('byproductsList'));
            dispatch(TableReload('totalOrderbloc'));
            dispatch(TableReload('table_api_shop_sales_get_payment'));
            loadByProducts(response.data.uuid);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
        },
      );
    }
  }, [reload, params.clientId]);

  const loadByProducts = (orderClientId) => {
    if (orderClientId != null) {
      ApiHandler.post(
        {
          route: 'api_shop_sales_select_product',
          params: { shopId: authenticationReducer.shopSelected, id: orderClientId },
        },
        (response) => {
          if (response.status === 200) {
            params.order = response.data;
            params.clientId = response.data.clientId;
            params.orderClientId = response.data.orderClientId;
            params.clientName = response.data.clientName;
            params.clientSolde = response.data.clientSolde;
            params.clientEmail = response.data.clientEmail;
            params.email = response.data.email;
            params.priceTTC = response.data.priceTTC;
            params.totalCash = response.data.totalCash;
            params.totalCB = response.data.totalCB;
            params.totalAvoir = response.data.totalAvoir;
            params.byProducts = response.data.byProducts;

            setOrder(response.data);
            setByProducts(response.data.byProducts);
            setReload(reload);

            dispatch(TableReload('totalOrderbloc'));
          }
        },
      );
    }
  };

  const saveAdd = (productId, free) => {
    if (orderClientId != null) {
      ApiHandler.post(
        {
          route: 'api_shop_sales_add_product',
          params: {
            productId: productId,
            shopId: authenticationReducer.shopSelected,
            orderClientId: orderClientId,
            free: free,
          },
        },
        (response) => {
          if (response.status === 200) {
            setReload(reload);
            loadByProducts(response.data.orderClientId);
            dispatch(TableReload('totalOrderbloc'));

            if (response.data.codePac != null) {
              const pacLabel = response.data.codePac;
              setTimeout(function () {
                removePac(response.data.orderClientId);
              }, 1000);

              setTimeout(function () {
                handlerFormPac.setValue('codePac', pacLabel);
                savePacForm();
              }, 2000);
            }
            setTimeout(function () {
              removePayment();
              dispatch(TableReload('table_api_shop_sales_get_payment'));
            }, 2000);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
            boop();
          }
        },
      );
    }
  };

  const removePayment = () => {
    if (orderClientId != null) {
      ApiHandler.post(
        {
          route: 'api_shop_sales_reset_payment',
          params: { orderClientId: orderClientId },
        },
        (response) => {
          setReload(reload);
          setOrder(response.data);
          dispatch(TableReload('table_api_shop_sales_get_payment'));
        },
      );
    }
  };

  const saveRemove = (productId) => {
    if (orderClientId != null) {
      ApiHandler.post(
        {
          route: 'api_shop_sales_remove_product',
          params: { id: productId },
        },
        (response) => {
          setReload(reload);
          setOrder(response.data);

          dispatch(TableReload('totalOrderbloc'));
          dispatch(TableReload('table_api_shop_sales_get_payment'));
          loadByProducts(response.data.orderClientId);

          if (order && order.codePac != null) {
            const pacLabel = order.codePac;
            setTimeout(function () {
              removePac(response.data.orderClientId);
            }, 1000);

            setTimeout(function () {
              handlerFormPac.setValue('codePac', pacLabel);
              savePacForm();
            }, 2000);
          }

          setTimeout(function () {
            removePayment();
          }, 2000);
        },
      );
    }
  };

  const removePac = (orderID) => {
    if (orderClientId != null) {
      ApiHandler.post(
        {
          route: 'api_shop_sales_remove_pac',
          params: { orderID: orderID },
        },
        (response) => {
          setReload(reload);
          setOrder(response.data);
          dispatch(TableReload('totalOrderbloc'));
          loadByProducts(response.data.orderClientId);

          setTimeout(function () {
            removePayment();
          }, 2000);
        },
      );
    }
  };

  const deleteRowByProduct = (row) => (
    <div style={{ width: 100 }}>
      <Tooltip title={'Supprimer ce produit'} placement="left" onClick={() => saveRemove(row.ocbpid)}>
        <IconButton>
          <DeleteOutline style={{ color: '#982525' }} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const getAction = (row) => (
    <div style={{ width: 100 }}>
      <Tooltip title={'Ajouter ce produit'} placement="left" onClick={() => saveAdd(row.id, 0)}>
        <IconButton>
          <Article style={{ color: '#982525' }} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const getActionPayment = () => (
    <div style={{ width: 100 }}>
      <Tooltip title={'Supprimer encaissement'} placement="left" onClick={() => removePayment()}>
        <IconButton>
          <DeleteOutline style={{ color: '#982525' }} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const getActionFree = (row) => (
    <div style={{ width: 100 }}>
      <Tooltip title={'Ajouter ce produit'} placement="left" onClick={() => saveAdd(row.id, 1)}>
        <IconButton>
          <Article style={{ color: '#982525' }} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const inputAddProduct = (value) => {
    ApiHandler.get(
      {
        route: 'api_shop_sales_get_byproduct_by_ean',
        params: { ean: value },
      },
      (response) => {
        if (response.status === 200) {
          dispatch(
            SnackbarOpen({
              text: 'Produit identifié avec succès.',
              variant: 'success',
            }),
          );

          ApiHandler.post(
            {
              route: 'api_shop_sales_add_product',
              params: {
                productId: response.data.productId,
                shopId: authenticationReducer.shopSelected,
                orderClientId: orderClientId,
                free: 0,
              },
            },
            (response) => {
              if (response.status === 200) {
                setReload(reload);
                setOrder(response.data);
                setByProducts(response.data.byProducts);
                dispatch(TableReload('byproductsList'));
                dispatch(TableReload('totalOrderbloc'));

                dispatch(
                  SnackbarOpen({
                    text: 'Produit ajouté avec succès.',
                    variant: 'success',
                  }),
                );
                handlerFormFlashProduct.setValue('flashcode', '');

                document.getElementById('flashcode').value = '';
                beep();

                handlerFormFlashProduct.setFormLoading(false);

                handlerFormFlashProduct.reset();
                removePayment();
              } else {
                dispatch(
                  SnackbarOpen({
                    text:
                      response.error && response.error.message
                        ? response.error.message
                        : "Une erreur inattendue s'est produite.",
                    variant: 'error',
                  }),
                );
                boop();
              }
            },
          );
          dispatch(TableReload('table_api_shop_sales_get_payment'));
          dispatch(TableReload('byproductsList'));
          dispatch(TableReload('totalOrderbloc'));
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
          boop();
        }
      },
    );
  };

  const inputSearchProduct = () => {
    if (document.getElementById('flashcode').value.length === 13) {
      if (handlerFormFlashProduct.checkError() < 1 && orderClientId != null) {
        handlerFormFlashProduct.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        ApiHandler.get(
          {
            route: 'api_shop_sales_get_byproduct_by_ean',
            params: { ean: document.getElementById('flashcode').value },
          },
          (response) => {
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Produit identifié avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.post(
                {
                  route: 'api_shop_sales_add_product',
                  params: {
                    productId: response.data.productId,
                    shopId: authenticationReducer.shopSelected,
                    orderClientId: orderClientId,
                    free: 0,
                  },
                },
                (response) => {
                  if (response.status === 200) {
                    setReload(reload);
                    setOrder(response.data);
                    setByProducts(response.data.byProducts);
                    dispatch(TableReload('byproductsList'));
                    dispatch(TableReload('totalOrderbloc'));

                    dispatch(
                      SnackbarOpen({
                        text: 'Produit ajouté avec succès.',
                        variant: 'success',
                      }),
                    );

                    document.getElementById('flashcode').value = '';
                    handlerFormFlashProduct.setValue('flashcode', '');
                    beep();

                    handlerFormFlashProduct.setFormLoading(false);
                    handlerFormFlashProduct.reset();

                    removePayment();
                  } else {
                    dispatch(
                      SnackbarOpen({
                        text:
                          response.error && response.error.message
                            ? response.error.message
                            : "Une erreur inattendue s'est produite.",
                        variant: 'error',
                      }),
                    );
                    boop();

                    document.getElementById('flashcode').value = '';
                    handlerFormFlashProduct.setValue('flashcode', '');
                    beep();

                    handlerFormFlashProduct.setFormLoading(false);
                    handlerFormFlashProduct.reset();
                  }
                },
              );
              dispatch(TableReload('table_api_shop_sales_get_payment'));
              dispatch(TableReload('byproductsList'));
              dispatch(TableReload('totalOrderbloc'));
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }
          },
        );
      }
    }
  };

  return (
    <ContentViewShopComponent id={'api_sales_order_global'}>
      {orderClientId && (
        <>
          {(() => {
            if (order.orderClientStateId !== 6) {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container spacing={1} id={'containerEan'}>
                      <Grid item xs={10} sm={10}>
                        <TextFieldComponent
                          id={'flashcode'}
                          autoComplete={false}
                          autoFocus
                          handler={handlerFormFlashProduct}
                          onScan={(val) => inputAddProduct(val)}
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <ButtonComponent label={'Valider'} onClick={inputSearchProduct} />
                      </Grid>
                    </Grid>
                    <hr />
                    <div>
                      <TableComponent
                        id={'api_shop_management_shop_by_product_list'}
                        title={'Choisir les articles'}
                        columns={columns}
                        promiseData={(resolve) => {
                          ApiHandler.get(
                            {
                              route: 'api_shop_management_shop_by_product_list',
                              params: { shopId: authenticationReducer.shopSelected },
                            },
                            (response) => {
                              const data = response.data;
                              for (const index in data) {
                                data[index].action = getAction(data[index]);
                              }
                              resolve(data);
                            },
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} style={{ padding: '10px', background: '#0484c7', color: '#fff' }}>
                      Client : {order.clientName} - {order.clientEmail} - Solde avoir disponible : {order.clientSolde}€
                    </Grid>
                    <hr />
                    <TableContainer component={Paper} id={'byproductsList'} sx={{ backgroundColor: '#b3daee' }}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }}>SKU</TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Nom
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Marque
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Quantité
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Prix TTC
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Remise
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Total
                            </TableCell>
                            <TableCell style={{ fontWeight: 900, fontSize: 12 }} align="right">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {byProducts.length > 0 ? (
                            byProducts.map((row) => {
                              return (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell style={{ fontSize: 11 }} component="th" scope="row">
                                    {row.sku}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {row.name}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {row.mark}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {row.amount}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {row.priceTTC.toFixed(2)}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {row.priceDiscountTtc.toFixed(2)}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {(row.priceTTC * row.amount).toFixed(2)}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 11 }} align="right">
                                    {deleteRowByProduct(row)}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                Aucun résultat.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <hr />
                    <div id={'div_recap_order_client_totals'}>
                      <TableContainer component={Paper} id={'totalOrderbloc'}>
                        <Table sx={{ minWidth: 650, backgroundColor: '#b3daee' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align={'right'} style={{ fontWeight: 900, fontSize: 13 }}>
                                TOTAL TTC : {order.priceTTC.toFixed(2)} €
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align={'right'} style={{ fontWeight: 900, fontSize: 13 }}>
                                MONNAIE RENDUE : {order.cashBack.toFixed(2)} € - TOTAL REMISE :{' '}
                                {order.priceDiscountTTC.toFixed(2)} €
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                align={'right'}
                                style={{ fontWeight: 900, fontSize: 13 }}
                                component="th"
                                scope=""
                              >
                                Reste à payer :{' '}
                                {
                                  (params.totalpaid = (
                                    order.priceTTC -
                                    (order.totalCB + order.totalCash + order.totalAvoir + order.cashBack)
                                  ).toFixed(2))
                                }{' '}
                                €
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                      {(() => {
                        if (
                          parseFloat(order.priceTTC) -
                            (parseFloat(order.totalCB) +
                              parseFloat(order.totalCash) +
                              parseFloat(order.totalAvoir) +
                              parseFloat(order.cashBack)) <
                            0.01 &&
                          parseFloat(order.priceTTC) !== 0
                        ) {
                          return (
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                  <TextFieldComponent id={'idSeller'} handler={handlerFormCloseOrderClient} />
                                </Grid>
                              </Grid>
                              <ButtonComponent label={'Cloturer la vente'} onClick={saveCloseOrderClientForm} />
                            </Grid>
                          );
                        }
                      })()}
                    </div>
                    <hr />
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TableComponent
                          id={'api_shop_sales_get_products_offered'}
                          title={'Produits offerts'}
                          columns={columnsOffered}
                          height={10}
                          promiseData={(resolve) => {
                            ApiHandler.get(
                              {
                                route: 'api_shop_sales_get_byproduct_offered',
                              },
                              (response) => {
                                if (response.status === 200) {
                                  const data = response.data;
                                  for (const index in data) {
                                    data[index].action = getActionFree(data[index]);
                                  }
                                  resolve(data);
                                }
                              },
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {(() => {
                          if (order.codePac) {
                            return (
                              <TableContainer component={Paper} id={'codepromo'}>
                                <Table sx={{ minWidth: 65, backgroundColor: '#68b5dd' }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 900, fontSize: 12 }}>
                                        code promotionnel ?
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell style={{ fontSize: 11 }} component="th" scope="">
                                        {order.codePac}

                                        <Tooltip
                                          title={'Supprimer code promo'}
                                          placement="left"
                                          onClick={() => removePac(order.uuid)}
                                        >
                                          <IconButton>
                                            <DeleteOutline style={{ color: '#982525' }} />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            );
                          } else {
                            return (
                              <div style={{ backgroundColor: '#68b5dd', padding: 15 }}>
                                <b style={{ color: 'black' }}>Code promotionnel ?</b>
                                <TextFieldComponent id={'codePac'} handler={handlerFormPac} style={{ marginTop: 15 }} />
                                <ButtonComponent label={'Enregistrer'} onClick={savePacForm} />
                              </div>
                            );
                          }
                        })()}
                        <hr />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid item xs={12} sm={12}>
                      <TableComponent
                        id={'table_api_shop_sales_get_payment'}
                        title={'Règlement client'}
                        columns={columnsEncaissement}
                        search={false}
                        height={10}
                        promiseData={(resolve) => {
                          ApiHandler.get(
                            {
                              route: 'api_shop_sales_get_payment',
                              params: { orderClientId: orderClientId },
                            },
                            (response) => {
                              if (response.status === 200) {
                                const data = response.data;
                                for (const index in data) {
                                  data[index].montantCashText = isNumber(data[index].montantCashText)
                                    ? data[index].montantCashText.toLocaleString()
                                    : '-';
                                  data[index].montantCardText = isNumber(data[index].montantCardText)
                                    ? data[index].montantCardText.toLocaleString()
                                    : '-';
                                  data[index].montantAvoirText = isNumber(data[index].montantAvoirText)
                                    ? data[index].montantAvoirText.toLocaleString()
                                    : '-';
                                  data[index].actionPayment = getActionPayment(data[index]);
                                }
                                resolve(data);
                              }
                            },
                          );
                        }}
                      />
                    </Grid>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={8} sm={12}>
                          <p>Nouvel encaissement</p>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <TextFieldComponent id={'montant'} handler={handlerFormPayment} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <SelectComponent id={'type'} options={optionsPaymentType} handler={handlerFormPayment} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ButtonComponent
                            label={'Enregistrer'}
                            onClick={savePaymentForm}
                            disabled={paymentInProgress}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      Vente bouclée
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          })()}
        </>
      )}
    </ContentViewShopComponent>
  );
}

export default SelectProducts;
