import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  title: React.ReactNode;
  icon?: React.ReactNode;
};

const TitleComponent: React.FC<Props> = ({ title, icon }) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {icon && (
          <IconButton className={classes.icon} disableRipple={true} style={{ color: '#5E6E82', padding: 5 }}>
            {icon}
          </IconButton>
        )}
        <div className={classes.title}>{title}</div>
      </div>
      <hr style={{ marginBottom: 10 }} className={classes.hr} />
    </>
  );
};

const useStyles = makeStyles({
  icon: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  title: {
    fontSize: 15,
    color: '#5E6E82',
    padding: '0 5px 6px 5px',
    width: '100%',
    fontWeight: 300,
    textAlign: 'left',
  },
  hr: {
    marginBottom: '20px',
    marginTop: '0px',
    width: '100%',
    height: '1px',
    border: 0,
    borderBottom: 'solid 1px #5E6E82',
  },
});

export default TitleComponent;
