import { Article, Edit, UpdateDisabled, DoneAll } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { hasRight } from '../../../Common/UserCommon';
import CheckboxComponent from '../../../Component/CheckboxComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { formatNumberToEurCurrency } from '../../../Util/NumberTool';

function BuyProductList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [withByProduct, setWithByProduct] = React.useState(true);
  const [optionsGalenic, setOptionsGalenic] = React.useState([]);
  const [optionsMark, setOptionsMark] = React.useState([]);
  const [optionsProductType, setOptionsProductType] = React.useState([]);
  const [optionsSector, setOptionsSector] = React.useState([]);
  const [optionsIsActive] = React.useState([
    { value: undefined, label: 'Toutes' },
    { value: 'true', label: 'Activé' },
    { value: 'false', label: 'Désactivé' },
  ]);

  const columns = withByProduct
    ? [
        {
          name: 'id',
          label: 'Référence',
          options: { filter: true, sort: true },
        },
        {
          name: 'sku',
          label: 'SKU',
          options: { filter: true, sort: true },
        },
        {
          name: 'byProductSku',
          label: 'SKU sous article',
          options: { filter: true, sort: true },
        },
        {
          name: 'name',
          label: 'Nom',
          options: { filter: true, sort: true },
        },
        {
          name: 'galenic',
          label: 'galénique',
          options: { filter: true, sort: true },
        },
        {
          name: 'mark',
          label: 'Marque',
          options: { filter: true, sort: true },
        },
        {
          name: 'productType',
          label: 'Type',
          options: { filter: true, sort: true },
        },
        {
          name: 'sector',
          label: 'Secteur',
          options: { filter: true, sort: true },
        },
        {
          name: 'packSize',
          label: 'Conditionnement',
          options: { filter: true, sort: true },
        },
        {
          name: 'flavor',
          label: 'Parfum / Couleur',
          options: { filter: true, sort: true },
        },
        {
          name: 'dpa',
          label: 'DPA',
          options: { filter: true, sort: true },
        },
        {
          name: 'isActive',
          label: 'Activation',
          options: { filter: false, sort: true },
        },
        {
          name: 'isSylius',
          label: 'Sylius',
          options: { filter: false, sort: false },
        },
        {
          name: 'action',
          label: 'Action',
          options: { filter: false, sort: false },
        },
      ]
    : [
        {
          name: 'id',
          label: 'Référence',
          options: { filter: true, sort: true },
        },
        {
          name: 'sku',
          label: 'SKU',
          options: { filter: true, sort: true },
        },
        {
          name: 'name',
          label: 'Nom',
          options: { filter: true, sort: true },
        },
        {
          name: 'galenic',
          label: 'galénique',
          options: { filter: true, sort: true },
        },
        {
          name: 'mark',
          label: 'Marque',
          options: { filter: true, sort: true },
        },
        {
          name: 'productType',
          label: 'Type',
          options: { filter: true, sort: true },
        },
        {
          name: 'sector',
          label: 'Secteur',
          options: { filter: true, sort: true },
        },
        {
          name: 'isActive',
          label: 'Activation',
          options: { filter: false, sort: true },
        },
        {
          name: 'isSylius',
          label: 'Sylius',
          options: { filter: false, sort: false },
        },
        {
          name: 'action',
          label: 'Action',
          options: { filter: false, sort: false },
        },
      ];

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    sku: {
      name: 'sku',
      label: 'SKU',
      textHelper: 'Rechercher par codes.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].sku
          ? cacheTableServer['api_buy_product_list'].sku
          : '',
    },
    name: {
      name: 'name',
      label: 'Nom',
      textHelper: 'Rechercher par noms.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].name
          ? cacheTableServer['api_buy_product_list'].name
          : '',
    },
    galenic: {
      name: 'galenic',
      label: 'Galénique',
      textHelper: 'Rechercher par galéniques.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].galenic
          ? cacheTableServer['api_buy_product_list'].galenic
          : 0,
    },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: 'Rechercher par marques.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].mark
          ? cacheTableServer['api_buy_product_list'].mark
          : 0,
    },
    productType: {
      name: 'productType',
      label: 'Type',
      textHelper: 'Rechercher par types.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].productType
          ? cacheTableServer['api_buy_product_list'].productType
          : 0,
    },
    sector: {
      name: 'sector',
      label: 'Secteur',
      textHelper: 'Rechercher par secteurs.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].sector
          ? cacheTableServer['api_buy_product_list'].sector
          : 0,
    },
    isActive: {
      name: 'isActive',
      label: 'Activation',
      textHelper: 'Rechercher par activation.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_buy_product_list'] && cacheTableServer['api_buy_product_list'].isActive
          ? cacheTableServer['api_buy_product_list'].isActive
          : undefined,
    },
    withByProduct: {
      name: 'withByProduct',
      label: 'Avec les sous articles',
      type: 'boolean',
      defaultValue: true,
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage.api_buy_product_list = 0;
    cacheTableServer.api_buy_product_list.page = 0;
    cacheTableServer.api_buy_product_list[index] = value;
    dispatch(TableReload('api_buy_product_list'));
  };
  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') ||
      hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_GET')
    ) {
      return (
        <>
          {hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') ||
          hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT') ? (
            <Tooltip title={'Modifier'} placement="left">
              <Link to={getRoutePathname('buy_product', { id: row.id })}>
                <IconButton>
                  <Edit style={{ color: '#17a2b8' }} />
                </IconButton>
              </Link>
            </Tooltip>
          ) : hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_GET') ? (
            <Tooltip title={'Visualiser'} placement="left">
              <Link to={getRoutePathname('buy_product', { id: row.id })}>
                <IconButton>
                  <Article style={{ color: '#28a745' }} />
                </IconButton>
              </Link>
            </Tooltip>
          ) : (
            <IconButton disabled={true}>
              <Edit style={{ color: '#17a2b8' }} />
            </IconButton>
          )}
        </>
      );
    } else {
      return (
        <>
          {hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT') ? (
            <IconButton style={{ cursor: 'no-drop' }}>
              <Edit />
            </IconButton>
          ) : (
            <IconButton style={{ cursor: 'no-drop' }}>
              <Article />
            </IconButton>
          )}
        </>
      );
    }
  };

  React.useEffect(() => {
    setWithByProduct(formSearch.withByProduct.value);
  }, [formSearch.withByProduct.value]);
  React.useEffect(() => {
    handlerFormSearch.start();

    // get options Product
    ApiHandler.get({ route: 'api_select_galenic' }, (response) => {
      const data = [{ value: 0, label: 'Toutes' }, ...response.data];
      setOptionsGalenic(data);
    });
    ApiHandler.get({ route: 'api_select_mark' }, (response) => {
      const data = [{ value: 0, label: 'Toutes' }, ...response.data];
      setOptionsMark(data);
    });
    ApiHandler.get({ route: 'api_select_product_type' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsProductType(data);
    });
    ApiHandler.get({ route: 'api_select_sector' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsSector(data);
    });
  }, []);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des articles', context: 'Achat' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={4}>
                <TextFieldComponent
                  id={'sku'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('sku', val)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                <TextFieldComponent
                  id={'name'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('name', val)}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
                <SelectComponent
                  id={'isActive'}
                  options={optionsIsActive}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('isActive', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <CheckboxComponent
                  orientation={'right'}
                  id={'withByProduct'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('withByProduct', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'mark'}
                  options={optionsMark}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('mark', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'galenic'}
                  options={optionsGalenic}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('galenic', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'productType'}
                  options={optionsProductType}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('productType', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'sector'}
                  options={optionsSector}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('sector', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_buy_product_list'}
        title={'Articles'}
        columns={columns}
        search={false}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') || hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_ADD')
            ? {
                label: 'Ajouter un article',
                link: getRoutePathname('buy_product', { id: 'add' }),
              }
            : false
        }
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            sku: formSearch.sku.value ? formSearch.sku.value : '',
            name: formSearch.name.value ? formSearch.name.value : '',
            galenic: datas.galenic === 0 || !datas.galenic ? '' : datas.galenic,
            mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
            productType: datas.productType === 0 || !datas.productType ? '' : datas.productType,
            sector: datas.sector === 0 || !datas.sector ? '' : datas.sector,
            isActive: datas.isActive ? datas.isActive : '',
            withByProduct: formSearch.withByProduct.value ?? withByProduct,
          };

          ApiHandler.get(
            {
              route: 'api_buy_product_list',
              data: filters,
            },
            (response) => {
              const data = response.data?.data;

              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].isActive = data[index].isActive ? (
                  <span style={{ color: '#28a745' }}>Activé</span>
                ) : (
                  <span style={{ color: '#982525' }}>Désactivé</span>
                );
                data[index].isSylius = data[index].isSylius ? (
                  <DoneAll style={{ color: '#28a745' }} />
                ) : (
                  <UpdateDisabled style={{ color: '#ffc107' }} />
                );
                data[index].dpa =
                  data[index].dpa && data[index].dpa > 0 ? formatNumberToEurCurrency(data[index].dpa, 3) : '-';
                if (data[index].productTypeId === 2 || data[index].productTypeId === 3) data[index].isSylius = '-';
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
    </ContentViewComponent>
  );
}

export default BuyProductList;
