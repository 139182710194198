import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StateType } from '#app/Store';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';

type Props = {
  shopId: string;
};

const ShopCashRegisterForm: React.FC<Props> = ({ shopId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const [totalOpening, setTotalOpening] = useState<number>(0);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_SHOP_SHOP_SALES') && !hasRight(authenticationReducer, 'ROLE_SHOP_SELLER');

  const [form, setForm] = useState<Form>({
    b500: {
      name: 'b500',
      label: '500€',
      textHelper: 'Saisissez le nombre de billet de 500 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b200: {
      name: 'b200',
      label: '200€',
      textHelper: 'Saisissez le nombre de billet de 200 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b100: {
      name: 'b100',
      label: '100€',
      textHelper: 'Saisissez le nombre de billet de 100 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b50: {
      name: 'b50',
      label: '50€',
      textHelper: 'Saisissez le nombre de billet de 50 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b20: {
      name: 'b20',
      label: '20€',
      textHelper: 'Saisissez le nombre de billet de 20 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b10: {
      name: 'b10',
      label: '10€',
      textHelper: 'Saisissez le nombre de billet de 10 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    b5: {
      name: 'b5',
      label: '5€',
      textHelper: 'Saisissez le nombre de billet de 5 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p2: {
      name: 'p2',
      label: '2€',
      textHelper: 'Saisissez le nombre de pièces de 2 euros.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p1: {
      name: 'p1',
      label: '1€',
      textHelper: 'Saisissez le nombre de pièces de 1 euro.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p050: {
      name: 'p050',
      label: '0.5€',
      textHelper: 'Saisissez le nombre de pièces de 50 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p020: {
      name: 'p020',
      label: '0.2€',
      textHelper: 'Saisissez le nombre de pièces de 20 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p010: {
      name: 'p010',
      label: '0.1€',
      textHelper: 'Saisissez le nombre de pièces de 10 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p005: {
      name: 'p005',
      label: '0.05€',
      textHelper: 'Saisissez le nombre de pièces de 5 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p002: {
      name: 'p002',
      label: '0.02€',
      textHelper: 'Saisissez le nombre de pièces de 2 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
    p001: {
      name: 'p001',
      label: '0.01€',
      textHelper: 'Saisissez le nombre de pièces de 1 cents.',
      type: 'text',
      options: { validation: ['required'] },
    },
  });

  const handlerForm = initFormHandler(form, setForm);

  const calculateTotalCash = (): void => {
    const denominations = [
      { id: 'b500', value: 500 },
      { id: 'b200', value: 200 },
      { id: 'b100', value: 100 },
      { id: 'b50', value: 50 },
      { id: 'b20', value: 20 },
      { id: 'b10', value: 10 },
      { id: 'b5', value: 5 },
      { id: 'p2', value: 2 },
      { id: 'p1', value: 1 },
      { id: 'p050', value: 0.5 },
      { id: 'p020', value: 0.2 },
      { id: 'p010', value: 0.1 },
      { id: 'p005', value: 0.05 },
      { id: 'p002', value: 0.02 },
      { id: 'p001', value: 0.01 },
    ];

    const total = denominations.reduce((sum, denom) => {
      const value = handlerForm.getValue(denom.id);
      if (value !== '') {
        return sum + value * denom.value;
      }
      return sum;
    }, 0);
    setTotalOpening(parseFloat(total.toFixed(2)));
  };

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_shop_sales_open',
          data: handlerForm.getData(),
          params: { id: shopId },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(SnackbarOpen({ text: 'Caisse ouverte avec succès.', variant: 'success' }));
            navigate(getRoutePathname('shop_sales_sign_opening'));
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          dispatch(LinearProgressMainLayoutDeactivate());
          handlerForm.setFormLoading(false);
          setIsLoadingForm(false);
        },
      );
    }
  };

  useEffect(() => {
    handlerForm.start();
  }, []);
  useEffect(() => {
    handlerForm.setFormLoading(justWatch);
  }, [justWatch]);

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title="Ouverture fonds de caisse" />
      <Grid container spacing={2}>
        {Object.keys(form).map((id) => (
          <Grid item xs={12} sm={3} key={id}>
            <TextFieldComponent id={id} handler={handlerForm} onBlur={calculateTotalCash} />
          </Grid>
        ))}
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <h4>Total ouverture caisse : {totalOpening} €</h4>
        </Grid>
      </Grid>

      {!justWatch && (
        <ButtonComponent label="Enregistrer" className={classes.button} onClick={save} loading={isLoadingForm} />
      )}
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default ShopCashRegisterForm;
