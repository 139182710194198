import { Fade, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import ShopManagementByProductList from './ShopManagementShopComponent/ShopManagementByProductList';
import ShopManagementShopForm from './ShopManagementShopComponent/ShopManagementShopForm';

type Shop = {
  id: string;
};

const ShopManagementShop: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<{ id: string }>();

  // Global
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);
  const [shop, setShop] = useState<Shop>();

  useEffect(() => {
    setShop(undefined);
    if (params.id === 'add') {
      setTimeout(() => setIsLoadingContent(false), 1500);
    } else {
      setIsLoadingContent(true);

      ApiHandler.get<Shop>(
        {
          route: 'api_shop_management_shop_get',
          params: { id: params.id as string },
        },
        (response) => {
          if (response.status === 200) {
            setShop(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Ajouter une boutique' : 'Éditer une boutique',
        context: 'Gestion boutique',
        links: [{ path: getRoutePathname('shop_management_shop_list'), label: 'Liste des boutiques' }],
      }}
    >
      <Fade in={true} timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShopManagementShopForm shop={shop} />
        </Grid>
      </Fade>

      <br />

      {shop && <ShopManagementByProductList shop={shop} />}

      <br />
      <br />
    </ContentViewComponent>
  );
};

export default ShopManagementShop;
