export const isInt = (n: number): boolean => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n: number): boolean => {
  return Number(n) === n && n % 1 !== 0;
};

export const isNumber = (n: string): boolean => {
  const num = parseFloat(n);
  return isFloat(num) || isInt(num);
};

export const formatNumberToEurCurrency = (number: number, decimals: number = 2): string => {
  if (!isNaN(number)) {
    return parseFloat(number.toString()).toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });
  }
  return '-';
};

export const formatCurrencyToFloat = (val: string): number => {
  const decimals = findNumberOfDecimals(val);
  const divider = calculateDivider(decimals);
  const cleanedVal = removeSpecialCharsFromCurrency(val);

  return parseFloat(cleanedVal) / divider; // Formatting deletes comma "," and "€". We should divide to get the base value according to decimals.
};

export const removeSpecialCharsFromCurrency = (val: string | number): string => {
  return val.toString().replace(/[^\d.-]/g, '');
};

export const findNumberOfDecimals = (val: string | number): number => {
  const value = val.toString();
  if (value.includes(',')) {
    let decimalPart = value.split(',')[1];
    decimalPart = decimalPart.replace(/\D/g, '');

    return decimalPart.length;
  } else {
    return 0;
  }
};

export const calculateDivider = (decimals: number): number => {
  // Determining the divisor based on the number of decimal places.
  return Math.pow(10, decimals);
};

// Use only for displaying int/float values.
export const formatNumberWithThousandsSeparator = (number: number): string => {
  return new Intl.NumberFormat().format(number);
};
