import { AppRegistration } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TableComponent from '../../../../Component/TableComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';
import { isNumber } from '../../../../Util/NumberTool';

function BuyProductByProductPurveyor(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');

  // Purveyor List
  const columnsPurveyor = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom',
      options: { filter: true, sort: true },
    },
    {
      name: 'purveyorReference',
      label: 'Référence fournisseur',
      options: { filter: true, sort: true },
    },
    {
      name: 'priceText',
      label: 'Prix achat HT',
      options: { filter: true, sort: true },
    },
    {
      name: 'shapingPriceText',
      label: 'Prix de façonnage HT',
      options: { filter: true, sort: true },
    },
    {
      name: 'moqText',
      label: 'MOQ',
      options: { filter: true, sort: true },
    },
    {
      name: 'palletizingText',
      label: 'Palettisation',
      options: { filter: true, sort: true },
    },
    {
      name: 'boxText',
      label: 'Carton',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getActionPurveyor = (row) => {
    if (!justWatch) {
      return (
        <Tooltip title={'Modifier'} placement="left">
          <IconButton
            onClick={() => {
              handlerFormPurveyor.reset();
              handlerFormPurveyor.setValue('price', row.price === 'N/A' ? '' : row.price);
              handlerFormPurveyor.setValue('shapingPrice', row.shapingPrice === 'N/A' ? '' : row.shapingPrice);
              handlerFormPurveyor.setValue('moq', row.moq === 'N/A' ? 0 : row.moq);
              handlerFormPurveyor.setValue('palletizing', row.palletizing === 'N/A' ? 0 : row.palletizing);
              handlerFormPurveyor.setValue('box', row.box === 'N/A' ? 0 : row.box);
              handlerFormPurveyor.setValue(
                'purveyorReference',
                row.purveyorReference === 'N/A' ? '' : row.purveyorReference,
              );
              setPurveyor(row);
            }}
          >
            <AppRegistration style={{ color: '#17a2b8' }} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <IconButton style={{ cursor: 'no-drop' }}>
          <AppRegistration />
        </IconButton>
      );
    }
  };

  // Purveyor price Form
  const [purveyor, setPurveyor] = React.useState(null);
  const [formPurveyor, setFormPurveyor] = React.useState({
    purveyorReference: {
      name: 'purveyorReference',
      label: 'Référence fournisseur',
      textHelper: 'Saisissez la référence fournisseur du sous article chez ce fournisseur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    price: {
      name: 'price',
      label: "Prix d'achat HT",
      textHelper: "Saisissez le prix d'achat du sous article chez ce fournisseur.",
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    shapingPrice: {
      name: 'shapingPrice',
      label: 'Prix de façonnage HT',
      textHelper: 'Saisissez le prix de façonnage du sous produit chez ce fournisseur.',
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    moq: {
      name: 'moq',
      label: 'MOQ',
      textHelper: 'Saisissez le MOQ du sous produit chez ce fournisseur.',
      type: 'integer',
      defaultValue: 0,
      options: { validation: ['required'] },
    },
    palletizing: {
      name: 'palletizing',
      label: 'Palettisation',
      textHelper: "Saisissez le nombre d'unité par palette du sous produit chez ce fournisseur.",
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    box: {
      name: 'box',
      label: 'Carton',
      textHelper: "Saisissez le nombre d'unité par carton du sous produit chez ce fournisseur.",
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const [isLoadingFormPurveyor, setIsLoadingFormPurveyor] = React.useState(false);
  const handlerFormPurveyor = initFormHandler(formPurveyor, setFormPurveyor);
  const savePurveyor = () => {
    if (handlerFormPurveyor.checkError() < 1) {
      handlerFormPurveyor.setFormLoading(true);
      setIsLoadingFormPurveyor(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_buy_product_by_product_purveyor_change',
          data: handlerFormPurveyor.getData(),
          params: { productId: props.product.id, byProductId: props.byProduct.id, purveyorId: purveyor.id },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(TableReload('api_buy_product_by_product_purveyor_list'));
            setPurveyor(null);
            dispatch(
              SnackbarOpen({
                text: 'Information du sous article et du fournisseur modifié avec succès.',
                variant: 'success',
              }),
            );
          } else if (response.status === 400) {
            handlerFormPurveyor.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          dispatch(LinearProgressMainLayoutDeactivate());
          handlerFormPurveyor.setFormLoading(false);
          setIsLoadingFormPurveyor(false);
        },
      );
    }
  };
  React.useEffect(() => {
    handlerFormPurveyor.start();
  }, []);

  return (
    <>
      <TableComponent
        noShadow={true}
        id={'api_buy_product_by_product_purveyor_list'}
        title={
          props.byProduct.dpa ? (
            <div>
              Fournisseurs<span className={classes.dpa}> DPA : {props.byProduct.dpa} €</span>
            </div>
          ) : (
            <div>Fournisseurs</div>
          )
        }
        columns={columnsPurveyor}
        promiseData={(resolve) => {
          ApiHandler.get(
            {
              route: 'api_buy_product_by_product_purveyor_list',
              params: { productId: props.product.id, byProductId: props.byProduct.id },
            },
            (response) => {
              const data = response.data;
              for (const index in data) {
                data[index].action = getActionPurveyor(data[index]);
                data[index].priceText = isNumber(data[index].price)
                  ? parseFloat(data[index].price).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].shapingPriceText = isNumber(data[index].shapingPrice)
                  ? parseFloat(data[index].shapingPrice).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].moqText = isNumber(data[index].moq)
                  ? data[index].moq.toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].palletizingText = isNumber(data[index].palletizing)
                  ? data[index].palletizing.toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].boxText = isNumber(data[index].box)
                  ? data[index].box.toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
              }
              resolve(data);
            },
          );
        }}
      />

      <Dialog open={!!purveyor} maxWidth={'xl'} onClose={() => setPurveyor(null)}>
        <DialogTitle style={{ fontSize: 15 }}>{purveyor ? purveyor.name : ''}</DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextFieldComponent
                id={'purveyorReference'}
                handler={handlerFormPurveyor}
                adornment={<span>unité(s)</span>}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <TextFieldComponent id={'price'} handler={handlerFormPurveyor} adornment={<span>€</span>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <TextFieldComponent id={'shapingPrice'} handler={handlerFormPurveyor} adornment={<span>€</span>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
              <TextFieldComponent id={'moq'} handler={handlerFormPurveyor} adornment={<span>unité(s)</span>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
              <TextFieldComponent id={'palletizing'} handler={handlerFormPurveyor} adornment={<span>unité(s)</span>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
              <TextFieldComponent id={'box'} handler={handlerFormPurveyor} adornment={<span>unité(s)</span>} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setPurveyor(null)}
            disabled={isLoadingFormPurveyor}
          />
          <ButtonComponent label={'Enregistrer'} onClick={savePurveyor} loading={isLoadingFormPurveyor} />
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles({
  dpa: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
  },
});

export default BuyProductByProductPurveyor;
