import { Grid, Slide, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function InventoryManagementValorisationStockList() {
  const classes = useStyles();

  const [loadingContent, setLoadingContent] = React.useState(true);

  const [optionsDeposit, setOptionsDeposit] = React.useState([]);
  const [listDeposit, setListDeposit] = React.useState([]);
  const [optionsPurveyor, setOptionsPurveyor] = React.useState([]);
  const [optionsFamily, setOptionsFamily] = React.useState([]);
  const [optionsSubFamily, setOptionsSubFamily] = React.useState([]);
  const [optionsMark, setOptionsMark] = React.useState([]);
  const [optionsPackSize, setOptionsPackSize] = React.useState([]);
  const [optionsFlavor, setOptionsFlavor] = React.useState([]);
  const [optionsGalenic, setOptionsGalenic] = React.useState([]);
  const [optionsProductType, setOptionsProductType] = React.useState([]);
  const [optionsSector, setOptionsSector] = React.useState([]);
  const [moveColumns, setMoveColumns] = React.useState([]);
  const [optionsLocationType, setOptionsLocationType] = React.useState('');
  const [optionRegion, setOptionsRegion] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const defaultColumns = [
    {
      name: 'id',
      label: 'Référence article',
      options: { filter: true, sort: true },
    },

    {
      name: 'sku',
      label: 'SKU',
      options: { filter: true, sort: true },
    },
    {
      name: 'mark',
      label: 'Marque',
      options: { filter: true, sort: true },
    },
    {
      name: 'galenic',
      label: 'Galénique',
      options: { filter: true, sort: true },
    },
    {
      name: 'type',
      label: 'Type',
      options: { filter: true, sort: true },
    },
    {
      name: 'sector',
      label: 'Secteur',
      options: { filter: true, sort: true },
    },
    {
      name: 'family',
      label: 'Famille',
      options: { filter: true, sort: true },
    },
    {
      name: 'subfamily',
      label: 'Sous famille',
      options: { filter: true, sort: true },
    },
    {
      name: 'name',
      label: 'Nom article',
      options: { filter: true, sort: true },
    },
    {
      name: 'packSize',
      label: 'Taille de conditionnement',
      options: { filter: true, sort: true },
    },
    {
      name: 'flavor',
      label: 'Parfum/ Couleur',
      options: { filter: true, sort: true },
    },
  ];

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseurs.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.purveyor
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.purveyor
          : '',
    },
    family: {
      name: 'family',
      label: 'Famille',
      textHelper: 'Rechercher par familles.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.family
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.family
          : '',
    },
    subfamily: {
      name: 'subfamily',
      label: 'Sous famille',
      textHelper: 'Rechercher par sous familles.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.subFamily
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.subFamily
          : '',
    },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: "Rechercher par marque d'article.",
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.mark
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.mark
          : '',
    },
    byProduct: {
      name: 'byProduct',
      label: 'Article',
      textHelper: "Rechercher par nom d'article.",
      type: 'text',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.byProduct
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.byProduct
          : '',
    },
    packSize: {
      name: 'packSize',
      label: 'Conditionnement',
      textHelper: 'Rechercher par conditionnement.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.packSize
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.packSize
          : '',
    },
    flavor: {
      name: 'flavor',
      label: 'Parfum',
      textHelper: 'Rechercher par parfum.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.flavor
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.flavor
          : '',
    },
    galenic: {
      name: 'galenic',
      label: 'Galénique',
      textHelper: 'Rechercher par galéniques.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.galenic
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.galenic
          : '',
    },
    productType: {
      name: 'productType',
      label: 'Type',
      textHelper: 'Rechercher par types.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.productType
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.productType
          : '',
    },
    sector: {
      name: 'sector',
      label: 'Secteur',
      textHelper: 'Rechercher par secteurs.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.sector
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.sector
          : '',
    },
    deposit: {
      name: 'deposit',
      label: 'Dépôt',
      textHelper: 'Rechercher par dépôts.',
      type: 'array',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.deposit
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.deposit
          : '',
    },
    locationType: {
      name: 'locationType',
      label: 'Lieu',
      textHelper: 'Rechercher par type de lieu',
      type: 'text',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.locationType
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.locationType
          : '',
    },
    region: {
      name: 'region',
      label: 'Region',
      textHelper: 'Rechercher par region',
      type: 'text',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.region
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.region
          : '',
    },
    startAt: {
      name: 'startAt',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.fromStartAt
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.fromStartAt
          : moment().format('YYYY-01-01'),
      options: { validation: ['date'] },
    },
    endAt: {
      name: 'endAt',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue:
        cacheTableServer.api_inventory_management_valorisation_stock_list &&
        cacheTableServer.api_inventory_management_valorisation_stock_list.fromEndAt
          ? cacheTableServer.api_inventory_management_valorisation_stock_list.fromEndAt
          : '',
      options: { validation: ['date'] },
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    dispatch(TableReload('api_inventory_management_valorisation_stock_list'));
  };
  React.useEffect(() => {
    handlerFormSearch.start();

    // get options
    ApiHandler.get({ route: 'api_select_purveyor' }, (response) => {
      setOptionsPurveyor(response.data);
    });
    ApiHandler.get({ route: 'api_select_deposit' }, (response) => {
      setOptionsDeposit(response.data);
      setListDeposit(response.data);
    });
    ApiHandler.get({ route: 'api_select_mark' }, (response) => {
      setOptionsMark(response.data);
    });
    ApiHandler.get({ route: 'api_select_pack_size' }, (response) => {
      setOptionsPackSize(response.data);
    });
    ApiHandler.get({ route: 'api_select_flavor' }, (response) => {
      setOptionsFlavor(response.data);
    });
    ApiHandler.get({ route: 'api_select_family' }, (response) => {
      setOptionsFamily(response.data);
    });
    ApiHandler.get({ route: 'api_select_subfamily' }, (response) => {
      setOptionsSubFamily(response.data);
    });
    ApiHandler.get({ route: 'api_select_galenic' }, (response) => {
      setOptionsGalenic(response.data);
    });
    ApiHandler.get({ route: 'api_select_product_type' }, (response) => {
      setOptionsProductType(response.data);
    });
    ApiHandler.get({ route: 'api_select_sector' }, (response) => {
      setOptionsSector(response.data);
    });

    setOptionsLocationType([
      {
        id: 1,
        label: 'Entrepôt',
        value: 'entrepot',
      },
      {
        id: 2,
        label: 'Façonnier',
        value: 'faconnier',
      },
      {
        id: 3,
        label: 'Boutiques',
        value: 'boutiques',
      },
      {
        id: 4,
        label: 'Tous',
        value: 'tous',
      },
    ]);
    ApiHandler.get({ route: 'api_select_region' }, (response) => {
      setOptionsRegion(response.data);
      setOptionsRegion((optionRegion) => [...optionRegion, { id: 4, label: 'TOUS', value: '4' }]);
    });

    setLoadingContent(false);
  }, []);
  const handleDownloadCSV = () => {
    const datas = handlerFormSearch.getData();
    let filteredDeposits = listDeposit;
    setOptionsDeposit(filteredDeposits);

    if (datas.region !== 4 && datas.locationType !== 'boutiques' && datas.locationType !== 'tous') {
      inputSearchChange('region', 4);
      datas.region = null;
    }
    if (datas.locationType !== null) {
      filteredDeposits = filterDeposits(datas.locationType, filteredDeposits);
      setOptionsDeposit(filteredDeposits);
    }
    if (datas.region !== null) {
      filteredDeposits = filterRegion(datas.region, filteredDeposits);
      setOptionsDeposit(filteredDeposits);
    }
    datas.deposit = datas.deposit.length > 0 ? datas.deposit : filteredDeposits.map((dep) => dep.value);
    const filters = {
      byProduct: formSearch.byProduct.value ? formSearch.byProduct.value : '',
      purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
      deposit: datas.deposit === 0 || !datas.deposit ? '' : datas.deposit,
      family: datas.family === 0 || !datas.family ? '' : datas.family,
      subfamily: datas.subfamily === 0 || !datas.subfamily ? '' : datas.subfamily,
      mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
      packSize: datas.packSize === 0 || !datas.packSize ? '' : datas.packSize,
      flavor: datas.flavor === 0 || !datas.flavor ? '' : datas.flavor,
      galenic: datas.galenic === 0 || !datas.galenic ? '' : datas.galenic,
      productType: datas.productType === 0 || !datas.productType ? '' : datas.productType,
      sector: datas.sector === 0 || !datas.sector ? '' : datas.sector,
      startAt: datas.startAt === 0 || !datas.startAt ? '' : datas.startAt,
      endAt: datas.endAt === 0 || !datas.endAt ? '' : datas.endAt,
      locationType: formSearch.locationType.value ? formSearch.locationType.value : '',
      csv: true,
    };
    ApiHandler.get({ route: 'api_inventory_management_valorisation_stock_list_csv', data: filters }, (response) => {
      const blob = new Blob([response], { type: 'text/csv' });
      const a = document.createElement('a');
      a.download = 'valorisation-stock.csv';
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    });
  };

  // Fonction pour filtrer les dépôts en fonction de l'emplacement
  const filterDeposits = (locationType, deposits) => {
    switch (locationType) {
      case 'entrepot':
        return deposits.filter((dep) => dep.is_deposit_web === true);
      case 'faconnier':
        return deposits.filter((dep) => dep.purveyor > 0);
      case 'boutiques':
        return deposits.filter((dep) => dep.is_deposit_retail === true);
      default:
        return deposits;
    }
  };
  const filterRegion = (region, deposits) => {
    switch (region) {
      case 1:
        return deposits.filter((dep) => dep.code_region === 'EST');
      case 2:
        return deposits.filter((dep) => dep.code_region === 'PARIS');
      case 3:
        return deposits.filter((dep) => dep.code_region === 'SUD');
      default:
        return deposits;
    }
  };
  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{ title: 'Valorisations des stocks', context: 'Gestion des stocks' }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  id={'byProduct'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('byProduct', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <DatePickerComponent
                  id={'startAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('startAt', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <DatePickerComponent
                  id={'endAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('endAt', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'locationType'}
                  options={optionsLocationType}
                  handler={handlerFormSearch}
                  defaultValue={optionsLocationType[3]}
                  onChange={(val) => inputSearchChange('locationType', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <SelectComponent
                  disabled={
                    handlerFormSearch.getData().locationType !== 'boutiques' &&
                    handlerFormSearch.getData().locationType !== 'tous'
                  }
                  id={'region'}
                  options={optionRegion}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('region', val)}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'deposit'}
                  options={optionsDeposit}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('deposit', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'purveyor'}
                  options={optionsPurveyor}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('purveyor', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'mark'}
                  options={optionsMark}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('mark', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'family'}
                  options={optionsFamily}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('family', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'subfamily'}
                  options={optionsSubFamily}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('subfamily', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                <MultipleSelectComponent
                  id={'packSize'}
                  options={optionsPackSize}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('packSize', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                <MultipleSelectComponent
                  id={'flavor'}
                  options={optionsFlavor}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('flavor', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                <MultipleSelectComponent
                  id={'galenic'}
                  options={optionsGalenic}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('galenic', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'productType'}
                  options={optionsProductType}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('productType', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <MultipleSelectComponent
                  id={'sector'}
                  options={optionsSector}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('sector', val)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <Button
                  id="downloadButton"
                  variant="contained"
                  size="small"
                  sx={{ fontSize: '0.8rem', marginTop: '5px' }}
                  onClick={handleDownloadCSV}
                >
                  Télécharger CSV
                </Button>
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_inventory_management_valorisation_stock_list'}
        title={
          total ? (
            <div>
              Valorisation stocks<span className={classes.valo}> Total : {total} €</span>
            </div>
          ) : (
            <div>Valorisation stocks</div>
          )
        }
        columns={moveColumns}
        promiseData={(resolve) => {
          const datas = handlerFormSearch.getData();
          let filteredDeposits = listDeposit;
          setOptionsDeposit(filteredDeposits);

          if (datas.region !== 4 && datas.locationType !== 'boutiques' && datas.locationType !== 'tous') {
            inputSearchChange('region', 4);
            datas.region = null;
          }
          if (datas.locationType !== null) {
            filteredDeposits = filterDeposits(datas.locationType, filteredDeposits);
            setOptionsDeposit(filteredDeposits);
          }
          if (datas.region !== null) {
            filteredDeposits = filterRegion(datas.region, filteredDeposits);
            setOptionsDeposit(filteredDeposits);
          }
          datas.deposit = datas.deposit.length > 0 ? datas.deposit : filteredDeposits.map((dep) => dep.value);
          const filters = {
            byProduct: formSearch.byProduct.value ? formSearch.byProduct.value : '',
            purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
            deposit: datas.deposit === 0 || !datas.deposit ? '' : datas.deposit,
            family: datas.family === 0 || !datas.family ? '' : datas.family,
            subfamily: datas.subfamily === 0 || !datas.subfamily ? '' : datas.subfamily,
            mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
            packSize: datas.packSize === 0 || !datas.packSize ? '' : datas.packSize,
            flavor: datas.flavor === 0 || !datas.flavor ? '' : datas.flavor,
            galenic: datas.galenic === 0 || !datas.galenic ? '' : datas.galenic,
            productType: datas.productType === 0 || !datas.productType ? '' : datas.productType,
            sector: datas.sector === 0 || !datas.sector ? '' : datas.sector,
            startAt: datas.startAt === 0 || !datas.startAt ? '' : datas.startAt,
            endAt: datas.endAt === 0 || !datas.endAt ? '' : datas.endAt,
            locationType: formSearch.locationType.value ? formSearch.locationType.value : '',
          };

          ApiHandler.get(
            {
              route: 'api_inventory_management_valorisation_stock_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;

              const col = defaultColumns;
              const deps = [];
              let t = 0;
              for (const index in data) {
                data[index].valuation = (Math.round(data[index].dpa * data[index].amount * 100) / 100).toLocaleString(
                  'fr-FR',
                  {
                    minimumFractionDigits: 2,
                  },
                );
                t += data[index].dpa * data[index].amount;
                data[index].total = data[index].amount;
                for (const index2 in data[index].deposits) {
                  deps[data[index].deposits[index2].id] = {
                    name: `deposit_${data[index].deposits[index2].id}`,
                    label: data[index].deposits[index2].name,
                    options: { filter: true, sort: false },
                  };

                  data[index][`deposit_${data[index].deposits[index2].id}`] = data[index].deposits[
                    index2
                  ].amount.toLocaleString('fr-FR', {
                    minimumFractionDigits: 0,
                  });
                }
              }

              setTotal((Math.round(t * 100) / 100).toLocaleString('fr-FR', { minimumFractionDigits: 2 }));

              for (const index in deps) {
                col.push(deps[index]);

                for (const index2 in data) {
                  if (!data[index2][deps[index].name]) {
                    data[index2][deps[index].name] = '-';
                  }
                }
              }

              setMoveColumns([
                ...col,
                ...col,
                {
                  name: 'total',
                  label: 'Total',
                  options: { filter: true, sort: false },
                },
                {
                  name: 'dpa',
                  label: 'DPA',
                  options: { filter: true, sort: true },
                },
                {
                  name: 'valuation',
                  label: 'Valorisation',
                  options: { filter: true, sort: true },
                },
              ]);

              resolve(data);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  valo: {
    backgroundColor: '#9c27b0',
    color: '#FFF',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
  },
});

export default InventoryManagementValorisationStockList;
