import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import { dispatch } from '../../../../App';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TableComponent from '../../../../Component/TableComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';

function SaleClientCommentList(props) {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'comment',
      label: 'Message',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: ' ',
      options: { filter: true, sort: true },
    },
  ];
  const [form, setForm] = React.useState({
    comment: {
      name: 'comment',
      label: 'Commentaire',
      textHelper: 'Saisissez un commentaire.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'], max: 255 },
    },
  });
  const handlerForm = initFormHandler(form, setForm);
  React.useEffect(() => {
    handlerForm.start();
  }, []);

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_sale_client_comment_add',
          params: { uuid: props.uuid },
          data: handlerForm.getData(),
        },
        (response) => {
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Commentaire créé avec succès.',
                variant: 'success',
              }),
            );
            dispatch(TableReload('api_sale_client_comment_list'));
          } else if (response.status === 400) {
            handlerForm.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          dispatch(LinearProgressMainLayoutDeactivate());
          handlerForm.setFormLoading(false);
          setLoading(false);
          setOpenDialog(false);
        },
      );
    }
  };

  return (
    <>
      <TableComponent
        id={'api_sale_client_comment_list'}
        title={'Commentaires'}
        columns={columns}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SALE_CLIENT') || hasRight(authenticationReducer, 'ROLE_SALE_CLIENT_GET')
            ? {
                label: 'Ajouter un commentaire',
                onClick: () => {
                  handlerForm.reset();
                  setOpenDialog(true);
                },
              }
            : false
        }
        promiseData={(resolve) => {
          ApiHandler.get(
            {
              route: 'api_sale_client_comment_list',
              params: { uuid: props.uuid },
            },
            (response) => {
              const data = response.data;
              for (const index in data) {
                data[index].createdAt = moment(data[index].createdAt).fromNow();
              }
              resolve(data);
            },
          );
        }}
      />

      <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ fontSize: 15 }}>Ajouter un commentaire</DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <TextFieldComponent id={'comment'} handler={handlerForm} multiline={true} />
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setOpenDialog(false)}
            disabled={loading}
          />
          <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaleClientCommentList;
