import { Visibility } from '@mui/icons-material';
import { Box, Grid, Slide } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { CustomPagination } from '../../../Component/DataGrid/CustomPagination';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import FooterWithTotalGeneral from '../../../Component/DataGrid/FooterWithTotalGeneral';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import SelectComponent, { SelectOption } from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { displayErrorMessage } from '../../../Error/Errors';
import { setEndDateFilter, setStartDateFilter } from '../../../Filter/DateFilters';
import { fetchPurveyors, fetchPurveyorTypes } from '../../../Filter/FetchDropdownData';
import ApiHandler from '../../../Handler/ApiHandler';
import { Form } from '../../../Handler/FormHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { styleDataGridDefault } from '../../../Style/styleDataGridDefault';
import { listingStyles } from '../../../Style/useStyles';
import { exportFloatFormat } from '../../../Util/ExportNumberFormatter';
import { formatCurrencyToFloat, formatNumberToEurCurrency } from '../../../Util/NumberTool';
import { floatSortComparator } from '../../../Util/SortNumber';

type PurveyorData = {
  purveyorId: number;
  purveyor: string;
  purveyorType: string;
  totalCAperPurveyor: number;
};

const StatisticBuyCa: React.FC = () => {
  const classes = listingStyles();

  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState<boolean>(false);
  const [optionsPurveyor, setOptionsPurveyor] = useState<SelectOption[]>([]);
  const [optionsPurveyorType, setOptionsPurveyorType] = useState<SelectOption[]>([]);
  const [cellsTable, setCellsTable] = useState<PurveyorData[]>([]);
  const [generalTotalCA, setGeneralTotalCA] = useState<number>(0);

  const [formSearch, setFormSearch] = useState<Form>({
    startDate: {
      name: 'startDate',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue: (cacheTableServer.api_statistic_buy_ca && cacheTableServer.api_statistic_buy_ca?.startDate) || '',
      options: { validation: ['date'] },
    },
    endDate: {
      name: 'endDate',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue: (cacheTableServer.api_statistic_buy_ca && cacheTableServer.api_statistic_buy_ca?.endDate) || '',
      options: { validation: ['date'] },
    },
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseur.',
      type: 'integer',
      defaultValue: (cacheTableServer.api_statistic_buy_ca && cacheTableServer.api_statistic_buy_ca?.purveyor) || null,
    },
    purveyorType: {
      name: 'purveyorType',
      label: 'Type de fournisseur',
      textHelper: 'Rechercher par types de fournisseur.',
      type: 'integer',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index: string, value: string | number | null): void => {
    handlerFormSearch.setValue(index, value);
  };

  useEffect(() => {
    handlerFormSearch.start();
    fetchPurveyors(setOptionsPurveyor);
    fetchPurveyorTypes(setOptionsPurveyorType);
  }, []);

  const onSearch = useCallback(() => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);
    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();
    const filters = {
      startDate: setStartDateFilter(formSearch.startDate.value),
      endDate: setEndDateFilter(formSearch.endDate.value),
      purveyor: datas.purveyor === null || !datas.purveyor ? '' : datas.purveyor,
      purveyorType: datas.purveyorType === 0 || !datas.purveyorType ? '' : datas.purveyorType,
    };

    ApiHandler.get({ route: 'api_statistic_buy_ca', data: filters }, (response) => {
      if (response.status >= 200 && response.status < 300) {
        const { generalTotalCA, ...dataByPurveyor } = response.data;

        setCellsTable(dataByPurveyor as PurveyorData[]);
        setGeneralTotalCA(generalTotalCA);
      } else {
        displayErrorMessage(response);
      }

      setDisabledStatusButtonSearch(false);
      setLoadingContent(false);
      dispatch(LinearProgressMainLayoutDeactivate());
    });
  }, [formSearch]);

  const columns: ({ hidden?: boolean } & GridColDef)[] = [
    {
      field: 'purveyorId',
      headerName: 'ID',
      hidden: true,
      minWidth: 110,
    },
    {
      field: 'purveyor',
      headerName: 'Fournisseur',
      hideable: false,
      flex: 2,
      minWidth: 300,
    },
    {
      field: 'purveyorType',
      headerName: 'Type de fournisseur',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'totalCAperPurveyor',
      headerName: "Chiffre d'affaire facturé",
      flex: 1,
      minWidth: 200,
      sortComparator: floatSortComparator,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <strong>{formatNumberToEurCurrency(params.value)}</strong>,
      valueFormatter: (value) => exportFloatFormat(value),
      cellClassName: (params) => {
        return clsx('amount', {
          smaller: formatCurrencyToFloat(params.value) < 10_000,
          greater: formatCurrencyToFloat(params.value) > 100_000,
        });
      },
    },
    {
      field: 'actions',
      headerName: 'Détails',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <Link
          to={`${getRoutePathname('statistic_buy_portfolio_details')}?purveyorId=${params.row.purveyorId}`}
          target="_blank"
        >
          <Visibility />
        </Link>,
      ],
    },
  ];

  const rows = Object.values(cellsTable).map((row) => ({ ...row, id: row.purveyorId }));

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats fournisseur CA', context: 'Statistiques' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title="Filtres" />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <DatePickerComponent
                  id="startDate"
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('startDate', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <DatePickerComponent
                  id="endDate"
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('endDate', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <SelectComponent
                  id="purveyor"
                  options={optionsPurveyor}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('purveyor', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <SelectComponent
                  id="purveyorType"
                  options={optionsPurveyorType}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('purveyorType', val)}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={1} xl={1}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label="Rechercher"
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          sx={{
            ...styleDataGridDefault,
            '& .amount.greater': { color: 'brown' },
            '& .amount.smaller': { color: 'gray' },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={rows}
          disableColumnMenu
          rowHeight={25}
          slots={{
            toolbar: () => <CustomToolbar fileName="stats_purveyor_ca" />,
            pagination: CustomPagination,
            footer: () => <FooterWithTotalGeneral totalGeneralValue={generalTotalCA} />,
          }}
          initialState={{
            columns: { columnVisibilityModel: { purveyorId: false } },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          ignoreDiacritics
          disableRowSelectionOnClick
        />
      </Box>
    </ContentViewComponent>
  );
};

export default StatisticBuyCa;
