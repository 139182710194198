import { AppRegistration, DeleteOutline } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import AutoCompleteComponent from '../../../../Component/AutoCompleteComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import InputFileComponent from '../../../../Component/InputFileComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TableComponent from '../../../../Component/TableComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';
import { isNumber } from '../../../../Util/NumberTool';

function BuyPurveyorForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  // Order Purveyor Form
  const [onlyRead, setOnlyRead] = React.useState(false);
  const [totalPriceHT, setTotalPriceHT] = React.useState(0);
  const [showDelete, setShowDelete] = React.useState(false);
  const [purveyor, setPurveyor] = React.useState(null);
  const [orderReady, setOrderReady] = React.useState(false);
  const [form, setForm] = React.useState({
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Recherchez un fournisseur.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    deposit: {
      name: 'deposit',
      label: 'Dépôt de réception',
      textHelper: 'Sélectionnez un dépôt.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    deliveryAt: {
      name: 'deliveryAt',
      label: 'Date prévue de livraison',
      textHelper: 'Saisissez la date prévue de livraison.',
      type: 'date',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    depositAddress: {
      name: 'depositAddress',
      label: 'Adresse de livraison',
      textHelper: 'Choisissez une adresse de livraison.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const [optionsDeposit, setOptionsDeposit] = React.useState([]);
  const [optionsDepositAddress, setOptionsDepositAddress] = React.useState([]);
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const handlerForm = initFormHandler(form, setForm);
  const save = (isValid) => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      if (params.id) {
        ApiHandler.post(
          {
            route: 'api_buy_order_purveyor_edit',
            data: {
              byProducts: byProducts,
              purveyor: purveyor,
              deposit: form.deposit.value,
              deliveryAt: form.deliveryAt.value,
              depositAddress: form.depositAddress.value,
              isValid: isValid,
            },
            params: { id: params.id },
          },
          (response) => {
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Commande enregistrée avec succès.',
                  variant: 'success',
                }),
              );
              navigate(getRoutePathname('buy_order_purveyor', { id: response.data.id }));
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      } else {
        ApiHandler.post(
          {
            route: 'api_buy_order_purveyor_add',
            data: {
              byProducts: byProducts,
              purveyor: purveyor,
              deposit: form.deposit.value,
              depositAddress: form.depositAddress.value,
              deliveryAt: form.deliveryAt.value,
            },
          },
          (response) => {
            if (response.status === 200) {
              dispatch(
                SnackbarOpen({
                  text: 'Commande enregistrée avec succès.',
                  variant: 'success',
                }),
              );
              navigate(getRoutePathname('buy_order_purveyor', { id: response.data.id }));
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      }
    }
  };
  const deleteOrder = () => {
    handlerForm.setFormLoading(true);
    setIsLoadingForm(true);
    dispatch(LinearProgressMainLayoutActivate());

    ApiHandler.delete(
      {
        route: 'api_buy_order_purveyor_delete',
        params: { id: params.id },
      },
      (response) => {
        if (response.status === 200) {
          navigate(getRoutePathname('buy_order_purveyor_list'));

          dispatch(
            SnackbarOpen({
              text: 'Commande supprimée avec succès.',
              variant: 'success',
            }),
          );
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }

        dispatch(LinearProgressMainLayoutDeactivate());
        handlerForm.setFormLoading(false);
        setIsLoadingForm(false);
      },
    );
  };
  const loadPurveyor = (text, callback) => {
    ApiHandler.get(
      {
        route: 'api_auto_complete_purveyor',
        data: { text: text },
      },
      (response) => {
        if (response.status === 200) {
          callback(response.data);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };

  React.useEffect(() => {
    handlerForm.start();
  }, []);
  React.useEffect(() => {
    setPurveyor(form.purveyor.value);
    if (form.purveyor.value && params.id === 'add') {
      ApiHandler.get(
        { route: 'api_buy_order_purveyor_delivery', data: { purveyor: form.purveyor.value } },
        (response) => {
          handlerForm.setValue('deliveryAt', response.data.deliveryAt);
        },
      );
      ApiHandler.get(
        { route: 'api_buy_order_purveyor_deposit_default', data: { purveyor: form.purveyor.value } },
        (response) => {
          handlerForm.setValue('deposit', response.data.depositDefault);
        },
      );
    }
  }, [form.purveyor.value]);
  React.useEffect(() => {
    if (form.deposit.value) {
      ApiHandler.get({ route: 'api_select_deposit_address', data: { deposit: form.deposit.value } }, (response) => {
        setOptionsDepositAddress(response.data);

        let defaultSelection = false;
        for (const index in response.data) {
          if (response.data[index].defaultSelection) {
            defaultSelection = response.data[index].value;
          }
        }

        if (
          defaultSelection &&
          (!props.purveyorOrder || (props.purveyorOrder && form.deposit.value !== props.purveyorOrder.deposit))
        ) {
          handlerForm.setValue('depositAddress', defaultSelection);
        }
      });
    } else {
      setOptionsDepositAddress([]);
    }

    if (props.purveyorOrder && form.deposit.value !== props.purveyorOrder.deposit) {
      handlerForm.setValue('depositAddress', '');
    }
  }, [form.deposit.value]);
  React.useEffect(() => {
    if (props.purveyorOrder) {
      handlerForm.form.purveyor.valueText = props.purveyorOrder.purveyorName;
      handlerForm.setValue('purveyor', props.purveyorOrder.purveyor);
      handlerForm.setValue('deposit', props.purveyorOrder.deposit);
      handlerForm.setValue('depositAddress', props.purveyorOrder.depositAddress);
      handlerForm.setValue('deliveryAt', props.purveyorOrder.deliveryAt);
      setPurveyor(props.purveyorOrder.purveyor);
      setByProducts(props.purveyorOrder.byProducts);

      setOnlyRead(props.purveyorOrder.state > 2);
    }
    ApiHandler.get({ route: 'api_select_deposit' }, (response) => setOptionsDeposit(response.data));
  }, [props.purveyorOrder]);

  // Product
  const columns = onlyRead
    ? [
        {
          name: 'sku',
          label: 'SKU',
          options: { filter: true, sort: true },
        },
        {
          name: 'purveyorReference',
          label: 'Référence fournisseur',
          options: { filter: true, sort: true },
        },
        {
          name: 'name',
          label: 'Nom',
          options: { filter: true, sort: true },
        },
        {
          name: 'amountText',
          label: 'Quantité',
          options: { filter: true, sort: true },
        },
        {
          name: 'priceText',
          label: "Prix d'achat HT",
          options: { filter: true, sort: true },
        },
        {
          name: 'shapingPriceText',
          label: 'Prix de façonnage HT',
          options: { filter: true, sort: true },
        },
      ]
    : [
        {
          name: 'sku',
          label: 'SKU',
          options: { filter: true, sort: true },
        },
        {
          name: 'purveyorReference',
          label: 'Référence fournisseur',
          options: { filter: true, sort: true },
        },
        {
          name: 'name',
          label: 'Nom',
          options: { filter: true, sort: true },
        },
        {
          name: 'amountText',
          label: 'Quantité',
          options: { filter: true, sort: true },
        },
        {
          name: 'priceText',
          label: "Prix d'achat HT",
          options: { filter: true, sort: true },
        },
        {
          name: 'shapingPriceText',
          label: 'Prix de façonnage HT',
          options: { filter: true, sort: true },
        },
        {
          name: 'info',
          label: 'Informations',
          options: { filter: true, sort: true },
        },
        {
          name: 'action',
          label: 'Action',
          options: { filter: false, sort: false },
        },
      ];
  const [byProducts, setByProducts] = React.useState([]);
  const [byProductsLine, setByProductsLine] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formByProduct, setFormByProduct] = React.useState({
    productType: {
      name: 'productType',
      label: 'Type',
      textHelper: "Choisissez le type de l'article.",
      type: 'integer',
      defaultValue: '',
    },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: "Choisissez la marque de l'article.",
      type: 'integer',
      defaultValue: '',
    },
    byProduct: {
      name: 'byProduct',
      label: 'Article',
      textHelper: 'Choisissez un article.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    amount: {
      name: 'amount',
      label: 'Quantité',
      textHelper: "Saisissez la quantité d'article.",
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const handlerFormByProduct = initFormHandler(formByProduct, setFormByProduct);

  const loadByProduct = (text, callback) => {
    ApiHandler.get(
      {
        route: 'api_auto_complete_by_product',
        data: {
          text: text,
          purveyor: purveyor,
          mark: formByProduct.mark.value,
          productType: formByProduct.productType.value,
        },
      },
      (response) => {
        if (response.status === 200) {
          callback(response.data);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };
  const addByProduct = () => {
    if (handlerFormByProduct.checkError() < 1) {
      const newByProduct = handlerFormByProduct.getData();

      for (const index in byProducts) {
        if (newByProduct.byProduct === byProducts[index].byProduct) {
          handlerFormByProduct.setError('byProduct', 'Cet article est déjà dans la commande.');
          return;
        }
      }

      const data = [...byProducts];
      data.push(newByProduct);
      setByProducts(data);
      setOpenDialog(false);
    }
  };
  const getAction = (row, i) => {
    return (
      <div style={{ width: 65 }}>
        <Tooltip title={"Supprimer l'article"} placement="left">
          <IconButton
            onClick={() => {
              const data = [];
              for (const index in byProducts) {
                if (index !== i) data.push(byProducts[index]);
              }
              setByProducts(data);
              setOrderReady(false);
            }}
          >
            <DeleteOutline style={{ color: '#982525' }} />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={() => {
            setLineEdited(i);
            handlerFormLineEdited.setValue('amount', row.amount);
            handlerFormLineEdited.setValue('price', row.price);
            handlerFormLineEdited.setValue('shapingPrice', row.shapingPrice);
          }}
        >
          <AppRegistration style={{ color: '#17a2b8' }} />
        </IconButton>
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(TableReload('api_buy_order_purveyor_by_product_list'));
  }, [byProducts]);

  const [optionsMark, setOptionsMark] = React.useState([]);
  const [optionsProductType, setOptionsProductType] = React.useState([]);
  React.useEffect(() => {
    ApiHandler.get({ route: 'api_select_mark' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsMark(data);
    });
    ApiHandler.get({ route: 'api_select_product_type' }, (response) => {
      const data = [{ value: '', label: '' }, ...response.data];
      setOptionsProductType(data);
    });
  }, []);

  // Edit Line
  const [lineEdited, setLineEdited] = React.useState(null);
  const [formLineEdited, setFormLineEdited] = React.useState({
    amount: {
      name: 'amount',
      label: 'Quantité',
      textHelper: "Saisissez la quantité d'article.",
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    price: {
      name: 'price',
      label: "Prix d'achat HT",
      textHelper: "Saisissez le prix d'achat du sous article chez ce fournisseur.",
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    shapingPrice: {
      name: 'shapingPrice',
      label: 'Prix de façonnage HT',
      textHelper: 'Saisissez le prix de façonnage du sous produit chez ce fournisseur.',
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const handlerFormLineEdited = initFormHandler(formLineEdited, setFormLineEdited);
  const saveLine = () => {
    if (handlerFormLineEdited.checkError() < 1) {
      const data = [...byProducts];
      const line = handlerFormLineEdited.getData();
      data[lineEdited].amount = line.amount;
      data[lineEdited].priceOverride = line.price;
      data[lineEdited].shapingPriceOverride = line.shapingPrice;
      setByProducts(data);
      setLineEdited(null);
      setOrderReady(false);
    }
  };

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title={'Commande fournisseur'} />
      {props.purveyorOrder && props.purveyorOrder.stateText && (
        <span className={classes.state}>{props.purveyorOrder.stateText}</span>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <InputFileComponent
            urlDownload={ApiHandler.route({ route: 'api_buy_order_purveyor_bc_download', params: { id: params.id } })}
            urlUpload={ApiHandler.route({ route: 'api_buy_order_purveyor_bc_upload', params: { id: params.id } })}
            cantUpload={params.id !== 'add' && !onlyRead}
            onUpload={() => {
              navigate(getRoutePathname('buy_order_purveyor', { id: params.id }));
            }}
            cantDownload={params.id !== 'add'}
            namePDF={'BC_PURVEYOR_' + params.id + '.pdf'}
            defaultPDF={
              props.purveyorOrder && props.purveyorOrder.BCUploadAt
                ? ApiHandler.route({ route: 'api_buy_order_purveyor_bc_download', params: { id: params.id } })
                : ''
            }
            label={'Bon de commande'}
            csvUpload={false}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoCompleteComponent
                id={'purveyor'}
                handler={handlerForm}
                loadOptions={loadPurveyor}
                disabled={!!purveyor || onlyRead}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerForm} disabled={onlyRead} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <DatePickerComponent id={'deliveryAt'} handler={handlerForm} disabled={onlyRead} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SelectComponent
                options={optionsDepositAddress}
                id={'depositAddress'}
                handler={handlerForm}
                disabled={onlyRead}
              />
            </Grid>
          </Grid>
        </Grid>

        {!!purveyor && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableComponent
              noShadow={true}
              id={'api_buy_order_purveyor_by_product_list'}
              title={'Articles'}
              columns={columns}
              actionFirst={
                onlyRead
                  ? false
                  : {
                      label: 'Ajouter un article',
                      onClick: () => {
                        handlerFormByProduct.reset();
                        setOpenDialog(true);
                      },
                    }
              }
              actionSecond={{
                label: 'Télécharger le BC',
                onClick: () => {
                  fetch(ApiHandler.route({ route: 'api_buy_order_purveyor_download', params: { id: params.id } }), {
                    method: 'get',
                    mode: 'no-cors',
                    referrerPolicy: 'no-referrer',
                  })
                    .then((res) => res.blob())
                    .then((res) => {
                      const aElement = document.createElement('a');
                      aElement.setAttribute('download', 'BC_PURVEYOR_' + params.id + '_ns.pdf');
                      const href = URL.createObjectURL(res);
                      aElement.href = href;
                      aElement.setAttribute('target', '_blank');
                      aElement.click();
                      URL.revokeObjectURL(href);
                    });
                },
              }}
              promiseData={(resolve) => {
                if (byProducts.length > 0 && purveyor) {
                  ApiHandler.post(
                    {
                      route: 'api_buy_order_purveyor_check',
                      data: { byProducts: byProducts, purveyor: purveyor },
                    },
                    (response) => {
                      if (response.status === 200) {
                        const data = response.data;
                        let orderReadyTMP = true;
                        let totalPriceHTTMP = 0;

                        const updatedData = data.map((item, index) => {
                          const updatedItem = { ...item };

                          // Attach action
                          updatedItem.action = getAction(item, index);

                          // Handle info mapping
                          const info = updatedItem.info;
                          if (info && info.length > 0) {
                            updatedItem.info = (
                              <div>
                                {info.map((element, key) => {
                                  if (element.type === 'error') orderReadyTMP = false;
                                  return (
                                    <p style={{ margin: 2 }} key={key} className={classes['m' + element.type]}>
                                      {element.message}
                                    </p>
                                  );
                                })}
                              </div>
                            );
                          } else {
                            updatedItem.info = '';
                          }

                          // Handle price overrides
                          if (byProducts[index].priceOverride || byProducts[index].priceOverride === 0) {
                            updatedItem.price = byProducts[index].priceOverride;
                          }
                          if (byProducts[index].shapingPriceOverride || byProducts[index].shapingPriceOverride === 0) {
                            updatedItem.shapingPrice = byProducts[index].shapingPriceOverride;
                          }

                          // Calculate total price
                          totalPriceHTTMP +=
                            (parseFloat(updatedItem.price) + parseFloat(updatedItem.shapingPrice)) * updatedItem.amount;

                          // Format amount and price texts
                          updatedItem.amountText = isNumber(updatedItem.amount)
                            ? updatedItem.amount.toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                            : '-';
                          updatedItem.priceText = isNumber(updatedItem.price)
                            ? parseFloat(updatedItem.price).toLocaleString('fr-FR', {
                                minimumFractionDigits: 2,
                              })
                            : '-';
                          updatedItem.shapingPriceText = isNumber(updatedItem.shapingPrice)
                            ? parseFloat(updatedItem.shapingPrice).toLocaleString('fr-FR', {
                                minimumFractionDigits: 2,
                              })
                            : '-';

                          return updatedItem;
                        });

                        setOrderReady(orderReadyTMP);
                        setByProductsLine(updatedData);
                        setTotalPriceHT(totalPriceHTTMP.toFixed(2));

                        resolve(updatedData);
                      } else {
                        dispatch(
                          SnackbarOpen({
                            text: response?.error?.message || "Une erreur inattendue s'est produite.",
                            variant: 'error',
                          }),
                        );
                      }
                    },
                  );
                } else {
                  resolve([]);
                }
              }}
            />
          </Grid>
        )}
      </Grid>

      <div style={{ height: 10, padding: '20px 20px' }}>
        <div style={{ float: 'right' }}>Total HT : {totalPriceHT} €</div>
      </div>

      {!onlyRead && (
        <div style={{ position: 'absolute', bottom: 0, width: '100%', marginLeft: '-12px' }}>
          <ButtonComponent
            label={'Enregistrer'}
            className={classes.button}
            onClick={() => save(false)}
            loading={isLoadingForm}
          />
          {!props.purveyorOrder && (
            <Link to={getRoutePathname('buy_purveyor_order_list')} className={'link'}>
              <ButtonComponent color={'#982525'} label={'Annuler'} className={classes.button} loading={isLoadingForm} />
            </Link>
          )}
          {!!props.purveyorOrder && (
            <ButtonComponent
              color={!orderReady || !props.purveyorOrder.BCUploadAt ? 'rgba(132,132,132,0.48)' : '#28a745'}
              label={'Passer commande'}
              onClick={() => save(true)}
              className={classes.button}
              loading={isLoadingForm}
              disabled={!orderReady || !props.purveyorOrder.BCUploadAt}
            />
          )}
          {!!props.purveyorOrder && (
            <ButtonComponent
              color={'#982525'}
              label={'Supprimer la commande'}
              className={classes.button}
              onClick={() => setShowDelete(true)}
              loading={isLoadingForm}
            />
          )}
        </div>
      )}

      <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ fontSize: 15 }}>{'Ajouter un article'}</DialogTitle>
        <DialogContent style={{ minWidth: '40vw', height: 200 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectComponent id={'productType'} options={optionsProductType} handler={handlerFormByProduct} />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormByProduct} />
            </Grid>
            <Grid item xs={12}>
              <AutoCompleteComponent id={'byProduct'} handler={handlerFormByProduct} loadOptions={loadByProduct} />
              <TextFieldComponent id={'amount'} handler={handlerFormByProduct} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} />
          <ButtonComponent label={'Enregistrer'} onClick={addByProduct} />
        </DialogActions>
      </Dialog>

      <Dialog open={lineEdited !== null} maxWidth={'xl'} onClose={() => setLineEdited(null)}>
        <DialogTitle style={{ fontSize: 15 }}>
          {byProductsLine[lineEdited] && byProductsLine[lineEdited].name}
        </DialogTitle>
        <DialogContent style={{ minWidth: '40vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldComponent id={'amount'} handler={handlerFormLineEdited} />
              <TextFieldComponent id={'price'} handler={handlerFormLineEdited} />
              <TextFieldComponent id={'shapingPrice'} handler={handlerFormLineEdited} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setLineEdited(null)} />
          <ButtonComponent label={'Enregistrer'} onClick={saveLine} />
        </DialogActions>
      </Dialog>

      <Dialog open={showDelete} maxWidth={'xl'} onClose={() => setShowDelete(false)}>
        <DialogTitle style={{ fontSize: 15 }}>{'Confirmation'}</DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Êtes-vous sûr de vouloir supprimer la commande ?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Non'} onClick={() => setShowDelete(false)} />
          <ButtonComponent label={'Oui'} onClick={deleteOrder} />
        </DialogActions>
      </Dialog>
    </ShadowBoxComponent>
  );
}

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 50,
    height: '100%',
  },
  button: {
    margin: '10px 15px 10px -5px !important',
    float: 'right',
  },
  mwarning: {
    color: '#a47b00',
  },
  merror: {
    color: '#982525',
  },
  msuccess: {
    color: '#28a745',
  },
  minfo: {
    color: '#35A2EB',
  },
  state: {
    backgroundColor: '#9c27b0',
    color: '#ffffff',
    borderRadius: 25,
    padding: '5px 10px',
    marginLeft: 10,
    fontSize: 11,
    position: 'absolute',
    top: 7,
    right: 14,
  },
});

export default BuyPurveyorForm;
