import React from 'react';

import TableComponent from '../../../../Component/TableComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { isNumber } from '../../../../Util/NumberTool';

function BuyProductStockList(props) {
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'deposit',
      label: 'Dépôt',
      options: { filter: true, sort: true },
    },
    {
      name: 'amount',
      label: 'Quantité',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountReserved',
      label: 'Quantité réservée',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountAvailable',
      label: 'Quantité disponible',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountCurrentSupply',
      label: 'Quantité en cours d’approvisionnement',
      options: { filter: true, sort: true },
    },
    {
      name: 'amountTerm',
      label: 'Quantité à terme',
      options: { filter: true, sort: true },
    },
  ];

  return (
    <TableComponent
      id={'api_buy_product_by_product_stock_list'}
      noShadow={true}
      title={'Stocks'}
      columns={columns}
      promiseData={(resolve) => {
        ApiHandler.get(
          {
            route: 'api_buy_product_by_product_stock_list',
            params: { productId: props.product.id, byProductId: props.byProduct.id },
          },
          (response) => {
            const data = response.data;
            for (const index in data) {
              data[index].amountText = isNumber(data[index].amountText)
                ? parseFloat(data[index].amountText).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                : '-';
              data[index].amountReservedText = isNumber(data[index].amountReservedText)
                ? parseFloat(data[index].amountReservedText).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                : '-';
              data[index].amountAvailableText = isNumber(data[index].amountAvailableText)
                ? parseFloat(data[index].amountAvailableText).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                : '-';
              data[index].amountCurrentSupplyText = isNumber(data[index].amountCurrentSupplyText)
                ? parseFloat(data[index].amountCurrentSupplyText).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                : '-';
              data[index].amountTermText = isNumber(data[index].amountTermText)
                ? parseFloat(data[index].amountTermText).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                : '-';
            }
            resolve(data);
          },
        );
      }}
    />
  );
}

export default BuyProductStockList;
