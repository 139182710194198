import moment from 'moment';

type FormFieldType = 'text' | 'password' | 'integer' | 'float' | 'boolean' | 'array' | 'date' | 'datetime' | 'file';

type ValidationOptions = {
  validation?: string[];
  min?: number;
  max?: number;
  equal?: number;
  inf?: number;
  sup?: number;
  minDate?: string;
  maxDate?: string;
};

export type FormField = {
  name: string;
  label?: string;
  textHelper?: string;
  type: FormFieldType;
  value?: any;
  valueText?: string;
  defaultValue?: any;
  defaultValueText?: string;
  error?: string;
  disabled?: boolean;
  options?: ValidationOptions;
};

export type Form = Record<string, FormField>;

export const initFormHandler = (form: Form, setForm: (form: Form) => void) => {
  const start = () => {
    Object.keys(form).forEach((key) => {
      const field = form[key];
      field.value = parseType(field.defaultValue, field.type) || '';
      field.valueText = field.defaultValueText || '';
      field.error = '';
    });

    setForm({ ...form });
  };
  const setValue = (index: string, value: any) => {
    form[index].value = parseType(value, form[index].type);
    setForm({ ...form });
  };
  const setValueText = (index: string, value: string) => {
    form[index].valueText = value || '';
    setForm({ ...form });
  };
  const getData = (): Record<string, any> => {
    const data: Record<string, any> = {};
    Object.keys(form).forEach((key) => {
      data[form[key].name] = parseType(form[key].value, form[key].type);
    });
    return data;
  };
  const getValue = (key: string): any => {
    return parseType(form[key].value, form[key].type);
  };
  const checkError = (): number => {
    let errorCount = 0;
    Object.keys(form).forEach((key) => {
      const field = form[key];
      field.error = getErrorByField(field);
      if (field.error) {
        errorCount++;
      }
    });
    setForm({ ...form });

    return errorCount;
  };
  const setFormLoading = (value: boolean) => {
    Object.keys(form).forEach((key) => {
      form[key].disabled = value;
    });
    setForm({ ...form });
  };
  const setDisabled = (index: string, value: boolean) => {
    form[index].disabled = value;
    setForm({ ...form });
  };
  const setError = (index: string, message: string) => {
    form[index].error = message;
    setForm({ ...form });
  };
  const setErrorApi = (error?: { fields?: { reference: string; message: string }[] }) => {
    if (error?.fields) {
      error.fields.forEach(({ reference, message }) => {
        if (form[reference]) {
          form[reference].error = message;
        }
      });
    }
    setForm({ ...form });
  };
  const setDataApi = (data: Record<string, any>) => {
    Object.keys(data).forEach((key) => {
      if (form[key]) {
        if (data[key] && typeof data[key] === 'object' && !Array.isArray(data[key])) {
          form[key].value = parseType(data[key].value, form[key].type);
          form[key].valueText = data[key].label || '';
        } else {
          form[key].value = parseType(data[key], form[key].type);
        }
      }
    });
    setForm({ ...form });
  };

  const parseType = (value: any, type: FormFieldType): any => {
    switch (type) {
      case 'text':
      case 'password':
        return value || '';
      case 'integer':
        return typeof value === 'number' ? value : value ? parseInt(value, 10) : '';
      case 'float':
        return typeof value === 'number' ? value : value ? parseFloat(value).toFixed(5) : '';
      case 'boolean':
        return typeof value === 'boolean' ? value : false;
      case 'array':
        return value || [];
      case 'date':
        return value ? moment(value).format('YYYY-MM-DD') : '';
      case 'datetime':
        return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
      case 'file':
      default:
        return value;
    }
  };

  const getErrorByField = (field: FormField) => {
    const { validation = [], min, max, equal, minDate, maxDate } = field.options || {};
    const value = field.value;

    if ((validation.includes('required') && value === '') || value === null || value === undefined) {
      return 'Ce champ est obligatoire.';
    }

    for (const rule of validation) {
      switch (rule) {
        case 'email':
          if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(value || '')) {
            return "Cet email n'est pas valide.";
          }
          break;
        case 'codePostal':
          if (!/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(value || '')) {
            return "Ce code postal n'est pas valide.";
          }
          break;
        case 'siteInternet':
          if (!/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)?$/.test(value || '')) {
            return "Cette adresse du site internet n'est pas valide.";
          }
          break;
        case 'siret':
          if (!/^\d{14}$/.test(value || '')) {
            return 'Ce Siret ne contient pas 14 chiffres.';
          }
          break;
        case 'siren':
          if (!/^\d{9}$/.test(value || '')) {
            return 'Ce Siren ne contient pas 9 chiffres.';
          }
          break;
        case 'float':
          if (!/^-?\d*(\.\d+)?$/.test(value || '')) {
            return 'Ce champ doit être un nombre décimal.';
          }
          break;
        case 'integer':
          if (!/^-?\d+$/.test(value || '')) {
            return 'Ce champ doit être un nombre entier.';
          }
          break;
        case 'datetime':
          if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value || '')) {
            return 'Ce format de date est invalide.';
          }
          break;
        case 'date':
          if (!/^\d{4}-\d{2}-\d{2}$/.test(value || '')) {
            return 'Ce format de date est invalide.';
          }
          break;
        case 'alpha':
          if (!/^[a-zA-Z]+$/.test(value || '')) {
            return 'Ce champ doit être une valeur alphabétique.';
          }
          break;
        case 'numeric':
          if (!/^\d+$/.test(value || '')) {
            return 'Ce champ doit être une valeur numérique.';
          }
          break;
      }
    }

    if (field.type === 'float' || field.type === 'integer') {
      if (min !== undefined && max !== undefined && (value < min || value > max)) {
        return `Ce nombre doit être compris entre ${min} et ${max}.`;
      }

      if (equal !== undefined && value !== equal) {
        return `Ce nombre doit être exactement ${equal}.`;
      }

      if (min !== undefined && value < min) {
        return `Ce nombre doit être au minimum ${min}.`;
      }

      if (max !== undefined && value > max) {
        return `Ce nombre ne peut pas dépasser ${max}.`;
      }
    } else {
      const length = value.toString().length;
      if (min !== undefined && max !== undefined && (length < min || length > max)) {
        return `Ce champ doit être compris entre ${min} et ${max} caractères.`;
      }

      if (equal !== undefined && length !== equal) {
        return `Ce champ doit contenir exactement ${equal} caractères.`;
      }

      if (min !== undefined && length < min) {
        return `Ce champ doit contenir au minimum ${min} caractères.`;
      }

      if (max !== undefined && length > max) {
        return `Ce champ ne peut pas dépasser ${max} caractères.`;
      }

      // Date Constraints
      if (minDate && moment(value).isBefore(moment(minDate))) {
        return `La date ne doit pas être inférieure au ${moment(minDate).format('L')}.`;
      }

      if (maxDate && moment(value).isAfter(moment(maxDate))) {
        return `La date ne doit pas être supérieure au ${moment(maxDate).format('L')}.`;
      }
    }

    return '';
  };

  return {
    form: form,
    setForm: setForm,
    start: start,
    getErrorByField: getErrorByField,
    setValue: setValue,
    setValueText: setValueText,
    setError: setError,
    setDisabled: setDisabled,
    setErrorApi: setErrorApi,
    checkError: checkError,
    getData: getData,
    getValue: getValue,
    reset: start,
    setFormLoading: setFormLoading,
    setDataApi: setDataApi,
  };
};
