import { Fade, Grid } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import BuyOrderPurveyorForm from './BuyOrderPurveyorComponents/BuyOrderPurveyorForm';

function BuyOrderPurveyor() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  // Global
  const [isLoadingContent, setIsLoadingContent] = React.useState(true);
  const [purveyorOrder, setPurveyorOrder] = React.useState(null);
  React.useEffect(() => {
    if (params.id === 'add') {
      setPurveyorOrder(null);

      setTimeout(() => setIsLoadingContent(false), 1500);
    } else {
      ApiHandler.get(
        {
          route: 'api_buy_order_purveyor_get',
          params: { id: params.id },
        },
        (response) => {
          if (response.status === 200) {
            setPurveyorOrder(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
            setPurveyorOrder(null);
          }

          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Créer une commande fournisseur' : 'Éditer une commande fournisseur',
        context: 'Achat',
        links: [{ path: getRoutePathname('buy_order_purveyor_list'), label: 'Liste des commandes fournisseurs' }],
      }}
    >
      <Grid container spacing={2}>
        <Fade in timeout={750}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BuyOrderPurveyorForm purveyorOrder={purveyorOrder} />
          </Grid>
        </Fade>
      </Grid>

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BuyOrderPurveyor;
