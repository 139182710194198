import { Edit } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide } from '@mui/material';
import React from 'react';

import { LinearProgressMainLayoutActivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function BuyProductManageWeightList() {
  const [optionsMark, setOptionsMark] = React.useState([]);
  const [openDialogEditWeight, setOpenDialogEditWeight] = React.useState(false);
  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const columns = [
    {
      name: 'mark',
      label: 'Marque',
      options: { filter: true, sort: true },
    },
    {
      name: 'productConcatName',
      label: 'Nom du produit',
      options: { filter: true, sort: true },
    },
    {
      name: 'byProductTheoreticalWeight',
      label: 'Poids renseigné',
      options: { filter: true, sort: true },
    },
    {
      name: 'ean',
      label: 'EAN',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    name: {
      name: 'name',
      label: 'Nom',
      textHelper: 'Rechercher par noms.',
      type: 'text',
      defaultValue:
        cacheTableServer.api_buy_product_manage_weight_list && cacheTableServer.api_buy_product_manage_weight_list.name
          ? cacheTableServer.api_buy_product_manage_weight_list.name
          : '',
    },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: 'Rechercher par marques.',
      type: 'integer',
      defaultValue:
        cacheTableServer.api_buy_product_manage_weight_list && cacheTableServer.api_buy_product_manage_weight_list.mark
          ? cacheTableServer.api_buy_product_manage_weight_list.mark
          : 0,
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage.api_buy_product_manage_weight_list = 0;
    cacheTableServer.api_buy_product_manage_weight_list.page = 0;
    cacheTableServer.api_buy_product_manage_weight_list[index] = value;
    dispatch(TableReload('api_buy_product_manage_weight_list'));
  };

  const [formWeight, setFormWeight] = React.useState({
    weight: {
      name: 'weight',
      label: 'Poids',
      textHelper: 'Saisissez le poids.',
      type: 'float',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    ean: {
      name: 'ean',
      label: 'EAN',
      textHelper: 'Saisissez le ean.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });

  const handlerFormWeight = initFormHandler(formWeight, setFormWeight);

  React.useEffect(() => {
    handlerFormSearch.start();

    // get options Product
    ApiHandler.get({ route: 'api_select_mark' }, (response) => {
      const data = [{ value: 0, label: 'Toutes' }, ...response.data];
      setOptionsMark(data);
    });
  }, []);

  const handleSelectRow = (row) => {
    handlerFormWeight.reset();
    handlerFormWeight.setValue('ean', row.ean);
    handlerFormWeight.setValue('weight', row.byProductTheoreticalWeight);
    setProduct(row);
    setOpenDialogEditWeight(true);
  };

  const closeModalUpdate = () => {
    setProduct(null);
    setOpenDialogEditWeight(false);
  };

  const getAction = (row) => (
    <IconButton onClick={() => handleSelectRow(row)}>
      <Edit style={{ color: '#17a2b8' }} />
    </IconButton>
  );

  const save = () => {
    if (handlerFormWeight.checkError() < 1) {
      handlerFormWeight.setFormLoading(true);
      setLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_buy_product_manage_weight_edit',
          data: handlerFormWeight.getData(),
          params: { id: product.byProductId },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Modification enregistrée avec succès.',
                variant: 'success',
              }),
            );
            setProduct(null);
            dispatch(TableReload('api_buy_product_manage_weight_list'));
          } else if (response.status === 400) {
            handlerFormWeight.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          handlerFormWeight.setFormLoading(false);
          setLoading(false);
          setOpenDialogEditWeight(false);
        },
      );
    }
  };

  return (
    <ContentViewComponent
      breadcrumbs={{
        title: 'Liste des articles',
        context: 'Achat',
      }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                <TextFieldComponent
                  id={'name'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('name', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectComponent
                  id={'mark'}
                  options={optionsMark}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('mark', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_buy_product_manage_weight_list'}
        title={'Produits fabriqués'}
        columns={columns}
        search={false}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            name: formSearch.name.value ? formSearch.name.value : '',
            mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
          };

          ApiHandler.get(
            {
              route: 'api_buy_product_manage_weight_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;

              for (const index in data) {
                data[index].action = getAction(data[index]);
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
      <br />
      <br />

      <Dialog open={openDialogEditWeight} maxWidth={'xl'} onClose={() => setOpenDialogEditWeight(false)}>
        <DialogTitle style={{ fontSize: 15 }}>Modifier le poids</DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldComponent id={'weight'} handler={handlerFormWeight} />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent id={'ean'} handler={handlerFormWeight} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => closeModalUpdate()} disabled={loading} />
          <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading} />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

export default BuyProductManageWeightList;
