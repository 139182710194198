import { Article, Face, FaceRetouchingOff } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function ShopSalesList() {
  const [loadingContent, setLoadingContent] = React.useState(true);
  const navigate = useNavigate();
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [optionsOrderClientState, setOptionsOrderClientState] = React.useState([]);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'Etat',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'isGuest',
      label: 'Guest',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    return (
      <>
        <Tooltip title={'Visualiser'} placement="left">
          <Link to={getRoutePathname('shop_seller_order_client', { uuid: row.uuid })}>
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  };

  // FormSearch
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par référence.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_order_client_list'] && cacheTableServer['api_shop_order_client_list'].number
          ? cacheTableServer['api_shop_order_client_list'].number
          : '',
    },
    clientEmail: {
      name: 'clientEmail',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_order_client_list'] && cacheTableServer['api_shop_order_client_list'].clientEmail
          ? cacheTableServer['api_shop_order_client_list'].clientEmail
          : '',
    },
    clientName: {
      name: 'clientName',
      label: 'Client',
      textHelper: 'Rechercher par client.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_shop_order_client_list'] && cacheTableServer['api_shop_order_client_list'].clientName
          ? cacheTableServer['api_shop_order_client_list'].clientName
          : '',
    },
    orderClientState: {
      name: 'orderClientState',
      label: 'État',
      textHelper: 'Rechercher par état.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_shop_order_client_list'] &&
        cacheTableServer['api_shop_order_client_list'].orderClientState
          ? cacheTableServer['api_shop_order_client_list'].orderClientState
          : 0,
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_shop_order_client_list'] = 0;
    cacheTableServer['api_shop_order_client_list'].page = 0;
    cacheTableServer['api_shop_order_client_list'][index] = value;
    dispatch(TableReload('api_shop_order_client_list'));
  };

  React.useEffect(() => {
    ApiHandler.post(
      {
        route: 'api_shop_sales_check_opening',
        params: { shopId: authenticationReducer.shopSelected },
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.open === 0) {
            dispatch(
              SnackbarOpen({
                text: 'Vous devez ouvrir votre caisse pour accéder à cet onglet.',
                variant: 'error',
              }),
            );
            navigate(getRoutePathname('shop'));
          }
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );

    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_order_client_state' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsOrderClientState(data);
    });

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent loading={loadingContent} breadcrumbs={{ title: 'Liste des ventes', context: 'Vente' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <TextFieldComponent
                  id={'number'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('number', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <TextFieldComponent
                  id={'clientEmail'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientEmail', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                <TextFieldComponent
                  id={'clientName'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientName', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <SelectComponent
                  id={'orderClientState'}
                  options={optionsOrderClientState}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('orderClientState', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_shop_order_client_list'}
        title={'Commandes client'}
        columns={columns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
            clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
            orderClientState: datas.orderClientState === 0 || !datas.orderClientState ? '' : datas.orderClientState,
          };

          ApiHandler.get(
            {
              route: 'api_shop_order_client_list',
              params: { shopId: authenticationReducer.shopSelected },
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].clientName = data[index].clientName ?? '-';
                data[index].orderClientState = data[index].orderClientState ?? '-';
                data[index].isGuest = data[index].isGuest ? (
                  <Tooltip title={'Guest'} placement="left">
                    <FaceRetouchingOff style={{ color: '#ffc107' }} />
                  </Tooltip>
                ) : (
                  <Face style={{ color: '#28a745' }} />
                );
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default ShopSalesList;
