import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ByProduct } from '#app/typings/order';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../../Action/SnackbarAction';
import { dispatch } from '../../../../../App';
import ButtonComponent from '../../../../../Component/ButtonComponent';
import SelectComponent from '../../../../../Component/SelectComponent';
import TextFieldComponent from '../../../../../Component/TextFieldComponent';
import ApiHandler from '../../../../../Handler/ApiHandler';
import { Form, FormField, initFormHandler } from '../../../../../Handler/FormHandler';

type Props = {
  onClose: () => void;
  uuid: string;
  products: ByProduct[];
};

const DisputeModal: React.FC<Props> = ({ onClose, uuid, products = [] }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [optionsOrderClientLitigeType, setOptionsOrderClientLitigeType] = useState([]);

  useEffect(() => {
    ApiHandler.get({ route: 'api_select_order_client_litige_type' }, (response) => {
      setOptionsOrderClientLitigeType(response.data);
    });
  }, []);

  const [form, setForm] = useState<Form>({
    orderClientLitigeType: {
      name: 'orderClientLitigeType',
      label: 'Type',
      textHelper: 'Choisissez un type de litige.',
      type: 'integer',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    reason: {
      name: 'reason',
      label: 'Raison',
      textHelper: 'Saisissez une raison.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    ...products.reduce((acc: Record<string, FormField>, product) => {
      acc[product.id] = {
        name: product.id.toString(),
        label: 'Quantité',
        textHelper: `Saisissez la quantité de produit en litige (maximum : ${product.amount - product.amountLitige})`,
        type: 'integer',
        defaultValue: 0,
        options: {
          validation: ['required'],
          max: product.amount - product.amountLitige,
        },
      };
      return acc;
    }, {}),
  });

  const handlerForm = initFormHandler(form, setForm);

  const handleSubmit = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setLoading(true);
      dispatch(LinearProgressMainLayoutActivate());

      const data = handlerForm.getData();

      const amountByProducts = Object.entries(data)
        .filter(([key]) => !isNaN(parseInt(key)))
        .map(([key, value]) => ({
          amount: value,
          byProduct: parseInt(key),
        }));

      ApiHandler.post(
        {
          route: 'api_sale_order_client_litige',
          data: {
            orderClientLitigeType: data.orderClientLitigeType,
            reason: data.reason,
            amountByProducts,
          },
          params: { uuid },
        },
        (response) => {
          const success = response.status === 200;
          const errorMessage = response.error?.message || "Une erreur inattendue s'est produite.";

          dispatch(
            SnackbarOpen({
              text: success ? 'Litige enregistré avec succès.' : errorMessage,
              variant: success ? 'success' : 'error',
            }),
          );

          if (success) {
            onClose();
          }

          dispatch(LinearProgressMainLayoutDeactivate());
          handlerForm.setFormLoading(false);
          setLoading(false);
        },
      );
    }
  };

  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <DialogTitle style={{ fontSize: 15 }}>Ouvrir un litige</DialogTitle>
      <DialogContent style={{ minWidth: '30vw' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectComponent id="orderClientLitigeType" options={optionsOrderClientLitigeType} handler={handlerForm} />
          </Grid>
          <Grid item xs={12}>
            <TextFieldComponent id={'reason'} handler={handlerForm} multiline />
          </Grid>

          {products?.map((product, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <p>{product.name}</p>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent id={product.id.toString()} handler={handlerForm} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loading} />
        <ButtonComponent label="Enregistrer" onClick={handleSubmit} loading={loading} />
      </DialogActions>
    </Dialog>
  );
};

export default DisputeModal;
