type DrawerMainLayoutOpenAction = {
  type: 'DrawerMainLayoutOpen';
};

export const DrawerMainLayoutOpen = (): DrawerMainLayoutOpenAction => ({
  type: 'DrawerMainLayoutOpen',
});

type DrawerMainLayoutCloseAction = {
  type: 'DrawerMainLayoutClose';
};

export const DrawerMainLayoutClose = (): DrawerMainLayoutCloseAction => ({
  type: 'DrawerMainLayoutClose',
});
