import { Grid } from '@mui/material';
import React from 'react';

import { TableReload } from '../../../../Action/TableAction';
import { dispatch } from '../../../../App';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TableComponent from '../../../../Component/TableComponent';
import ApiHandler from '../../../../Handler/ApiHandler';

function InventoryManagementPreparerManagerOrderList() {
  const [carrierCount, setCarrierCount] = React.useState({
    carrier_1: 0,
    carrier_2: 0,
    carrier_4: 0,
    carrier_6: 0,
    carrier_7: 0,
    carrier_11: 0,
  });

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'carrier',
      label: 'Transporteur',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'Etat',
      options: { filter: true, sort: true },
    },
  ];

  React.useEffect(() => {
    const interval = setInterval(
      () => dispatch(TableReload('api_inventory_management_preparer_manager_order_list')),
      20000,
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_1.png'} alt="carrier_1" />
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_1}</span>
          </ShadowBoxComponent>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_2.png'} alt="carrier_2" />
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_2}</span>
          </ShadowBoxComponent>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_11.png'} alt="carrier_11" />
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_11}</span>
          </ShadowBoxComponent>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_6.png'} alt="carrier_6" />
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_6}</span>
          </ShadowBoxComponent>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_4.png'} alt="carrier_4" /> TLN
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_4}</span>
          </ShadowBoxComponent>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <ShadowBoxComponent style={{ textAlign: 'center' }}>
            <img style={{ height: 40 }} src={'/image/carrier_7.png'} alt="carrier_7" /> PICKUP
            <br />
            <span style={{ fontSize: 20, fontWeight: 700 }}>{carrierCount.carrier_7}</span>
          </ShadowBoxComponent>
        </Grid>
      </Grid>

      <br />

      <TableComponent
        hideLoading={true}
        id={'api_inventory_management_preparer_manager_order_list'}
        title={'Commandes'}
        columns={columns}
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_inventory_management_preparer_manager_order_list' }, (response) => {
            const data = response.data;
            const count = {
              carrier_1: 0,
              carrier_2: 0,
              carrier_11: 0,
              carrier_7: 0,
              carrier_4: 0,
              carrier_6: 0,
            };
            for (const index in data) {
              switch (data[index].carrierId) {
                case 1:
                  count.carrier_1 += 1;
                  break;
                case 6:
                  count.carrier_6 += 1;
                  break;
                case 2:
                  count.carrier_2 += 1;
                  break;
                case 7:
                  count.carrier_7 += 1;
                  break;
                case 11:
                  count.carrier_11 += 1;
                  break;
                case 4:
                  count.carrier_4 += 1;
                  break;
                default:
                  break;
              }
            }

            setCarrierCount(count);
            resolve(data);
          });
        }}
      />
      <br />
      <br />
    </>
  );
}

export default InventoryManagementPreparerManagerOrderList;
