import { DoneAll, RotateLeft } from '@mui/icons-material';
import { Fade, Grid, Slide } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { hasRight } from '../../../Common/UserCommon';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import BuyProductByProductTabs from './BuyProductComponents/BuyProductByProductTabs';
import BuyProductForm from './BuyProductComponents/BuyProductForm';
import BuyProductPurveyor from './BuyProductComponents/BuyProductPurveyor';

function BuyProduct() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');

  // Sylius
  const [isLoadingSylius, setIsLoadingSylius] = React.useState(null);
  const [isUpdateSylius, setIsUpdateSylius] = React.useState(null);
  const updateSylius = () => {
    setIsLoadingSylius(true);
    ApiHandler.get(
      {
        route: 'api_buy_product_sylius',
        params: { id: params.id },
      },
      (response) => {
        if (response.status === 200) {
          setIsUpdateSylius(response.data.isUpdate);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
          setIsUpdateSylius(false);
        }

        setIsLoadingSylius(false);
      },
    );
  };

  // Global
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoadingContent, setIsLoadingContent] = React.useState(true);
  const [product, setProduct] = React.useState(null);
  React.useEffect(() => {
    if (params.id === 'add') {
      setProduct(null);
      setIsLoadingContent(false);
    } else {
      ApiHandler.get(
        {
          route: 'api_buy_product_get',
          params: { id: params.id },
        },
        (response) => {
          if (response.status === 200) {
            setProduct(response.data);
            setIsUpdateSylius(!!response.data.syliusAt);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Ajouter un article' : 'Éditer un article',
        context: 'Achat',
        links: [{ path: getRoutePathname('buy_product_list'), label: 'Liste des articles' }],
      }}
    >
      {product && product.productType !== 2 && product.productType !== 3 && (
        <ButtonComponent
          small={true}
          style={{ position: 'absolute', top: 25, right: 25 }}
          loading={isLoadingSylius}
          color={isUpdateSylius ? '#006500' : '#ffc107'}
          outlined={true}
          disabled={justWatch}
          label={
            isUpdateSylius ? (
              <span>
                Sylius est à jour <DoneAll style={{ verticalAlign: 'middle' }} />
              </span>
            ) : (
              <span>
                Mettre à jour Sylius <RotateLeft style={{ verticalAlign: 'middle' }} />
              </span>
            )
          }
          onClick={isUpdateSylius ? () => {} : updateSylius}
        />
      )}

      <Grid container spacing={2}>
        <Fade in timeout={750}>
          <Grid item xs={12} sm={12} md={12} lg={product ? 7 : 12} xl={product ? 7 : 12}>
            <BuyProductForm product={product} setIsUpdateSylius={setIsUpdateSylius} />
          </Grid>
        </Fade>

        {product && (
          <Slide direction={'left'} in={true} {...{ timeout: 500 }}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <BuyProductPurveyor />
            </Grid>
          </Slide>
        )}

        {product && (
          <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BuyProductByProductTabs product={product} setIsUpdateSylius={setIsUpdateSylius} />
            </Grid>
          </Slide>
        )}
      </Grid>

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BuyProduct;
