import { Close } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Popover,
  Drawer as DrawerMUI,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { StateType } from '#app/Store';

import { DrawerMainLayoutClose } from '../../../Action/DrawerMainLayoutAction';
import { getMenu } from '../../../Handler/MenuHandler';
import { getRouteByPathname } from '../../../Handler/RouteHandler';

const drawerOpenWidth = 260;
const drawerCloseWidth = 68;

const Drawer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerMainLayoutReducer = useSelector((state: StateType) => state.DrawerMainLayoutReducer);
  const screenReducer = useSelector((state: StateType) => state.ScreenReducer);
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);

  const [expanded, setExpanded] = useState<string>('');
  const [expandedPopover, setExpandedPopover] = useState<{ id: string; anchorEl: HTMLElement | null }>({
    id: '',
    anchorEl: null,
  });
  const [route, setRoute] = useState<any>({});
  const [showDrawerFull, setShowDrawerFull] = useState<boolean>(true);

  const handleTooltipClick = (id: string, event: React.MouseEvent<HTMLElement>): void => {
    if (drawerMainLayoutReducer.isOpen) {
      setExpanded(expanded === id ? '' : id);
    } else {
      setExpandedPopover(
        expandedPopover.id === id ? { id: '', anchorEl: null } : { id, anchorEl: event.currentTarget },
      );
    }
  };

  const renderList = (): React.ReactElement => (
    <List style={{ color: '#FFF', padding: 0 }}>
      {getMenu().map((element, index) => {
        if (element.children) {
          return (
            <div
              key={index}
              className={`${drawerMainLayoutReducer.isOpen && expanded.startsWith(element.id) ? classes.drawerListOpen : ''}`}
              style={showDrawerFull ? {} : { borderRight: 0 }}
            >
              <Tooltip
                title={!drawerMainLayoutReducer.isOpen ? element.label : ''}
                placement="right"
                key={element.id}
                style={route.id && route.id.startsWith(element.id) ? { background: 'rgb(236 236 236)' } : {}}
              >
                <ListItem
                  key={element.id}
                  onClick={(event) => handleTooltipClick(element.id, event)}
                  className={classes.drawerListItem}
                >
                  <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                  <ListItemText className={classes.drawerListText} primary={element.label} />
                </ListItem>
              </Tooltip>
              {drawerMainLayoutReducer.isOpen ? (
                <Collapse in={expanded.startsWith(element.id)} timeout="auto" unmountOnExit style={{ zIndex: 0 }}>
                  {element.children.map((children) => (
                    <Link to={children.path as string} key={children.key ? children.key : children.id}>
                      <ListItem
                        className={classes.drawerListItem}
                        style={children.id === route.id ? { background: 'rgb(236 236 236)' } : {}}
                      >
                        <ListItemIcon className={classes.drawerListIcon} />
                        <ListItemText className={classes.drawerListText} primary={children.label} />
                      </ListItem>
                    </Link>
                  ))}
                </Collapse>
              ) : (
                <Popover
                  id={element.id}
                  open={expandedPopover.id === element.id}
                  anchorEl={expandedPopover.anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={() => setExpandedPopover({ id: '', anchorEl: null })}
                  className={classes.drawerPopover}
                >
                  {element.children.map((children) => (
                    <Link to={children.path as string} key={children.id}>
                      <ListItem
                        className={classes.drawerListItem}
                        style={children.id === route.id ? { background: 'rgb(236 236 236)' } : {}}
                      >
                        <ListItemText className={classes.drawerListText} primary={children.label} />
                      </ListItem>
                    </Link>
                  ))}
                </Popover>
              )}
            </div>
          );
        } else {
          return (
            <div key={index}>
              <Tooltip title={!drawerMainLayoutReducer.isOpen ? element.label : ''} placement="right" key={element.id}>
                <ListItem
                  className={classes.drawerListItem}
                  key={element.id}
                  style={element.id === route ? { background: 'rgb(236 236 236)' } : {}}
                  onClick={() => navigate(element.path as string)}
                >
                  <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                  <ListItemText className={classes.drawerListText} primary={element.label} />
                </ListItem>
              </Tooltip>
            </div>
          );
        }
      })}
    </List>
  );

  React.useEffect(() => {
    if (!showDrawerFull) {
      dispatch(DrawerMainLayoutClose());
    }
    const currentRoute = getRouteByPathname(window.location.pathname);
    if (currentRoute) {
      setRoute(currentRoute);
      setExpanded(currentRoute.id);
    }
    setExpandedPopover({ id: '', anchorEl: null });
  }, [window.location.pathname]);

  useEffect(() => {
    const isFullDrawer =
      screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
    if (isFullDrawer !== showDrawerFull) {
      setShowDrawerFull(isFullDrawer);
      if (!isFullDrawer) {
        dispatch(DrawerMainLayoutClose());
      }
    }
  }, [screenReducer.screen]);

  return showDrawerFull ? (
    <DrawerMUI
      variant="permanent"
      className={`${classes.drawer} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpen : classes.drawerClose}`}
      open={drawerMainLayoutReducer.isOpen}
    >
      <div
        className={`${classes.drawerHeader} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpenHeader : classes.drawerCloseHeader}`}
      >
        <img
          alt="avatar"
          src={authenticationReducer.gender ? '/image/avatar_man.webp' : '/image/avatar_woman.webp'}
          className={classes.drawerAvatar}
        />
        <p
          className={`${classes.drawerText} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpenText : classes.drawerCloseText}`}
        >
          {authenticationReducer.firstname} {authenticationReducer.lastname}
        </p>
      </div>
      {renderList()}
    </DrawerMUI>
  ) : (
    <DrawerMUI anchor="top" open={drawerMainLayoutReducer.isOpen} onClose={() => dispatch(DrawerMainLayoutClose())}>
      <Close className={classes.drawerIconClose} onClick={() => dispatch(DrawerMainLayoutClose())} />
      <div className={classes.drawerHeader}>
        <img
          alt="avatar"
          src={authenticationReducer.gender ? '/image/avatar_man.webp' : '/image/avatar_woman.webp'}
          className={classes.drawerAvatar}
        />
        <p className={classes.drawerText}>
          {authenticationReducer.firstname} {authenticationReducer.lastname}
        </p>
      </div>
      {renderList()}
    </DrawerMUI>
  );
};

const useStyles = makeStyles({
  drawer: {
    width: drawerOpenWidth,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    background: '#FFFFFF',
    overflowX: 'hidden',
    zIndex: 1,
    paddingTop: 58,
    boxSizing: 'border-box',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 0,
    },
    '& .MuiDrawer-paper': {
      height: '100%',
      boxSizing: 'border-box',
      width: '100%',
      overflowX: 'hidden',
      position: 'relative',
    },
  },
  drawerOpen: {
    width: drawerOpenWidth,
  },
  drawerClose: {
    width: drawerCloseWidth,
  },
  drawerHeader: {
    transition: 'all 200ms',
    textAlign: 'center',
  },
  drawerOpenHeader: {
    width: drawerOpenWidth,
  },
  drawerCloseHeader: {
    width: drawerCloseWidth,
  },
  drawerAvatar: {
    width: 45,
    marginTop: 25,
  },
  drawerText: {
    transition: 'all 200ms',
    textAlign: 'center',
    marginTop: 0,
    fontWeight: 500,
    color: '#5E6E82',
    textTransform: 'capitalize',
    fontSize: 14,
    paddingBottom: 10,
  },
  drawerOpenText: {
    opacity: 1,
  },
  drawerCloseText: {
    opacity: 0,
  },
  drawerNetwork: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
    width: '100%',
  },
  drawerNetworkSmall: {
    width: '100%',
    top: 0,
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
  },
  drawerOpenNetwork: {
    display: 'flex',
  },
  drawerCloseNetwork: {
    display: 'none',
  },
  drawerListItem: {
    padding: '2px 16px !important',
  },
  drawerListIcon: {
    paddingLeft: 10,
    color: '#5E6E82',
    minWidth: 42,
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  drawerListText: {
    cursor: 'pointer',
    '& .MuiListItemText-primary': {
      fontSize: 12,
      color: '#5E6E82',
    },
  },
  drawerPopover: {
    left: '68px !important',
    '& .MuiPopover-paper': {
      borderRadius: '0px !important',
      left: '1px !important',
      backgroundColor: '#FFFFFF',
      color: '#FFFFFF',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 10px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    '& > div:nth-child(1)': {
      left: '68px !important',
    },
  },
  drawerListOpen: {
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 17px 0 #00000050',
    borderRight: '2px solid #57A585',
    boxSizing: 'border-box',
    position: 'relative',
  },
  drawerIconClose: {
    position: 'absolute',
    padding: 12,
    fontSize: 18,
    zIndex: 2,
    cursor: 'pointer',
  },
});

export default Drawer;
