import { makeStyles } from '@mui/styles';
import React, { ComponentType } from 'react';

type Props = {
  view: ComponentType;
};

const AuthenticationLayout: React.FC<Props> = ({ view: View }) => (
  <div className={useStyles().authenticationLayout}>
    <View />
  </div>
);

const useStyles = makeStyles({
  authenticationLayout: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
});

export default AuthenticationLayout;
