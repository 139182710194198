import { Grid, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { isNumber } from '../../../Util/NumberTool';

function StatisticTradeReporting() {
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
  const [optionsChannel, setOptionsChannel] = React.useState([]);
  const [cellsTable, setCellsTable] = React.useState([]);

  const classes = useStyles();

  const columnsStats = [
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'number',
      label: 'Commande',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date',
      options: { filter: true, sort: true },
    },
    {
      name: 'client',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalByProductsHT',
      label: 'Prix HT produits',
      options: { filter: true, sort: true },
    },
    {
      name: 'carrierTotal',
      label: 'Frais de port',
      options: { filter: true, sort: true },
    },
    {
      name: 'paypalTotal',
      label: 'Frais paypal',
      options: { filter: false, sort: false },
    },
    {
      name: 'totalPriceHT',
      label: 'Total HT',
      options: { filter: false, sort: false },
    },
    {
      name: 'totalTVA',
      label: 'Total TVA',
      options: { filter: false, sort: false },
    },
    {
      name: 'totalTTC',
      label: 'Total TTC',
      options: { filter: false, sort: false },
    },
    {
      name: 'orderClientState',
      label: 'Statut',
      options: { filter: false, sort: false },
    },
  ];

  const [formSearch, setFormSearch] = React.useState({
    fromStartAt: {
      name: 'fromStartAt',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromStartAt
          ? cacheTableServer['api_statistic_trades'].fromStartAt
          : '',
      options: { validation: ['date'] },
    },
    fromEndAt: {
      name: 'fromEndAt',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromEndAt
          ? cacheTableServer['api_statistic_trades'].fromEndAt
          : '',
      options: { validation: ['date'] },
    },
    channels: {
      name: 'channels',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'array',
      defaultValue: '',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel_stats', data: { limited: true, from: 'stats' } }, (response) =>
      setOptionsChannel(response.data),
    );
  }, []);

  const onSearch = () => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();
    const filters = {
      fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
      fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
      channels: datas.channels === 0 || !datas.channels ? '' : datas.channels.join('|'),
    };

    ApiHandler.get(
      {
        route: 'api_statistic_trades_reporting',
        data: filters,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setCellsTable(response.data);

          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);

          dispatch(LinearProgressMainLayoutDeactivate());
          dispatch(TableReload('table_api_statistic_trades_reporting'));
          setTimeout(() => {
            dispatch(TableReload('table_api_statistic_trades_reporting'));
          }, 4000);
        } else {
          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);
          dispatch(LinearProgressMainLayoutDeactivate());
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats de vente', context: 'Listing Commandes' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <DatePickerComponent
                  id={'fromStartAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromStartAt', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <DatePickerComponent
                  id={'fromEndAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromEndAt', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <MultipleSelectComponent
                  id={'channels'}
                  options={optionsChannel}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('channels', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label={'Rechercher'}
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />
      <TableComponent
        id={'table_api_statistic_trades_reporting'}
        title={'Reporting des ventes'}
        columns={columnsStats}
        search={false}
        height={10}
        promiseServerData={(resolve) => {
          const data = cellsTable;
          for (const index in data) {
            data[index].confirmedAt = moment(data[index].confirmedAt).format('lll');
            data[index].paypalTotal = isNumber(data[index].paypalTotal) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].paypalTotal).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
            data[index].carrierTotal = isNumber(data[index].carrierTotal) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].carrierTotal).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
            data[index].totalByProductsHT = isNumber(data[index].totalByProductsHT) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].totalByProductsHT).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
            data[index].totalTVA = isNumber(data[index].totalTVA) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].totalTVA).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
            data[index].totalPriceHT = isNumber(data[index].totalPriceHT) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].totalPriceHT).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
            data[index].totalTTC = isNumber(data[index].totalTTC) ? (
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                {parseFloat(data[index].totalTTC).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              '-'
            );
          }
          resolve(data);
        }}
      />
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  button: {
    margin: '15px 15px 15px 0 !important',
  },
  buttonTop: {
    margin: '15px !important',
    top: '0 !important',
    right: '0 !important',
    position: 'absolute !important',
  },
  buttonActionFirst: {
    margin: '10px -8px 10px 20px !important',
    fontSize: '10px !important',
  },
  buttonActionSecond: {
    margin: '10px -8px 10px 20px !important',
    fontSize: '10px !important',
  },
});

export default StatisticTradeReporting;
