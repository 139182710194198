/*
 * Sort dates formatted as "DD/MM/YYYY"
 * TODO: handle other formats
 */
export const sortDateForTableComponent = (
  order: 'asc' | 'desc',
  val1: { data: string | { props: { children: string } } },
  val2: { data: string | { props: { children: string } } },
): number => {
  const extractValue = (val: string | { props: { children: string } }): string =>
    typeof val === 'object' ? val.props.children : val;

  const date1 = new Date(extractValue(val1.data).split('/').reverse().join('-'));
  const date2 = new Date(extractValue(val2.data).split('/').reverse().join('-'));

  return (date1.getTime() - date2.getTime()) * (order === 'asc' ? 1 : -1);
};
