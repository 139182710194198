import { Grid, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTableServer } from '../../../Cache';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { isNumber } from '../../../Util/NumberTool';

function StatisticTradeRetail() {
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
  const [optionsMark, setOptionsMark] = React.useState([]);
  const [optionsRegion, setOptionsRegion] = React.useState([]);
  const [optionsShop, setOptionsShop] = React.useState([]);
  const [optionsByProduct, setOptionsByProduct] = React.useState([]);
  const [cellsTable, setCellsTable] = React.useState([]);

  const classes = useStyles();

  const columnsStats = [
    {
      name: 'sellPlaceChannel',
      label: 'Boutique',
      options: { filter: true, sort: true },
    },
    {
      name: 'product',
      label: 'Produit',
      options: { filter: true, sort: true },
    },
    {
      name: 'mark',
      label: 'Marque',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalPriceHt',
      label: 'CA Total',
      options: { filter: true, sort: true },
      style: {
        textAlign: 'right',
      },
    },
    {
      name: 'marge',
      label: 'Marge',
      options: { filter: true, sort: true },
    },
    {
      name: 'numberOfOrders',
      label: 'Nombre de commandes',
      options: { filter: true, sort: true },
    },
    {
      name: 'numberOfSellProducts',
      label: 'Nombre de produits vendus',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalCaHtFive',
      label: 'CA HT 5,5%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalCaHtTwenty',
      label: 'CA HT 20%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalCaHtSix',
      label: 'CA HT 6%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalCaHtTen',
      label: 'CA HT 10%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalTvaFive',
      label: 'TVA 5,5%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalTvaTwenty',
      label: 'TVA 20%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalTvaSix',
      label: 'TVA 6%',
      options: { filter: true, sort: true },
    },
    {
      name: 'totalTvaTen',
      label: 'TVA 10%',
      options: { filter: true, sort: true },
    },
  ];

  const [formSearch, setFormSearch] = React.useState({
    fromStartAt: {
      name: 'fromStartAt',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromStartAt
          ? cacheTableServer['api_statistic_trades'].fromStartAt
          : '',
      options: { validation: ['date'] },
    },
    fromEndAt: {
      name: 'fromEndAt',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromEndAt
          ? cacheTableServer['api_statistic_trades'].fromEndAt
          : '',
      options: { validation: ['date'] },
    },
    region: {
      name: 'region',
      label: 'Région',
      textHelper: 'Rechercher par region.',
      type: 'integer',
      defaultValue: '',
    },
    shop: {
      name: 'shop',
      label: 'Boutique',
      textHelper: 'Rechercher par boutique.',
      type: 'array',
      defaultValue: '',
    },
    byProduct: {
      name: 'byProduct',
      label: 'Produit',
      textHelper: 'Rechercher par produit.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].byProduct
          ? cacheTableServer['api_statistic_trades'].byProduct
          : 0,
    },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: 'Rechercher par marque.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].mark
          ? cacheTableServer['api_statistic_trades'].mark
          : 0,
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    if (index === 'region') {
      ApiHandler.get({ route: 'api_select_shop', params: { regionId: value } }, (response) => {
        const data = [{ value: 0, label: 'Tous' }, ...response.data];
        setOptionsShop(data);
      });
    }
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_mark' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsMark(data);
    });

    ApiHandler.get({ route: 'api_select_by_product' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsByProduct(data);
    });

    ApiHandler.get({ route: 'api_select_region', data: { limited: true } }, (response) => {
      setOptionsRegion(response.data);
    });

    ApiHandler.get({ route: 'api_select_shop' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsShop(data);
    });
  }, []);

  const onSearch = () => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();
    const filters = {
      fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
      fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
      shop: datas.shop === 0 || !datas.shop ? '' : datas.shop.join('|'),
      byProduct: datas.byProduct === 0 || !datas.byProduct ? '' : datas.byProduct,
      region: datas.region === 0 || !datas.region ? '' : datas.region,
      mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
    };

    ApiHandler.get(
      {
        route: 'api_statistic_trades_retail',
        data: filters,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setCellsTable(response.data);

          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);

          dispatch(LinearProgressMainLayoutDeactivate());
          setTimeout(() => {
            dispatch(TableReload('table_api_statistic_trades_retail'));
          }, 2000);
        } else {
          setDisabledStatusButtonSearch(false);
          setLoadingContent(false);
          dispatch(LinearProgressMainLayoutDeactivate());
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats de vente RETAIL', context: 'Statistiques' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <DatePickerComponent
                  id={'fromStartAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromStartAt', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <DatePickerComponent
                  id={'fromEndAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromEndAt', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <SelectComponent
                  id={'region'}
                  options={optionsRegion}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('region', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <MultipleSelectComponent
                  id={'shop'}
                  options={optionsShop}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('shop', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <SelectComponent
                  id={'mark'}
                  options={optionsMark}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('mark', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <SelectComponent
                  id={'byProduct'}
                  options={optionsByProduct}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('byProduct', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label={'Rechercher'}
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />
      <TableComponent
        id={'table_api_statistic_trades_retail'}
        title={'Stats de vente RETAIL'}
        columns={columnsStats}
        search={false}
        height={10}
        promiseData={(resolve) => {
          const data = cellsTable;
          for (const index in data) {
            data[index].marge = isNumber(data[index].marge)
              ? parseFloat(data[index].marge).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalPriceHt = isNumber(data[index].totalPriceHt)
              ? parseFloat(data[index].totalPriceHt).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalCaHtFive = isNumber(data[index].totalCaHtFive)
              ? parseFloat(data[index].totalCaHtFive).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalTvaFive = isNumber(data[index].totalTvaFive)
              ? parseFloat(data[index].totalTvaFive).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalCaHtTwenty = isNumber(data[index].totalCaHtTwenty)
              ? parseFloat(data[index].totalCaHtTwenty).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalTvaTwenty = isNumber(data[index].totalTvaTwenty)
              ? parseFloat(data[index].totalTvaTwenty).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalCaHtSix = isNumber(data[index].totalCaHtSix)
              ? parseFloat(data[index].totalCaHtSix).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalTvaSix = isNumber(data[index].totalTvaSix)
              ? parseFloat(data[index].totalTvaSix).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalCaHtTen = isNumber(data[index].totalPriceHt)
              ? parseFloat(data[index].totalCaHtTen).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
            data[index].totalTvaTen = isNumber(data[index].totalTvaTen)
              ? parseFloat(data[index].totalTvaTen).toLocaleString('fr-FR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '-';
          }
          resolve(data);
        }}
      />
    </ContentViewComponent>
  );
}

const useStyles = makeStyles({
  button: {
    margin: '15px 15px 15px 0 !important',
  },
  buttonTop: {
    margin: '15px !important',
    top: '0 !important',
    right: '0 !important',
    position: 'absolute !important',
  },
  buttonActionFirst: {
    margin: '10px -8px 10px 20px !important',
    fontSize: '10px !important',
  },
  buttonActionSecond: {
    margin: '10px -8px 10px 20px !important',
    fontSize: '10px !important',
  },
});

export default StatisticTradeRetail;
