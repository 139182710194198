import { Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { CSSProperties, useState, useEffect } from 'react';

type Props = {
  id: string;
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: boolean) => void;
  };
  orientation?: 'left' | 'right';
  style?: CSSProperties;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

const CheckboxComponent: React.FC<Props> = ({ id, handler, orientation = 'right', style, onChange, disabled }) => {
  const classes = useStyles();
  const input = handler.form[id];
  const label = input.label;

  // Value
  const [value, setValue] = useState<boolean>(false);
  useEffect(() => {
    if (input.value !== undefined && input.value !== value) {
      setValue(!!input.value);
    }
  }, [input.value]);

  const handleChange = (): void => {
    const newValue = !value;
    setValue(newValue);
    handler.setValue(id, newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={classes.content} style={style ?? {}}>
      {orientation === 'left' && <span className={classes.label}>{label}</span>}
      <Checkbox checked={value} onChange={handleChange} disabled={input.disabled || disabled} />
      {orientation === 'right' && <span className={classes.label}>{label}</span>}
      <p className={classes.textHelper}>{input.textHelper}</p>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    height: 55,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'inline-block',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  checkbox: {
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  textHelper: {
    margin: '-4px 14px',
    fontSize: 9,
    minHeight: 9,
    lineHeight: '9px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
  },
});

export default CheckboxComponent;
