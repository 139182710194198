type AuthenticationLoginAction = {
  type: 'AuthenticationLogin';
  data: any;
};

export const AuthenticationLogin = (data: any): AuthenticationLoginAction => ({
  type: 'AuthenticationLogin',
  data,
});

type AuthenticationLogoutAction = {
  type: 'AuthenticationLogout';
};

export const AuthenticationLogout = (): AuthenticationLogoutAction => ({
  type: 'AuthenticationLogout',
});
