export const clone = <T>(element: T): T => {
  if (Array.isArray(element)) {
    return element.map((item) => ({ ...item })) as T;
  }
  return { ...element };
};

export const cloneJson = <T>(element: T): T => {
  return JSON.parse(JSON.stringify(element));
};
