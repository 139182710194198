import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { StateType } from '#app/Store';

import { AuthenticationLogout } from './Action/AuthenticationAction';
import { ScreenChange } from './Action/ScreenAction';
import SnackbarComponent from './Component/SnackbarComponent';
import ApiHandler from './Handler/ApiHandler';
import { getPathDefault, getRoutes, updateRouting } from './Handler/RouteHandler';

// Version
const VERSION = 'v2.0.3';

// Dispatch
export let dispatch: ReturnType<typeof useDispatch>;

// Moment
moment.locale('fr');

const App: React.FC = () => {
  dispatch = useDispatch();
  const classes = useStyles();

  const [isReady, setReady] = React.useState<boolean>(false);

  // Security & Routes
  const [routes, setRoutes] = React.useState<any>(null);
  const [routeDefault, setRouteDefault] = React.useState<string>('');

  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);

  // Screen
  const resize = (): void => {
    const screen =
      window.innerWidth < 576
        ? 'XS'
        : window.innerWidth < 768
          ? 'SM'
          : window.innerWidth < 992
            ? 'MD'
            : window.innerWidth < 1200
              ? 'LG'
              : 'XL';

    dispatch(
      ScreenChange({
        screen,
        size: window.innerWidth,
        orientation: window.screen.orientation?.angle === 90,
      }),
    );
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    window.addEventListener('orientationchange', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('orientationchange', resize);
    };
  }, []);

  useEffect(() => {
    ApiHandler.get({ route: 'api_default' }, (response) => {
      if (!response.data.authenticated && authenticationReducer.email) {
        dispatch(AuthenticationLogout());
      }
      setReady(true);
    });
  }, [authenticationReducer.email, dispatch]);

  React.useEffect(() => {
    updateRouting(authenticationReducer);
    setRoutes(getRoutes());
    setRouteDefault(getPathDefault());
    setReady(true);
  }, [authenticationReducer]);

  if (isReady && routes) {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterMoment as any}>
          <BrowserRouter>
            <Routes>
              {Object.keys(routes).map((key) => {
                const route = routes[key];
                return <Route key={'route_' + key} path={route.path} element={<route.layout view={route.view} />} />;
              })}
              <Route path="*" element={<Navigate to={routeDefault} replace />} />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>

        <SnackbarComponent />
        <div className={classes.version}>Terravita Lucy {VERSION}</div>
      </>
    );
  }

  return null;
};

const useStyles = makeStyles({
  version: {
    position: 'absolute',
    zIndex: 1000,
    bottom: 0,
    right: 0,
    fontSize: 10,
    padding: '5px 10px',
    color: 'rgb(104 104 104)',
  },
});

export default App;
