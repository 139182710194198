import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { displayErrorMessage } from '../../..//Error/Errors';
import ApiHandler from '../../..//Handler/ApiHandler';
import { isNumber } from '../../..//Util/NumberTool';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DataTable from '../../../View/InventoryManagement/Movement/DataTable';
import SearchForm, { MovementData } from '../../../View/InventoryManagement/Movement/SearchForm';

const InventoryManagementMovementList: React.FC = () => {
  const [loadingContent, setLoadingContent] = useState(false);

  const [cellsTable, setCellsTable] = useState<MovementData[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    limit: 25,
    offset: 0,
    sortName: undefined,
    sortDirection: undefined,
  });

  const fetchData = (filters: any) => {
    setLoadingContent(true);

    ApiHandler.get({ route: 'api_inventory_management_movement_list', data: filters }, (response) => {
      if (response.status >= 200 && response.status < 300) {
        const data = response.data.data.map((item: any) => ({
          ...item,
          purveyor: item.purveyor ?? '-',
          channel: item.channel ?? '-',
          comment: item.comment ?? '-',
          createdAt: moment(item.createdAt).format('lll'),
          amountText: isNumber(item.amount) ? item.amount.toLocaleString('fr-FR', { minimumFractionDigits: 2 }) : '-',
        }));
        setCellsTable(data);
        setTotalAmount(response.data.totalAmount);
        setTotalItems(response.data.totalItems);
        setLoadingContent(false);
      } else {
        displayErrorMessage(response);
        setLoadingContent(false);
      }
    });
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des mouvements', context: 'Gestion des stocks' }}>
      <SearchForm loading={loadingContent} fetchData={(filters) => fetchData(filters)} />

      <DataTable
        loading={loadingContent}
        totalItems={totalItems}
        totalAmount={totalAmount}
        rows={Object.values(cellsTable).map((row) => row)}
        filters={filters}
        onPaginationModelChange={({ pageSize, page }) =>
          setFilters({ ...filters, limit: pageSize, offset: pageSize * page })
        }
        onSortModelChange={(sorting) =>
          sorting.length > 1 && setFilters({ ...filters, sortName: sorting[0].field, sortDirection: sorting[0].sort })
        }
      />
    </ContentViewComponent>
  );
};

export default InventoryManagementMovementList;
