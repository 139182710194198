import { makeStyles } from '@mui/styles';
import React, { ComponentType } from 'react';

import AppBar from './Component/AppBar';
import Drawer from './Component/Drawer';

type Props = {
  view: ComponentType;
};

const MainLayout: React.FC<Props> = ({ view: View }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainLayout}>
      <AppBar />

      <Drawer />

      <div className={classes.view}>
        <View />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  mainLayout: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
  },
  view: {
    flexGrow: 1,
    overflowX: 'scroll',
    paddingTop: 58,
    paddingBottom: 16,
    boxSizing: 'border-box',
  },
});

export default MainLayout;
