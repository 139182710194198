import { DoneAll, Edit, RotateLeft } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { hasRight } from '../../../Common/UserCommon';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function SettingFlavorList() {
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [flavor, setFlavor] = React.useState(null);
  const [isUpdateSylius, setIsUpdateSylius] = React.useState(true);
  const [isLoadingSylius, setIsLoadingSylius] = React.useState(true);

  // Sylius
  const updateSylius = () => {
    setIsLoadingSylius(true);
    ApiHandler.get(
      {
        route: 'api_setting_flavor_sylius',
      },
      (response) => {
        if (response.status === 200) {
          setIsUpdateSylius(response.data.isUpdate);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
          setIsUpdateSylius(false);
        }

        setIsLoadingSylius(false);
      },
    );
  };

  // Global
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'label',
      label: 'Libellé',
      options: { filter: true, sort: true },
    },
    {
      name: 'code',
      label: 'Code',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    if (
      hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR') ||
      hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR_EDIT')
    ) {
      return (
        <>
          <Tooltip title={'Modifier'} placement="left">
            <IconButton
              onClick={() => {
                handlerForm.reset();
                setFlavor(row.id);
                handlerForm.setValue('label', row.label);
                handlerForm.setValue('code', row.code);
                setOpenDialog(true);
              }}
            >
              <Edit style={{ color: '#17a2b8' }} />
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <IconButton style={{ cursor: 'no-drop' }}>
            <Edit />
          </IconButton>
        </>
      );
    }
  };

  // Form
  const [form, setForm] = React.useState({
    label: {
      name: 'label',
      label: 'Libellé',
      textHelper: 'Saisissez le libellé du parfum/couleur.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'] },
    },
    code: {
      name: 'code',
      label: 'Code',
      textHelper: 'Saisissez le code du parfum/couleur pour la génération du SKU.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required'], equal: 2 },
    },
  });
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const handlerForm = initFormHandler(form, setForm);
  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      if (flavor) {
        ApiHandler.post(
          {
            route: 'api_setting_flavor_edit',
            data: handlerForm.getData(),
            params: { id: flavor },
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_flavor_list'));
              setFlavor(null);
              dispatch(
                SnackbarOpen({
                  text: 'Parfum / Couleur modifié(e) avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_flavor');
              updateSylius();
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      } else {
        ApiHandler.post(
          {
            route: 'api_setting_flavor_add',
            data: handlerForm.getData(),
          },
          (response) => {
            if (response.status === 200) {
              setOpenDialog(false);
              dispatch(TableReload('api_setting_flavor_list'));
              dispatch(
                SnackbarOpen({
                  text: 'Parfum / Couleur créé(e) avec succès.',
                  variant: 'success',
                }),
              );

              ApiHandler.clearCache('api_select_flavor');
              updateSylius();
            } else if (response.status === 400) {
              handlerForm.setErrorApi(response.error);
            } else {
              dispatch(
                SnackbarOpen({
                  text: response?.error?.message || "Une erreur inattendue s'est produite.",
                  variant: 'error',
                }),
              );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerForm.setFormLoading(false);
            setIsLoadingForm(false);
          },
        );
      }
    }
  };
  React.useEffect(() => {
    updateSylius();
    handlerForm.start();
  }, []);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des parfums/couleurs', context: 'Paramétrage' }}>
      <TableComponent
        id={'api_setting_flavor_list'}
        title={'Parfums / Couleurs'}
        columns={columns}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR') ||
          hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR_ADD')
            ? {
                label: 'Ajouter un parfum/couleur',
                onClick: () => {
                  setFlavor(null);
                  handlerForm.reset();
                  setOpenDialog(true);
                },
              }
            : false
        }
        actionSecond={
          hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR') ||
          hasRight(authenticationReducer, 'ROLE_SETTING_FLAVOR_LIST')
            ? {
                label: isUpdateSylius ? (
                  <span>
                    Sylius est à jour <DoneAll style={{ verticalAlign: 'top' }} />
                  </span>
                ) : (
                  <span>
                    Mettre à jour Sylius <RotateLeft style={{ verticalAlign: 'top' }} />
                  </span>
                ),
                color: isUpdateSylius ? '#006500' : '#ffc107',
                loading: isLoadingSylius,
                outlined: true,
                onClick: isUpdateSylius ? () => {} : updateSylius,
              }
            : false
        }
        promiseData={(resolve) => {
          ApiHandler.get({ route: 'api_setting_flavor_list' }, (response) => {
            const data = response.data;
            for (const index in data) {
              data[index].action = getAction(data[index]);
            }
            resolve(data);
          });
        }}
      />

      <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ fontSize: 15 }}>
          {flavor ? 'Modifier un parfum/couleur' : 'Ajouter un parfum/couleur'}
        </DialogTitle>
        <DialogContent style={{ minWidth: '30vw' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent id={'label'} handler={handlerForm} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent id={'code'} handler={handlerForm} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color={'#5E6E82'}
            label={'Annuler'}
            onClick={() => setOpenDialog(false)}
            disabled={isLoadingForm}
          />
          <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm} />
        </DialogActions>
      </Dialog>
    </ContentViewComponent>
  );
}

export default SettingFlavorList;
