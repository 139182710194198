import { Fade, Grid } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import InventoryManagementDepositByProductList from './InventoryManagementDepositComponents/InventoryManagementDepositByProductList';
import InventoryManagementDepositDepositAddressList from './InventoryManagementDepositComponents/InventoryManagementDepositDepositAddressList';
import InventoryManagementDepositForm from './InventoryManagementDepositComponents/InventoryManagementDepositForm';

function InventoryManagementDeposit() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  // Global
  const [isLoadingContent, setIsLoadingContent] = React.useState(true);
  const [deposit, setDeposit] = React.useState(null);
  React.useEffect(() => {
    setDeposit(null);
    if (params.id === 'add') {
      setTimeout(() => setIsLoadingContent(false), 1500);
    } else {
      setIsLoadingContent(true);

      ApiHandler.get(
        {
          route: 'api_inventory_management_deposit_get',
          params: { id: params.id },
        },
        (response) => {
          if (response.status === 200) {
            setDeposit(response.data);
          } else {
            dispatch(
              SnackbarOpen({
                text: response?.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }

          setIsLoadingContent(false);
        },
      );
    }
  }, [location]);

  return (
    <ContentViewComponent
      loading={isLoadingContent}
      breadcrumbs={{
        title: params.id === 'add' ? 'Ajouter un dépôt' : 'Éditer un dépôt',
        context: 'Gestion des stocks',
        links: [{ path: getRoutePathname('inventory_management_deposit_list'), label: 'Liste des dépôts' }],
      }}
    >
      <Grid container spacing={2}>
        <Fade in timeout={750}>
          <Grid item xs={12} sm={12} md={deposit ? 4 : 12} lg={deposit ? 4 : 12} xl={deposit ? 4 : 12}>
            <InventoryManagementDepositForm deposit={deposit} />
          </Grid>
        </Fade>
        {deposit && (
          <Fade in timeout={750}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <InventoryManagementDepositDepositAddressList deposit={deposit} />
            </Grid>
          </Fade>
        )}
      </Grid>

      <br />

      {deposit && <InventoryManagementDepositByProductList deposit={deposit} />}

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default InventoryManagementDeposit;
