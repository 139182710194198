import { makeStyles } from '@mui/styles';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import React, { useEffect, useState } from 'react';

import { CustomPagination } from '../../../../Component/DataGrid/CustomPagination';
import ApiHandler, { ApiResponse } from '../../../../Handler/ApiHandler';
import { styleDataGridDefault } from '../../../../Style/styleDataGridDefault';
import { transformObjectToDataGridRowsArray } from '../../../../Util/DataGridDataTransformer';
import { momentDateFormatter } from '../../../../Util/MomentDateFormatter';
import { formatNumberToEurCurrency } from '../../../../Util/NumberTool';
import { Filters } from '../../../../View/Statistic/Order/StatisticOrder';
import {
  OrderDocumentStats,
  OrderType,
  OrderTypeTranslation,
  TaxTypeId,
  TaxTypeTranslation,
} from '../../../../typings/orderDocument';
import { PaymentMethod, PaymentMethodTranslation } from '../../../../typings/orderPayment';
import { Pagination, Sorting } from '../../../../typings/utils';

type Props = {
  filters: Filters;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

type Response = ApiResponse<OrderDocumentStats[]> & {
  totalItems: number;
  totalHT: number;
  totalTTC: number;
};

const List: React.FC<Props> = ({ filters, loading, setLoading }) => {
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>({ page: 0, limit: 25 });
  const [sorting, setSorting] = useState<Sorting>({ sortName: 'date', sortDirection: 'desc' });
  const [orderDocuments, setOrderDocuments] = useState<OrderDocumentStats[]>([]);
  const apiRef = useGridApiRef();

  const getTaxValueColumns = (): GridColDef[] =>
    (Object.keys(TaxTypeTranslation) as TaxTypeId[]).map((id) => ({
      field: `taxBase${id}`,
      headerName: `Base ${TaxTypeTranslation[id]} HT`,
      sortable: false,
      minWidth: 120,
      valueGetter: (_, row: OrderDocumentStats) =>
        row.taxRates[id]?.base ? formatNumberToEurCurrency(row.taxRates[id]?.base) : '-',
    }));

  const getTaxRateColumns = (): GridColDef[] =>
    (Object.keys(TaxTypeTranslation) as TaxTypeId[]).map((id) => ({
      field: `taxTotal${id}`,
      headerName: `TVA ${TaxTypeTranslation[id]}`,
      sortable: false,
      valueGetter: (_, row: OrderDocumentStats) =>
        row.taxRates[id]?.total ? formatNumberToEurCurrency(row.taxRates[id]?.total) : '-',
    }));

  const getColumnVisibilityModelFromTaxType = (): GridColumnVisibilityModel =>
    Object.keys(TaxTypeTranslation).reduce((acc, taxId) => {
      const hidden = !orderDocuments.every((orderDocument) => orderDocument.taxRates[taxId as TaxTypeId] === undefined);
      acc[`taxBase${taxId}`] = hidden;
      acc[`taxTotal${taxId}`] = hidden;
      return acc;
    }, {} as GridColumnVisibilityModel);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      valueGetter: (value: string) => momentDateFormatter.dateTimeToFormatFr(value),
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      minWidth: 110,
      valueGetter: (value: OrderType) => OrderTypeTranslation[value],
    },
    {
      field: 'reference',
      headerName: 'Référence',
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'paymentMethod',
      headerName: 'Methode',
      sortable: false,
      valueGetter: (value: PaymentMethod) => PaymentMethodTranslation[value],
    },
    {
      field: 'totalHT',
      headerName: 'Total HT',
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'totalTVA',
      headerName: 'Total TVA',
      sortable: false,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'totalProductsHT',
      headerName: 'Total Produits HT',
      minWidth: 140,
      sortable: false,
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'totalShippingFees',
      headerName: 'Frais de port HT',
      minWidth: 135,
      sortable: false,
      valueGetter: (value) => (value > 0 ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'totalPaymentFees',
      headerName: 'Frais payment HT',
      minWidth: 140,
      sortable: false,
      valueGetter: (value) => (value > 0 ? formatNumberToEurCurrency(value) : '-'),
    },
    ...getTaxValueColumns(),
    ...getTaxRateColumns(),
    {
      field: 'totalTTC',
      headerName: 'Total TTC',
      valueGetter: (value) => formatNumberToEurCurrency(value),
    },
  ];

  const handleSearch = () => {
    setLoading(true);
    ApiHandler.get<OrderDocumentStats[]>(
      {
        route: 'api_statistic_order_document',
        data: { ...filters, ...pagination, ...sorting },
      },
      (response) => {
        setOrderDocuments(response.data ?? []);
        setTotalItems((response as Response).totalItems ?? 0);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    handleSearch();
  }, [pagination, sorting, filters]);

  return (
    <div className={classes.container}>
      <DataGrid
        apiRef={apiRef}
        sx={styleDataGridDefault}
        rows={transformObjectToDataGridRowsArray<OrderDocumentStats>(orderDocuments)}
        columns={columns}
        rowHeight={25}
        loading={loading}
        rowCount={totalItems}
        disableRowSelectionOnClick
        disableColumnMenu
        slots={{ pagination: CustomPagination }}
        columnVisibilityModel={getColumnVisibilityModelFromTaxType()}
        sortingMode="server"
        onSortModelChange={(sort) => {
          setSorting({ sortName: sort[0]?.field, sortDirection: sort[0]?.sort as 'asc' | 'desc' });
          apiRef.current.setPage(0);
          setPagination({ page: 0, limit: pagination.limit });
        }}
        paginationMode="server"
        onPaginationModelChange={({ pageSize, page }) => setPagination({ page, limit: pageSize })}
        initialState={{
          pagination: { paginationModel: { pageSize: pagination.limit } },
          sorting: { sortModel: [{ field: sorting.sortName, sort: sorting.sortDirection }] },
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default List;
