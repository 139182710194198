import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '#app/Store';

type Props = {
  small?: boolean;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  label?: string;
};

const ButtonComponent: React.FC<Props> = ({
  small,
  className,
  loading,
  disabled,
  outlined,
  color,
  style,
  onClick,
  label,
}) => {
  const classes = useStyles();
  const screenReducer = useSelector((state: StateType) => state.ScreenReducer);
  const [isTall, setIsTall] = useState(true);

  useEffect(() => {
    if (screenReducer.screen) {
      const isTallScreen =
        screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
      if (isTallScreen !== isTall) {
        setIsTall(isTallScreen);
      }
    }
  }, [screenReducer.screen]);

  return (
    <LoadingButton
      className={`${isTall ? (small ? classes.buttonSmall : classes.button) : small ? classes.buttonVerySmall : classes.buttonSmall} ${className ? className : ''}`}
      loading={loading}
      disabled={disabled}
      variant={outlined ? 'outlined' : ('contained' as 'outlined' | 'contained')}
      style={
        color
          ? {
              ...style,
              backgroundColor: outlined ? 'unset' : color,
              color: loading ? 'rgba(255,255,255,0)' : outlined ? color : '#FFFFFF',
              borderColor: color,
            }
          : {
              ...style,
              backgroundColor: outlined ? 'unset' : '#0484c7',
              color: loading ? 'rgba(255,255,255,0)' : outlined ? color : '#FFFFFF',
            }
      }
      onClick={onClick}
    >
      {label}
    </LoadingButton>
  );
};

const useStyles = makeStyles({
  button: {
    fontSize: '10px !important',
    opacity: 0.9,
  },
  buttonSmall: {
    fontSize: '10px !important',
    opacity: 0.9,
    transform: 'scale(0.8)',
  },
  buttonVerySmall: {
    fontSize: '10px !important',
    opacity: 0.9,
    transform: 'scale(0.6)',
  },
});

export default ButtonComponent;
