type SnackbarAction = {
  type: string;
  text: string;
  variant: string;
  duration?: number;
};

export const SnackbarOpen = (content: { text: string; variant: string; duration?: number }): SnackbarAction => ({
  type: 'SnackbarOpen',
  text: content.text,
  variant: content.variant,
  duration: content.duration || 5000,
});

export const SnackbarClose = () => ({
  type: 'SnackbarClose',
});
