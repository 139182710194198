import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { StateType } from '#app/Store';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form } from '../../../../Handler/FormHandler';
import { initFormHandler } from '../../../../Handler/FormHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';

type Props = {
  [key: string]: any;
};

const SaleCreatePromoForm: React.FC<Props> = ({ promo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const justWatch =
    !hasRight(authenticationReducer, 'ROLE_SALE_PROMO_GET') && !hasRight(authenticationReducer, 'ROLE_SALE_PROMO');
  const params = useParams<{ id: string }>();
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

  const [form, setForm] = useState<Form>({
    label: {
      name: 'label',
      label: 'Label',
      textHelper: 'Saisissez le nom de la promo.',
      type: 'text',
      options: { validation: ['required'] },
    },
    type: {
      name: 'type',
      label: 'Type promo',
      type: 'integer',
      defaultValue: promo.type,
      options: { validation: ['required'] },
    },
    fixless: {
      name: 'fixless',
      label: 'Montant remise en euro ( sans signe euro ni virgule )',
      textHelper: 'Saisir le montant fixe a remiser',
      type: 'integer',
      options: { validation: ['required'] },
    },
    percent: {
      name: 'percent',
      label: 'Montant remise en % ( sans signe % )',
      textHelper: 'Saisir le pourcentage a remiser',
      type: 'integer',
      options: { validation: ['required'] },
    },
    fixlessp1: {
      name: 'fixlessp1',
      label: 'Palier 1 remise en euros',
      textHelper: 'Saisir le montant palier 1',
      type: 'integer',
    },
    fixlessp2: {
      name: 'fixlessp2',
      label: 'Palier 2 remise en euros',
      textHelper: 'Saisir le montant palier 2',
      type: 'integer',
    },
    fixlessp3: {
      name: 'fixlessp3',
      label: 'Palier 3 remise en euros',
      textHelper: 'Saisir le montant palier 3',
      type: 'integer',
    },
    percentp1: {
      name: 'percentp1',
      label: 'Palier 1 remise %',
      textHelper: 'Saisir le montant palier 1',
      type: 'integer',
    },
    percentp2: {
      name: 'percentp2',
      label: 'Palier 2 remise %',
      textHelper: 'Saisir le montant palier 2',
      type: 'integer',
    },
    percentp3: {
      name: 'percentp3',
      label: 'Palier 3 remise %',
      textHelper: 'Saisir le montant palier 3',
      type: 'integer',
    },
    palier1: {
      name: 'palier1',
      label: 'Palier 1 ',
      textHelper: 'Saisir le montant palier 1',
      type: 'integer',
    },
    palier2: {
      name: 'palier2',
      label: 'Palier 2',
      textHelper: 'Saisir le montant palier 2',
      type: 'integer',
    },
    palier3: {
      name: 'palier3',
      label: 'Palier 3',
      textHelper: 'Saisir le montant palier 3',
      type: 'integer',
    },
    byProductIdOffered: {
      name: 'byProductIdOffered',
      label: 'Produit offert',
      textHelper: "Saisir l'ID du produit offert",
      type: 'integer',
    },
    code: {
      name: 'code',
      label: 'Code',
      textHelper: 'Saisissez le coupon.',
      type: 'text',
      options: { validation: ['required'] },
    },
    productList: {
      name: 'productList',
      label: 'Skus',
      textHelper: 'Saisissez les skus de la promo.',
      type: 'text',
      options: { validation: ['required'] },
    },
    enable: {
      name: 'enable',
      label: 'Actif ?',
      textHelper: 'cocher si oui',
      type: 'boolean',
      defaultValue: true,
    },
    startAt: {
      name: 'startAt',
      label: 'Date de début',
      textHelper: 'Saisir début promo',
      type: 'date',
      defaultValue: promo.startAt ?? '',
      options: { validation: ['date'] },
    },
    endAt: {
      name: 'endAt',
      label: 'Date de fin',
      textHelper: 'Saisir fin promo',
      type: 'date',
      defaultValue: promo.endAt ?? '',
      options: { validation: ['date'] },
    },
  });
  const handlerForm = initFormHandler(form, setForm);

  const save = () => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      setIsLoadingForm(true);
      dispatch(LinearProgressMainLayoutActivate());

      ApiHandler.post(
        {
          route: 'api_sale_update_promo',
          data: handlerForm.getData(),
          params: { id: params.id as string },
        },
        (response) => {
          if (response.status === 200) {
            dispatch(
              SnackbarOpen({
                text: 'Promo modifié avec succès.',
                variant: 'success',
              }),
            );
            navigate(getRoutePathname('sale_promo_list'));
          } else if (response.status === 400) {
            handlerForm.setErrorApi(response.error);
          } else {
            dispatch(
              SnackbarOpen({
                text: response.error?.message || "Une erreur inattendue s'est produite.",
                variant: 'error',
              }),
            );
          }
          dispatch(LinearProgressMainLayoutDeactivate());
          handlerForm.setFormLoading(false);
          setIsLoadingForm(false);
        },
      );
    }
  };
  useEffect(() => {
    handlerForm.start();

    if (promo) {
      handlerForm.setDataApi(promo);
      if (promo.enable === 1) {
        handlerForm.setValue('enable', true);
      }
    }
  }, []);
  useEffect(() => {
    handlerForm.setFormLoading(justWatch);
  }, [justWatch]);

  return (
    <ShadowBoxComponent className={classes.shadowBox}>
      <TitleComponent title="Code promo" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <CheckboxComponent id="enable" handler={handlerForm} orientation="right" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextFieldComponent id="label" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldComponent id="code" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerComponent id="startAt" handler={handlerForm} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerComponent id="endAt" handler={handlerForm} />
        </Grid>
      </Grid>
      <br />
      <TitleComponent title="Liste des SKUS concernes" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextFieldComponent id="productList" handler={handlerForm} />
        </Grid>
      </Grid>
      <br />
      <TitleComponent title="Conditions de la promo" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SelectComponent
            id="type"
            options={[
              { value: 0, label: 'Promo sans code à saisir' },
              { value: 1, label: 'Promo remise' },
              { value: 2, label: 'Promo palier' },
              { value: 3, label: 'Produit offert' },
            ]}
            handler={handlerForm}
          />
        </Grid>
      </Grid>
      <br />
      <TitleComponent title="Montant promo remise" />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <TextFieldComponent id="fixless" handler={handlerForm} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextFieldComponent id="percent" handler={handlerForm} />
        </Grid>
      </Grid>
      <br />
      <div style={{ display: '' }}>
        <TitleComponent title="Configuration des paliers" />
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            Définition palier
          </Grid>
          <Grid item xs={4} sm={4}>
            Définition montant fixe
          </Grid>
          <Grid item xs={4} sm={4}>
            Définition remise en %
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="palier1" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="fixlessp1" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="percentp1" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="palier2" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="fixlessp2" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="percentp2" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="palier3" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="fixlessp3" handler={handlerForm} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldComponent id="percentp3" handler={handlerForm} />
          </Grid>
        </Grid>
      </div>
      <br />
      <div>
        <TitleComponent title="Produit offert" />
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <TextFieldComponent id="byProductIdOffered" handler={handlerForm} />
          </Grid>
        </Grid>
      </div>

      {!justWatch && (
        <ButtonComponent label="Enregistrer" className={classes.button} onClick={save} loading={isLoadingForm} />
      )}
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  shadowBox: {
    paddingBottom: 45,
    height: '100%',
  },
  button: {
    margin: '15px !important',
    bottom: '0 !important',
    right: '0 !important',
    position: 'absolute !important' as Property.Position,
  },
});

export default SaleCreatePromoForm;
