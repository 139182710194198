import MuiPagination from '@mui/material/Pagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  GridPagination,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid';
import { getPageCount } from '@mui/x-data-grid/hooks/features/pagination/gridPaginationUtils';
import React from 'react';

const Pagination: React.ElementType<TablePaginationActionsProps> = ({ page, onPageChange, className }) => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
  const rootProps = useGridRootProps();
  const pageCount = getPageCount(
    rootProps.rowCount ?? (visibleTopLevelRowCount as number),
    pageSize as number,
    page as number,
  );

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as React.MouseEvent<HTMLButtonElement>, newPage - 1);
      }}
    />
  );
};

export const CustomPagination: React.FC<React.ComponentProps<typeof GridPagination>> = (props) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
};
