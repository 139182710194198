import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { ByProduct } from '#app/typings/order';

import { styleDataGridDefault } from '../../../../../Style/styleDataGridDefault';
import { formatNumberToEurCurrency, isNumber } from '../../../../../Util/NumberTool';

type Props = {
  products: ByProduct[];
  loading: boolean;
};

const ProductList: React.FC<Props> = ({ products = [], loading }) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Référence',
      minWidth: 110,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: 'Nom',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'weight',
      headerName: 'Poids unitaire (kg)',
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? value : '-'),
    },
    {
      field: 'amount',
      headerName: 'Quantité prévue',
      minWidth: 110,
    },
    {
      field: 'amountShipped',
      headerName: 'Quantité expédiée',
      minWidth: 110,
    },
    {
      field: 'priceOriginalHT',
      headerName: "Prix d'origine HT unitaire",
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'priceOriginalTTC',
      headerName: "Prix d'origine TTC unitaire",
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'priceDiscountHT',
      headerName: 'Remise HT unitaire',
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'priceDiscountTTC',
      headerName: 'Remise TTC unitaire',
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'priceHT',
      headerName: 'Prix final HT unitaire',
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
    {
      field: 'priceTTC',
      headerName: 'Prix final TTC unitaire',
      minWidth: 110,
      valueGetter: (value) => (isNumber(value) ? formatNumberToEurCurrency(value) : '-'),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        sx={styleDataGridDefault}
        loading={loading}
        ignoreDiacritics
        columns={columns}
        rows={products}
        disableColumnMenu
        rowHeight={30}
        disableRowSelectionOnClick
        hideFooter
        autosizeOnMount
      />
    </div>
  );
};

export default ProductList;
