import { Article } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';

function SalePromoList() {
  const [loadingContent, setLoadingContent] = React.useState(true);
  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

  const columns = [
    {
      name: 'code',
      label: 'Code',
      options: { filter: true, sort: true },
    },
    {
      name: 'label',
      label: 'Nom promo',
      options: { filter: true, sort: true },
    },
    {
      name: 'startAt',
      label: 'Date début',
      options: { filter: true, sort: true },
    },
    {
      name: 'endAt',
      label: 'Date fin',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const [formSearch, setFormSearch] = React.useState({
    code: {
      name: 'code',
      label: 'Code',
      textHelper: 'Rechercher par code promo.',
      type: 'text',
      defaultValue: '',
    },
    label: {
      name: 'label',
      label: 'Nom promo',
      textHelper: 'Rechercher par nom promo.',
      type: 'text',
      defaultValue: '',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    dispatch(TableReload('api_sale_promo_list'));
  };
  const getAction = (row) => {
    return (
      <Tooltip title={'Visualiser'} placement="left">
        <Link to={getRoutePathname('sale_promo', { id: row.id })}>
          <IconButton>
            <Article style={{ color: '#17a2b8' }} />
          </IconButton>
        </Link>
      </Tooltip>
    );
  };
  React.useEffect(() => {
    handlerFormSearch.start();

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent loading={loadingContent} breadcrumbs={{ title: 'Liste des promos', context: 'Vente' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'code'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('code', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <TextFieldComponent
                  id={'label'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('label', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_sale_promo_list'}
        title={'Promotions'}
        columns={columns}
        search={false}
        actionFirst={
          hasRight(authenticationReducer, 'ROLE_SALE_PROMO') || hasRight(authenticationReducer, 'ROLE_SALE_PROMO_GET')
            ? {
                label: 'Ajouter un code promo',
                link: getRoutePathname('sale_promo_add'),
              }
            : false
        }
        promiseServerData={(resolve, options) => {
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            code: formSearch.code.value ? formSearch.code.value : '',
            label: formSearch.label.value ? formSearch.label.value : '',
          };

          ApiHandler.get(
            {
              route: 'api_sale_promo_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;

              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].startAt = moment(data[index].startAt).format('lll');
                data[index].endAt = moment(data[index].endAt).format('lll');
                data[index].code = data[index].code ? data[index].code : '-';
                data[index].label = data[index].label ? data[index].label : '-';
              }
              resolve(data, response.data.count);
            },
          );
        }}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default SalePromoList;
