import { Article, Edit } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { hasRight } from '../../../Common/UserCommon';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import OrderPurveyorListComponent from '../../../Component/OrderPurveyorListComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { isNumber } from '../../../Util/NumberTool';

function BuyOrderPurveyorList() {
  const [optionsPurveyor, setOptionsPurveyor] = useState([]);
  const [optionsDeposit, setOptionsDeposit] = useState([]);
  const [optionsOrderPurveyorState, setOptionsOrderPurveyorState] = useState([]);

  // formSearch
  const [formSearch, setFormSearch] = useState({
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseurs.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].purveyor
          ? cacheTableServer['api_buy_order_purveyor_list'].purveyor
          : '',
    },
    deposit: {
      name: 'deposit',
      label: 'Dépot',
      textHelper: 'Rechercher par dépots.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].deposit
          ? cacheTableServer['api_buy_order_purveyor_list'].deposit
          : '',
    },
    amountTotal: {
      name: 'amountTotal',
      label: 'Montant HT',
      textHelper: 'Rechercher par montant HT.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].channel
          ? cacheTableServer['api_buy_order_purveyor_list'].channel
          : '',
    },
    stateText: {
      name: 'stateText',
      label: 'État',
      textHelper: 'Rechercher par états.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].stateText
          ? cacheTableServer['api_buy_order_purveyor_list'].stateText
          : '',
    },
    deliveryAt: {
      name: 'deliveryAt',
      label: 'Date de livraison',
      textHelper: 'Rechercher par date de livraison.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].deliveryAt
          ? cacheTableServer['api_buy_order_purveyor_list'].deliveryAt
          : '',
      options: { validation: ['date'] },
    },
    fromStartAt: {
      name: 'fromStartAt',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].fromStartAt
          ? cacheTableServer['api_buy_order_purveyor_list'].fromStartAt
          : '',
      options: { validation: ['date'] },
    },
    fromEndAt: {
      name: 'fromEndAt',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      defaultValue:
        cacheTableServer['api_buy_order_purveyor_list'] && cacheTableServer['api_buy_order_purveyor_list'].fromEndAt
          ? cacheTableServer['api_buy_order_purveyor_list'].fromEndAt
          : '',
      options: { validation: ['date'] },
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_buy_order_purveyor_list'] = 0;
    cacheTableServer['api_buy_order_purveyor_list'].page = 0;
    cacheTableServer['api_buy_order_purveyor_list'][index] = value;
    dispatch(TableReload('api_buy_order_purveyor_list'));
  };

  useEffect(() => {
    handlerFormSearch.start();

    //get options BuyOrderPurveyorList
    ApiHandler.get({ route: 'api_select_purveyor' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsPurveyor(data);
    });
    ApiHandler.get({ route: 'api_select_deposit' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsDeposit(data);
    });
    ApiHandler.get({ route: 'api_select_order_purveyor_state' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsOrderPurveyorState(data);
    });
  }, []);

  const datas = handlerFormSearch.getData();

  const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

  const getAction = (row) => {
    return (
      <>
        {hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR') ||
        hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_EDIT') ? (
          <Tooltip title={'Modifier'} placement="left">
            <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
              <IconButton>
                <Edit style={{ color: '#17a2b8' }} />
              </IconButton>
            </Link>
          </Tooltip>
        ) : hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_GET') ? (
          <Tooltip title={'Visualiser'} placement="left">
            <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
              <IconButton>
                <Article style={{ color: '#28a745' }} />
              </IconButton>
            </Link>
          </Tooltip>
        ) : (
          <IconButton disabled={true}>
            <Edit style={{ color: '#17a2b8' }} />
          </IconButton>
        )}
      </>
    );
  };

  const promiseServerData = (resolve, options) => {
    const filters = {
      limit: options.rowsPerPage,
      offset: options.page * options.rowsPerPage,
      sortName: options.sortOrder.name,
      sortDirection: options.sortOrder.direction,
      purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
      deposit: datas.deposit === 0 || !datas.deposit ? '' : datas.deposit,
      stateText: datas.stateText === 0 || !datas.stateText ? '' : datas.stateText,
      deliveryAt: formSearch.deliveryAt.value ? formSearch.deliveryAt.value : '',
      fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
      fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
    };

    ApiHandler.get(
      {
        route: 'api_buy_order_purveyor_list',
        data: filters,
      },
      (response) => {
        const data = response.data.data;

        for (const index in data) {
          data[index].action = getAction(data[index]);
          data[index].createdAt = moment(data[index].createdAt).format('L');
          data[index].deliveryAt = (
            <span
              style={
                !data[index].deliveryAt || (moment(data[index].deliveryAt).isBefore(moment()) && data[index].state <= 2)
                  ? { color: '#982525' }
                  : {}
              }
            >
              {moment(data[index].deliveryAt).format('L')}
            </span>
          );
          data[index].amountTotal = isNumber(data[index].amountTotal) ? (
            <span style={{ display: 'flex', justifyContent: 'end' }}>
              {parseFloat(data[index].amountTotal).toLocaleString('fr-FR', { minimumFractionDigits: 2 })}
            </span>
          ) : (
            '-'
          );
        }
        resolve(data, response.data.count);
      },
    );
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des commandes fournisseur', context: 'Achat' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'purveyor'}
                  options={optionsPurveyor}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('purveyor', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'deposit'}
                  options={optionsDeposit}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('deposit', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <SelectComponent
                  id={'stateText'}
                  options={optionsOrderPurveyorState}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('stateText', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'deliveryAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('deliveryAt', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'fromStartAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromStartAt', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                <DatePickerComponent
                  id={'fromEndAt'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('fromEndAt', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <OrderPurveyorListComponent
        promiseServerData={promiseServerData}
        displayActions={true}
        displayDeposit={true}
        displayPurveyor={true}
        tableId="api_buy_order_purveyor_list"
      />

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BuyOrderPurveyorList;
