export const styleDataGridDefault = {
  '& .MuiSvgIcon-root': {
    fontSize: 'large',
  },
  '& .MuiDataGrid-cell': {
    fontSize: 11,
  },
  '& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-columnHeaderTitleContainer': {
    fontSize: 11,
    textTransform: 'uppercase',
    whiteSpace: 'normal',
  },
};
